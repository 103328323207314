import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { projectAuxiliary, getProjectDetail } from "../../../utils/public"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_23.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '项目中标立项',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '立项类别',
                        filedName: 'operation',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: []
                    },
                    {
                        filedLabel: '项目编号',
                        filedName: 'pro_number',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '立项时间',
                        filedName: 'pro_app_time',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '省份',
                        filedName: 'province',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: []
                    },
                    {
                        filedLabel: '城市',
                        filedName: 'city',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: []
                    },
                    {
                        filedLabel: '中标单位',
                        filedName: 'award_unit',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: []
                    },
                    {
                        filedLabel: '中标金额(元)',
                        filedName: 'award_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入合同甲方',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '计划开工时间',
                        filedName: 'plan_time',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '要求竣工时间',
                        filedName: 'complete_time',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '工期(天)',
                        filedName: 'con_period',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '所属行业',
                        filedName: 'industry',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '经营方式',
                        filedName: 'operation_mode',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: []
                    }, {
                        filedLabel: '业主单位',
                        filedName: 'owner_unit',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '业主地址',
                        filedName: 'owner_address',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '业主代表',
                        filedName: 'owner_deputy',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '业主联系方式',
                        filedName: 'owner_contact',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目资金来源',
                        filedName: 'money_source',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: []
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                ]
            },
            provinces: []
        }
    }

    componentDidMount() {
        this.get_projectAuxiliary()
    }

    get_projectAuxiliary = () => {
        let { Info } = this.state
        projectAuxiliary().then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '立项类别') {
                            for (let key in res.data.operationMode) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.operationMode[key]
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        } else if (item.filedLabel === '省份') {
                            res.data.provinces.forEach((tem, dex) => {
                                let value = {
                                    value: tem.value,
                                    label: tem.label
                                }
                                item.radioList = [...item.radioList, value]
                            })
                        } else if (item.filedLabel === '中标单位') {
                            for (let key in res.data.projectAwardUnit) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.projectAwardUnit[key]
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        } else if (item.filedLabel === '经营方式') {
                            for (let key in res.data.operation) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.operation[key]
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        } else if (item.filedLabel === '项目资金来源') {
                            for (let key in res.data.moneySource) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.moneySource[key]
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        provinces: res.data.provinces
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_ProjectDetail(tableId)
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_ProjectDetail = (tableId) => {
        const { Info, Form, provinces } = this.state;
        let data = {
            table_id: tableId
        }
        getProjectDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.status === 2 || res.data.status === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '附件') {
                                if (!res.data.enclosure || !res.data.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.status
                    Info.apply.userName = res.data.applyUserName
                    Info.apply.deName = res.data.applyUserDeName
                    Info.apply.apply_date = res.data.created_at
                    Info.apply.process = res.data.reviewAudit
                    Info.apply.process.editPermission = res.data.editPermission

                    provinces.forEach((item, index) => {
                        if (item.value === res.data.province) {
                            Info.TableInfo.forEach((tem, dex) => {
                                if (tem.filedLabel === '城市') {
                                    tem.radioList = []
                                    item.children.forEach((em, ex) => {
                                        let value = {
                                            value: em.value,
                                            label: em.label
                                        }
                                        tem.radioList = [...tem.radioList, value]
                                    })
                                }
                            })
                        }
                    })

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '立项类别') {
                            item.value = []
                            item.value = [...item.value, res.data.operation]
                        } else if (item.filedLabel === '项目编号') {
                            item.value = res.data.pro_number
                        } else if (item.filedLabel === '立项时间') {
                            item.value = res.data.pro_app_time ? new Date(res.data.pro_app_time) : ''
                        } else if (item.filedLabel === '项目名称') {
                            item.value = res.data.pro_name
                        } else if (item.filedLabel === '省份') {
                            item.value = []
                            item.value = [...item.value, res.data.province]
                        } else if (item.filedLabel === '城市') {
                            item.value = []
                            item.value = [...item.value, res.data.city]
                        } else if (item.filedLabel === '中标单位') {
                            item.value = []
                            item.value = [...item.value, res.data.award_unit]
                        } else if (item.filedLabel === '中标金额(元)') {
                            item.value = res.data.award_money
                        } else if (item.filedLabel === '计划开工时间') {
                            item.value = res.data.plan_time ? new Date(res.data.plan_time) : ''
                        } else if (item.filedLabel === '要求竣工时间') {
                            item.value = res.data.complete_time ? new Date(res.data.complete_time) : ''
                        } else if (item.filedLabel === '工期(天)') {
                            item.value = res.data.con_period
                        } else if (item.filedLabel === '所属行业') {
                            item.value = res.data.industry
                        } else if (item.filedLabel === '经营方式') {
                            item.value = []
                            item.value = [...item.value, res.data.operation_mode]
                        } else if (item.filedLabel === '业主单位') {
                            item.value = res.data.owner_unit

                        } else if (item.filedLabel === '业主地址') {
                            item.value = res.data.owner_address
                        } else if (item.filedLabel === '业主代表') {
                            item.value = res.data.owner_deputy
                        } else if (item.filedLabel === '业主联系方式') {
                            item.value = res.data.owner_contact
                        } else if (item.filedLabel === '项目资金来源') {
                            item.value = []
                            item.value = [...item.value, res.data.money_source]
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.enclosure || []
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        Form,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='项目中标立项' />

                <AddConfig key={FormKey} Info={Info} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
