import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import NavBar from "../../components/NavRar"
import Content from "../../components/Content"
import { awayApplyList } from "../../../utils/oa"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            status: 2,
            pageNum: 1,
            dataSource: [],
            Loading: false
        }
    }
    componentDidMount() {
        this.get_awayApplyList()
    }

    handleTab = (status) => {
        this.setState(preState => ({
            status,
            pageNum: 1,
            dataSource: [],
            Loading: false
        }), () => {
            this.get_awayApplyList()
        })
    }

    handleLimit = () => {
        this.get_awayApplyList()
    }

    get_awayApplyList = () => {
        let { dataSource, pageNum, status, Loading } = this.state
        let data = {
            queryCriteria: 'personal',
            status: status,
            page: pageNum,
            limit: 15
        }
        awayApplyList(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.aways.length) {
                        res.data.aways.forEach((item, index) => {
                            let value = {
                                key: index + 1,
                                table_id: item.table_id,
                                username: item.applyUserName,
                                deName: item.deName,
                                content: [
                                    {
                                        label: '预计出发时间',
                                        value: item.start_date,
                                    },
                                    {
                                        label: '预计返回时间',
                                        value: item.end_date,
                                    },
                                    {
                                        label: '出差天数',
                                        value: item.date_num,
                                    },
                                    {
                                        label: '交通工具',
                                        value: item.tool === 1 ? '火车' : item.tool === 2 ? '飞机' : item.tool === 3 ? '飞机+火车' : item.tool === 4 ? '高铁(动车)' : item.tool === 5 ? '飞机+高铁(动车)' : '其他',
                                    },
                                    {
                                        label: '同行人员',
                                        value: item.matter,
                                    },
                                    {
                                        label: '出差地点',
                                        value: item.address,
                                    },
                                    {
                                        label: '出差事由',
                                        value: item.matter,
                                    }
                                ],
                                apply_date: item.apply_date,
                            }

                            dataSource = [...dataSource, value]
                        })

                        if (pageNum < Math.ceil(res.data.pages.total / 15)) {
                            Loading = true
                        }

                        pageNum = pageNum + 1

                        this.setState(preState => ({
                            dataSource,
                            pageNum,
                            Loading
                        })
                        )
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { status, dataSource, Loading } = this.state
        return (
            <div>
                <NavBar menuName='出差申请' />

                <Content menuName='新增出差申请' pathname='/public/human/evection/NewItem' status={status} dataSource={dataSource} Loading={Loading} TabEvent={this.handleTab} LimitEvent={this.handleLimit} />
            </div>
        )
    }
}

export default withRouter(List)
