import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { selectProjectName } from "../../../utils/api"
import { createWorkEat, workEatDetail } from "../../../utils/oa"
import { reviewApplyResult } from "../../../utils/api"
import UserPopup from "../../../components/UserPopup"
import Icon from "../../../accets/img/icon_09.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '业务招待申请',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '费用预估(元)',
                        filedName: 'meal_cost',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入费用预估',
                        disabled: false,
                        required: true,
                        message: '请输入费用预估',
                        value: '',
                    },
                    {
                        filedLabel: '费用是否超限',
                        filedName: 'is_tr',
                        filedType: '单选',
                        placeholder: '请选择',
                        disabled: false,
                        required: true,
                        message: '请选择费用是否超限',
                        value: [],
                        radioList: [
                            {
                                value: 1,
                                label: '是'
                            },
                            {
                                value: 2,
                                label: '否'
                            }
                        ]
                    },
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '备注',
                        filedName: 'info',
                        filedType: '文本域',
                        placeholder: '请输入备注',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '费用超限标准',
                        filedName: 'standard',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '副总经理（含总监）级：单次消费合计1500元以内且人均不超过300元；\n部门经理级：单次消费合计600元以内且人均不超过200元；\n普通员工级：单次消费合计300元以内且人均不超过100元；',
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: [],
                    },
                ],
            },
            Form: {},
            pro_name: '',
            popup: {
                isShow: false,
                title: '选择项目',
                placeholder: '输入项目名称查询',
                multiple: false,
                disabled: false,
                dataSource: []
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_workEatDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let { Info } = this.state;
        let userInfo = storage.getItem('userInfo');

        Info.apply.userName = userInfo.name
        Info.apply.deName = userInfo.deName
        Info.apply.apply_date = ''

        this.setState(preState => ({
            Info
        })
        )
    }

    get_workEatDetail = (tableId) => {
        const { Info, Form } = this.state;
        let data = {
            table_id: tableId
        }
        workEatDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        Form.table_id = tableId
                        Form.project_id = res.data.Detail.project_id
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (!res.data.Detail.enclosure.length) {
                                if (item.filedLabel === '附件') {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.apply_userName
                    Info.apply.deName = res.data.Detail.deName
                    Info.apply.apply_date = res.data.Detail.created_at
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.projectName
                        } else if (item.filedLabel === '费用预估(元)') {
                            item.value = res.data.Detail.meal_cost
                        } else if (item.filedLabel === '费用是否超限') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.is_tr]
                        } else if (item.filedLabel === '备注') {
                            item.value = res.data.Detail.info
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.Detail.enclosure
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        Form,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_selectProjectName = () => {
        let { popup, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: 1,
            limit: 500
        }
        selectProjectName(data).then(
            (res) => {
                if (!res.code) {
                    popup.dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let pro = {
                                value: item.table_id,
                                label: item.pro_name,
                            }
                            popup.dataSource = [...popup.dataSource, pro]
                        })

                        popup.isShow = true
                    }
                    this.setState(preState => ({
                        popup,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = () => {
        this.get_selectProjectName()
    }

    handleVisible = (title, name) => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.filedLabel === title) {
                item.visible = true
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleQuery = (value) => {
        this.setState(preState => ({
            pro_name: value,
        }), () => {
            this.get_selectProjectName()
        })
    }

    handleClose = () => {
        this.setState(preState => ({
            userName: '',
            pro_name: '',
            popup: {
                isShow: false,
                title: '选择项目',
                placeholder: '输入项目名称查询',
                multiple: false,
                disabled: false,
                dataSource: []
            }
        }))
    }

    handleSelect = (value) => {
        let { popup, Info, Form } = this.state
        if (value.length) {
            value.forEach((item, index) => {
                popup.dataSource.forEach((em, ex) => {
                    if (em.value === item) {
                        Info.TableInfo.forEach((m, x) => {
                            if (m.filedLabel === '项目名称') {
                                m.value = em.label
                                Form.project_id = em.value
                            }
                        })
                    }
                })
            })
        }
        this.setState(preState => ({
            Info,
            Form,
            pro_name: '',
            popup: {
                isShow: false,
                title: '选择项目',
                placeholder: '输入项目名称查询',
                multiple: false,
                disabled: false,
                dataSource: []
            }
        }))
    }

    handleDate = (title) => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.filedLabel === title) {
                item.visible = false
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleFile = (fileList, fileName) => {
        let { Form, Info } = this.state
        let storeList = []
        if (fileList.length) {
            fileList.forEach((item, index) => {
                storeList.push(item.table_id)
            })
        }
        Form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Form,
            Info
        })
        )
    }

    handlePresent = (data) => {
        const { Info, Form } = this.state
        Form.status = 2
        Form.meal_cost = data.meal_cost
        Form.is_tr = data.is_tr[0]
        Form.info = data.info

        createWorkEat(Form).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '提交成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '提交失败,请检查表单!',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info, popup } = this.state
        return (
            <div>
                <NavBar menuName='新增业务招待申请' />

                <AddConfig key={FormKey} Info={Info} buttonEvent={this.handleButton} visibleEvent={this.handleVisible} fileEvent={this.handleFile} dateEvent={this.handleDate} presentEvent={this.handlePresent} auditEvent={this.handleAudit}/>

                <UserPopup popup={popup} closeEvent={this.handleClose} queryEvent={this.handleQuery} selectEvent={this.handleSelect} />
            </div>
        )
    }
}

export default withRouter(NewItem)
