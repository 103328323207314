import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { userInformation } from "../../../utils/oa"
import { reviewApplyResult, getDepartmentJob } from "../../../utils/api"
import Icon from "../../../accets/img/icon_36.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '入职办理',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '姓名',
                        filedName: 'name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '企业微信账号',
                        filedName: 'com_user_id',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '入职日期',
                        filedName: 'job_day',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '职员状态',
                        filedName: 'member_status',
                        filedType: '单选',
                        placeholder: '请选择',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            { value: '1', label: "实习" },
                            { value: '2', label: "试用" },
                            { value: '3', label: "在职" },
                        ]
                    },
                    {
                        filedLabel: '性别',
                        filedName: 'sex',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            { value: '1', label: "男" },
                            { value: '2', label: "女" },
                        ]
                    },
                    {
                        filedLabel: '民族',
                        filedName: 'nation',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '出生日期',
                        filedName: 'date_birth',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '婚姻状态',
                        filedName: 'marital_status',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            { value: '1', label: "已婚" },
                            { value: '2', label: "未婚" },
                        ]
                    }, {
                        filedLabel: '政治面貌',
                        filedName: 'outlook',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            { value: '1', label: "中共党员" },
                            { value: '2', label: "团员" },
                            { value: '3', label: "群众" },
                            { value: '4', label: "民族党派" },
                        ]
                    }, {
                        filedLabel: '学历',
                        filedName: 'education',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            { value: '1', label: "本科" },
                            { value: '2', label: "专科" },
                            { value: '3', label: "高中" },
                            { value: '4', label: "初中" },
                            { value: '5', label: "硕士" },
                            { value: '6', label: "博士" },
                        ]
                    },
                    {
                        filedLabel: '毕业院校',
                        filedName: 'school',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '毕业年份',
                        filedName: 'graduation_year',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '专业',
                        filedName: 'major',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '籍贯',
                        filedName: 'native_place',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '身份证号',
                        filedName: 'id_cart',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '身份证地址',
                        filedName: 'cart_address',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '现住址',
                        filedName: 'place_residence',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '紧急联系人',
                        filedName: 'contacts',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '紧急联系电话',
                        filedName: 'contact_phone',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '手机号',
                        filedName: 'phone',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '工作电话',
                        filedName: 'work_phone',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '邮箱',
                        filedName: 'email',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '部门',
                        filedName: 'department_id',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: []
                    }, {
                        filedLabel: '职位',
                        filedName: 'position_id',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: []
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                ],
                ListInfo: [
                    {
                        plateName: '工作经历',
                        unWind: false,
                        dataSource: []
                    },
                    {
                        plateName: '教育经历',
                        unWind: false,
                        dataSource: []
                    },
                    {
                        plateName: '',
                        list: [
                            {
                                filedLabel: '工资开户行',
                                filedName: 'bank_deposit',
                                filedType: '文本',
                                nodeType: 'text',
                                placeholder: '',
                                disabled: true,
                                required: false,
                                message: '',
                                value: '',
                            }, {
                                filedLabel: '工资卡号',
                                filedName: 'salary_card',
                                filedType: '文本',
                                nodeType: 'text',
                                placeholder: '',
                                disabled: true,
                                required: false,
                                message: '',
                                value: '',
                            },
                        ]
                    },
                 ]
            },
            departmentList: []
        }
    }

    componentDidMount() {
        this.get_DepartmentJob()
    }

    get_DepartmentJob() {
        let { Info } = this.state
        getDepartmentJob().then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '部门') {
                            res.data.des.forEach((em, ex) => {
                                let select = {
                                    value: Number(em.id),
                                    label: em.name
                                }
                                item.radioList = [...item.radioList, select]
                            })
                        }
                    })
                    this.setState(preState => ({
                        Info,
                        departmentList: res.data.des
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_userInformation(tableId)
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_userInformation = (tableId) => {
        const { Info, departmentList } = this.state;
        let data = {
            user_id: tableId
        }
        userInformation(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.userInformation.status === 2 || res.data.userInformation.status === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '附件') {
                                if (!res.data.userInformation.enclosure || !res.data.userInformation.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.userInformation.status
                    Info.apply.userName = res.data.userInformation.applyUserName
                    Info.apply.deName = res.data.userInformation.applyUserDeName
                    Info.apply.apply_date = res.data.userInformation.created_at
                    Info.apply.process = res.data.userInformation.reviewAudit
                    Info.apply.process.editPermission = res.data.userInformation.editPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '职位') {
                            item.radioList = []
                            departmentList.forEach((em, ex) => {
                                if (em.id === res.data.userInformation.department_id) {
                                    em.subset.forEach((m, x) => {
                                        let select = {
                                            value: Number(m.id),
                                            label: m.company_job
                                        }

                                        item.radioList = [...item.radioList, select]
                                    })
                                }
                            })
                        }
                    })


                    Info.ListInfo[0].dataSource = []
                    if (res.data.userInformation.workExperience.length) {
                        res.data.userInformation.workExperience.forEach((item, index) => {
                            let key = index + 1
                            let dataSource = {
                                key: key,
                                list: [
                                    {
                                        filedLabel: '开始时间',
                                        filedName: 'start_01_' + key,
                                        filedType: '日期',
                                        visible: false,
                                        nodeType: 'day',
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.start ? new Date(item.start) : '',
                                    },
                                    {
                                        filedLabel: '结束时间',
                                        filedName: 'end_01_' + key,
                                        filedType: '日期',
                                        visible: false,
                                        nodeType: 'day',
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.end ? new Date(item.end) : '',
                                    },
                                    {
                                        filedLabel: '任职单位及部门',
                                        filedName: 'unit_de_01_' + key,
                                        filedType: '文本',
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.unit,
                                    },
                                    {
                                        filedLabel: '担任职务',
                                        filedName: 'job_01_' + key,
                                        filedType: '文本',
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.job,
                                    },
                                ]
                            }
                            Info.ListInfo[0].dataSource = [...Info.ListInfo[0].dataSource, dataSource]
                        })
                    }

                    Info.ListInfo[1].dataSource = []
                    if (res.data.userInformation.schools.length) {
                        res.data.userInformation.schools.forEach((item, index) => {
                            let key = index + 1
                            let dataSource = {
                                key: key,
                                list: [
                                    {
                                        filedLabel: '开始时间',
                                        filedName: 'start_02_' + key,
                                        filedType: '日期',
                                        visible: false,
                                        nodeType: 'day',
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.start ? new Date(item.start) : '',
                                    },
                                    {
                                        filedLabel: '结束时间',
                                        filedName: 'end_02_' + key,
                                        filedType: '日期',
                                        visible: false,
                                        nodeType: 'day',
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.end ? new Date(item.end) : '',
                                    },
                                    {
                                        filedLabel: '毕业院校',
                                        filedName: 'school_02_' + key,
                                        filedType: '文本',
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.school,
                                    },
                                    {
                                        filedLabel: '所读专业',
                                        filedName: 'major_02_' + key,
                                        filedType: '文本',
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.major,
                                    },
                                ]
                            }
                            Info.ListInfo[1].dataSource = [...Info.ListInfo[1].dataSource, dataSource]
                        })

                    }


                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '姓名') {
                            item.value = res.data.userInformation.name
                        } else if (item.filedLabel === '企业微信账号') {
                            item.value = res.data.userInformation.com_userid
                        } else if (item.filedLabel === '入职日期') {
                            item.value = res.data.userInformation.job_day ? new Date(res.data.userInformation.job_day) : ''
                        } else if (item.filedLabel === '职员状态') {
                            item.value = []
                            item.value = [...item.value, res.data.userInformation.member_status]
                        } else if (item.filedLabel === '性别') {
                            item.value = []
                            item.value = [...item.value, res.data.userInformation.sex]
                        } else if (item.filedLabel === '民族') {
                            item.value = res.data.userInformation.nation
                        } else if (item.filedLabel === '出生日期') {
                            item.value = res.data.userInformation.date_birth ? new Date(res.data.userInformation.date_birth) : ''
                        } else if (item.filedLabel === '婚姻状态') {
                            item.value = []
                            item.value = [...item.value, res.data.userInformation.marital_status]
                        } else if (item.filedLabel === '政治面貌') {
                            item.value = []
                            item.value = [...item.value, res.data.userInformation.outlook]
                        } else if (item.filedLabel === '学历') {
                            item.value = []
                            item.value = [...item.value, res.data.userInformation.education]
                        } else if (item.filedLabel === '毕业院校') {
                            item.value = res.data.userInformation.school
                        } else if (item.filedLabel === '毕业年份') {
                            item.value = res.data.userInformation.graduation_year
                        } else if (item.filedLabel === '专业') {
                            item.value = res.data.userInformation.major
                        } else if (item.filedLabel === '籍贯') {
                            item.value = res.data.userInformation.native_place
                        } else if (item.filedLabel === '身份证号') {
                            item.value = res.data.userInformation.id_cart
                        } else if (item.filedLabel === '身份证地址') {
                            item.value = res.data.userInformation.cart_address
                        } else if (item.filedLabel === '现住址') {
                            item.value = res.data.userInformation.place_residence
                        } else if (item.filedLabel === '紧急联系人') {
                            item.value = res.data.userInformation.contacts
                        } else if (item.filedLabel === '紧急联系电话') {
                            item.value = res.data.userInformation.contact_phone
                        } else if (item.filedLabel === '手机号') {
                            item.value = res.data.userInformation.phone
                        } else if (item.filedLabel === '工作电话') {
                            item.value = res.data.userInformation.work_phone
                        } else if (item.filedLabel === '邮箱') {
                            item.value = res.data.userInformation.email
                        } else if (item.filedLabel === '部门') {
                            item.value = []
                            item.value = [...item.value, res.data.userInformation.department_id]
                        }else if (item.filedLabel === '职位') {
                            item.value = []
                            item.value = [...item.value, res.data.userInformation.position_id]
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.userInformation.enclosure
                        }
                    })
                    Info.ListInfo[2].list.forEach((item, index) => {
                        if (item.filedLabel === '工资开户行') {
                            item.value = res.data.userInformation.bank_deposit
                        } else if (item.filedLabel === '工资卡号') {
                            item.value = res.data.userInformation.salary_card
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleWind = (name) => {
        let { Info } = this.state
        Info.ListInfo.forEach((item, index) => {
            if (item.plateName === name) {
                item.unWind = !item.unWind
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='入职办理' />

                <AddConfig key={FormKey} Info={Info} windEvent={this.handleWind} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
