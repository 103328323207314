import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { SupConfirmPriceDetail } from "../../../utils/public"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_02.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '供应商认价审批',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '认价编号',
                        filedName: 'code',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '认价日期',
                        filedName: 'apply_date',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '认价类别',
                        filedName: 'class',
                        filedType: '单选',
                        placeholder: '请选择',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            { value: 1, label: "材料" },
                            { value: 2, label: "劳务" },
                            { value: 3, label: "机械" },
                            { value: 7, label: "零星材料" },
                            { value: 8, label: "双包" },
                        ]
                    },
                    {
                        filedLabel: '是否盖章',
                        filedName: 'is_seal',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                label: '是',
                                value: 1,
                            }, {
                                label: '否',
                                value: 2,
                            }
                        ]
                    },
                    {
                        filedLabel: '印章名称',
                        filedName: 'seal_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目编号',
                        filedName: 'pro_number',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目经理',
                        filedName: 'pro_manager',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '成本专员',
                        filedName: 'cost_user',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '中标供应商名称',
                        filedName: 'sup_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },

                    {
                        filedLabel: '备注',
                        filedName: 'info',
                        filedType: '文本域',
                        placeholder: '请输入备注',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                    {
                        filedLabel: '总合同价',
                        filedName: 'all_conMoney',
                        filedType: '默认',
                        value: '',
                    },
                    {
                        filedLabel: '总采购价',
                        filedName: 'all_total_price',
                        filedType: '默认',
                        value: '',
                    },
                    {
                        filedLabel: '总利润率(%)',
                        filedName: 'total_estimated',
                        filedType: '默认',
                        value: '',
                        status: ''
                    },
                ],
                ListInfo: [
                    {
                        plateName: '材料认价详情',
                        unWind: false,
                        dataSource: []
                    },
                ]
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_SupConfirmPriceDetail(tableId)
        }
    }

    get_SupConfirmPriceDetail = (tableId) => {
        const { Info } = this.state;
        let data = {
            table_id: tableId
        }
        SupConfirmPriceDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 2 || res.data.Detail.status === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '附件') {
                                if (!res.data.Detail.enclosure || !res.data.Detail.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUserName
                    Info.apply.deName = res.data.Detail.applyUserDeName
                    Info.apply.apply_date = res.data.Detail.created_at
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.ListInfo[0].dataSource = []
                    res.data.Detail.content.forEach((item, index) => {
                        let key = index + 1
                        let dataSource = {
                            key: key,
                            list: [
                                {
                                    filedLabel: '名称/工种',
                                    filedName: 'name_' + key,
                                    filedType: '文本',
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.name,
                                },
                                {
                                    filedLabel: '利润率(%)',
                                    filedName: 'profit_' + key,
                                    filedType: '文本',
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.profit,
                                },
                                {
                                    filedLabel: '采购单价（不含税）',
                                    filedName: 'ver_unit_price_' + key,
                                    filedType: '文本',
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.ver_unit_price,
                                },
                                {
                                    filedLabel: '税率(%)',
                                    filedName: 'tax_' + key,
                                    filedType: '文本',
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.tax,
                                },
                            ]
                        }
                        Info.ListInfo[0].dataSource = [...Info.ListInfo[0].dataSource, dataSource]
                    })

                    if (res.data.Detail.is_seal === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '印章名称') {
                                item.unShow = false
                                item.value = res.data.revenue.sealNames
                            }
                        })
                    }


                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '认价编号') {
                            item.value = res.data.Detail.code
                        } else if (item.filedLabel === '认价日期') {
                            item.value = res.data.Detail.apply_date ? new Date(res.data.Detail.apply_date) : ''
                        } else if (item.filedLabel === '认价类别') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.class]
                        } else if (item.filedLabel === '是否盖章') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.is_seal]
                        } else if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.proName
                        } else if (item.filedLabel === '项目编号') {
                            item.value = res.data.Detail.proNumber
                        } else if (item.filedLabel === '项目经理') {
                            item.value = res.data.Detail.proManager
                        } else if (item.filedLabel === '成本专员') {
                            item.value = res.data.Detail.costUser
                        } else if (item.filedLabel === '中标供应商名称') {
                            item.value = res.data.Detail.supplierName
                        } else if (item.filedLabel === '备注') {
                            item.value = res.data.Detail.info
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.Detail.enclosure
                        } else if (item.filedLabel === '总利润率(%)') {
                            item.value = res.data.Detail.total_estimated
                            item.status = res.data.Detail.estimated_profit
                        } else if (item.filedLabel === '总采购价') {
                            item.value = res.data.Detail.all_total_price || '/'
                        } else if (item.filedLabel === '总合同价') {
                            item.value = res.data.Detail.all_conMoney || '/'
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleWind = (name) => {
        let { Info } = this.state
        Info.ListInfo.forEach((item, index) => {
            if (item.plateName === name) {
                item.unWind = !item.unWind
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='供应商认价审批' />

                <AddConfig key={FormKey} Info={Info} windEvent={this.handleWind} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
