import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import NavBar from "../../components/NavRar"
import Content from "../../components/Content"
import { ConstructionLogList } from "../../../utils/project"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            status: 2,
            pageNum: 1,
            dataSource: [],
            Loading: false
        }
    }
    componentDidMount() {
        this.get_ConstructionLogList()
    }

    handleTab = (status) => {
        this.setState(preState => ({
            status,
            pageNum: 1,
            dataSource: [],
            Loading: false
        }), () => {
            this.get_ConstructionLogList()
        })
    }

    handleLimit = () => {
        this.get_ConstructionLogList()
    }

    get_ConstructionLogList = () => {
        let { dataSource, pageNum, status, Loading } = this.state
        let data = {
            queryCriteria: 'personal',
            status: status,
            page: pageNum,
            limit: 15
        }
        ConstructionLogList(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.cons.length) {
                        res.data.cons.forEach((item, index) => {
                            let value = {
                                key: index + 1,
                                table_id: item.table_id,
                                username: item.apply_name,
                                deName: item.deName,
                                content: [
                                    {
                                        label: '项目名称',
                                        value: item.projectName,
                                    },
                                    {
                                        label: '当日施工进展',
                                        value: item.progress,
                                    },
                                    {
                                        label: '需要协调问题',
                                        value: item.problem,
                                    },
                                ],
                                apply_date: item.apply_date,
                            }

                            dataSource = [...dataSource, value]
                        })

                        if (pageNum < Math.ceil(res.data.pages.total / 15)) {
                            Loading = true
                        }

                        pageNum = pageNum + 1

                        this.setState(preState => ({
                            dataSource,
                            pageNum,
                            Loading
                        })
                        )
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { status, dataSource, Loading } = this.state
        return (
            <div>
                <NavBar menuName='施工日志' />

                <Content menuName='新增施工日志' pathname='/project/build/NewItem' status={status} dataSource={dataSource} Loading={Loading} TabEvent={this.handleTab} LimitEvent={this.handleLimit} />
            </div>
        )
    }
}

export default withRouter(List)
