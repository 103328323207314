import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { updateMacPriceDetail } from "../../../utils/public"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_55.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '材料调价审批',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目编号',
                        filedName: 'pro_number',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目经理',
                        filedName: 'pro_manager',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '认价编号',
                        filedName: 'cc_user',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '中标供应商名称',
                        filedName: 'sup_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '备注',
                        filedName: 'info',
                        filedType: '文本域',
                        placeholder: '请输入备注',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                ],
                ListInfo: [
                    {
                        plateName: '材料调价详情',
                        unWind: false,
                        dataColumn: []
                    },
                ]
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_updateMacPriceDetail(tableId)
        }
    }

    get_updateMacPriceDetail = (tableId) => {
        const { Info } = this.state;
        let data = {
            table_id: tableId
        }
        updateMacPriceDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 2 || res.data.Detail.status === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '附件') {
                                if (!res.data.Detail.enclosure || !res.data.Detail.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUserName
                    Info.apply.deName = res.data.Detail.applyrDeName
                    Info.apply.apply_date = res.data.Detail.apply_day
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.ListInfo[0].dataColumn = []
                    res.data.Detail.details.forEach((item, index) => {
                        let key = index + 1
                        let updatePrice = []

                        if (item.updatePrice.length > 1) {
                            let key = item.updatePrice.length - 1
                            let priceList = []

                            if (item.PassUpdatePrice && item.PassUpdatePrice.length) {
                                item.updatePrice.forEach((em, ex) => {
                                    if (ex !== key) {
                                        priceList = [...priceList, em]
                                    }
                                })

                                item.PassUpdatePrice.forEach((em, ex) => {
                                    priceList = [...priceList, em]
                                })

                                item.updatePrice.forEach((em, ex) => {
                                    if (ex === key) {
                                        priceList = [...priceList, em]
                                    }
                                })
                            } else {
                                priceList = [...priceList, ...item.updatePrice]
                            }

                            priceList.forEach((em, ex) => {
                                if (ex === 0) {
                                    let pur_unit_price = item.confrimPriceDetail.pur_unit_price
                                    if (Number(pur_unit_price) > Number(em.tax_price)) {
                                        em.is_excess = 2
                                    } else if (Number(pur_unit_price) < Number(em.tax_price)) {
                                        em.is_excess = 1
                                    } else {
                                        em.is_excess = 0
                                    }
                                } else {
                                    let pur_unit_price = priceList[ex - 1].tax_price
                                    if (Number(pur_unit_price) > Number(em.tax_price)) {
                                        em.is_excess = 2
                                    } else if (Number(pur_unit_price) < Number(em.tax_price)) {
                                        em.is_excess = 1
                                    } else {
                                        em.is_excess = 0
                                    }
                                }
                                updatePrice = [...updatePrice, em]
                            })
                        } else {
                            updatePrice = []

                            if (item.PassUpdatePrice && item.PassUpdatePrice.length) {
                                let key = item.PassUpdatePrice.length - 1
                                item.PassUpdatePrice.forEach((em, ex) => {
                                    if (ex === 0) {
                                        let pur_unit_price = item.confrimPriceDetail.pur_unit_price
                                        if (Number(pur_unit_price) > Number(em.tax_price)) {
                                            em.is_excess = 2
                                        } else if (Number(pur_unit_price) < Number(em.tax_price)) {
                                            em.is_excess = 1
                                        } else {
                                            em.is_excess = 0
                                        }
                                    } else {
                                        let pur_unit_price = item.updatePrice[ex - 1].tax_price
                                        if (Number(pur_unit_price) > Number(em.tax_price)) {
                                            em.is_excess = 2
                                        } else if (Number(pur_unit_price) < Number(em.tax_price)) {
                                            em.is_excess = 1
                                        } else {
                                            em.is_excess = 0
                                        }
                                    }
                                    updatePrice = [...updatePrice, em]
                                })

                                item.updatePrice.forEach((em, ex) => {
                                    if (Number(item.PassUpdatePrice[key].tax_price) > Number(em.tax_price)) {
                                        em.is_excess = 2
                                    } else if (Number(item.PassUpdatePrice[key].tax_price) < Number(em.tax_price)) {
                                        em.is_excess = 1
                                    } else {
                                        em.is_excess = 0
                                    }
                                    updatePrice = [...updatePrice, em]
                                })
                            } else {
                                item.updatePrice.forEach((em, ex) => {
                                    if (Number(item.confrimPriceDetail.pur_unit_price) > Number(em.tax_price)) {
                                        em.is_excess = 2
                                    } else if (Number(item.confrimPriceDetail.pur_unit_price) < Number(em.tax_price)) {
                                        em.is_excess = 1
                                    } else {
                                        em.is_excess = 0
                                    }
                                    updatePrice = [...updatePrice, em]
                                })
                            }

                        }

                        let dataColumn = {
                            key: key,
                            list: [
                                {
                                    name: item.confrimPriceDetail.name,
                                    purchase: [item.confrimPriceDetail.pur_unit_price, item.confrimPriceDetail.pur_total_price],
                                    procurement: [item.confrimPriceDetail.ver_unit_price, item.confrimPriceDetail.ver_total_price],
                                    is_excess: 0
                                }
                            ]
                        }

                        updatePrice.forEach((item, index) => {
                            let value = {
                                name: item.update_day,
                                purchase: [item.tax_price, item.tax_all],
                                procurement: [item.price, item.price_all],
                                is_excess: item.is_excess
                            }
                            dataColumn.list = [...dataColumn.list, value]
                        })

                        Info.ListInfo[0].dataColumn = [...Info.ListInfo[0].dataColumn, dataColumn]

                        console.log(Info.ListInfo[0].dataColumn)
                    })

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.proName
                        } else if (item.filedLabel === '项目编号') {
                            item.value = res.data.Detail.proNumber
                        } else if (item.filedLabel === '项目经理') {
                            item.value = res.data.Detail.proManager
                        } else if (item.filedLabel === '认价编号') {
                            item.value = res.data.Detail.confirmNumber
                        } else if (item.filedLabel === '中标供应商名称') {
                            item.value = res.data.Detail.supName
                        } else if (item.filedLabel === '备注') {
                            item.value = res.data.Detail.info
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.Detail.enclosure
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleWind = (name) => {
        let { Info } = this.state
        Info.ListInfo.forEach((item, index) => {
            if (item.plateName === name) {
                item.unWind = !item.unWind
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='材料调价审批' />

                <AddConfig key={FormKey} Info={Info} windEvent={this.handleWind} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
