import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import dayjs from 'dayjs'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { selectUserName, selectProjectName, reviewApplyResult } from "../../../utils/api"
import { createDispatchApply, dispatchDetail } from "../../../utils/oa"
import UserPopup from "../../../components/UserPopup"
import Icon from "../../../accets/img/icon_13.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '派遣申请',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目经理',
                        filedName: 'pro_user',
                        filedType: '抄送',
                        disabled: false,
                        required: true,
                        message: '',
                        value: [],
                    },
                    {
                        filedLabel: '项目地点',
                        filedName: 'pro_address',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入项目地点',
                        disabled: false,
                        required: true,
                        message: '请输入项目地点',
                        value: '',
                    },
                    {
                        filedLabel: '派遣开始时间',
                        filedName: 'start',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'minute',
                        placeholder: '',
                        disabled: false,
                        required: true,
                        message: '请选择开始日期',
                        value: '',
                    },
                    {
                        filedLabel: '派遣结束时间',
                        filedName: 'end',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'minute',
                        placeholder: '',
                        disabled: false,
                        required: true,
                        message: '请选择结束日期',
                        value: '',
                    },
                ],
            },
            Form: {},
            userName: '',
            pro_name: '',
            popupTitle: '',
            popup: {
                isShow: false,
                title: '',
                placeholder: '',
                multiple: false,
                disabled: false,
                dataSource: []
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_dispatchDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let { Info } = this.state;
        let userInfo = storage.getItem('userInfo');

        Info.apply.userName = userInfo.name
        Info.apply.deName = userInfo.deName
        Info.apply.apply_date = ''

        this.setState(preState => ({
            Info
        })
        )
    }

    get_dispatchDetail = (tableId) => {
        const { Info, Form } = this.state;
        let data = {
            table_id: tableId
        }
        dispatchDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        Form.table_id = tableId
                        Form.project_id = res.data.Detail.project_id
                        Form.pro_manager = res.data.Detail.pro_manager
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUserName
                    Info.apply.deName = res.data.Detail.deName
                    Info.apply.apply_date = res.data.Detail.created_at
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.projectName
                        } else if (item.filedLabel === '项目经理') {
                            item.value = []
                            let value = {
                                label: res.data.Detail.proManager,
                                value: res.data.Detail.pro_manager
                            }
                            item.value = [...item.value, value]
                        } else if (item.filedLabel === '项目地点') {
                            item.value = res.data.Detail.pro_address
                        } else if (item.filedLabel === '派遣开始时间') {
                            item.value = new Date(res.data.Detail.start)
                        } else if (item.filedLabel === '派遣结束时间') {
                            item.value = new Date(res.data.Detail.end)
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        Form,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { popup, userName } = this.state
        let data = {
            username: userName,
            page: 1,
            limit: 500
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    popup.dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                value: item.table_id,
                                label: item.username,
                            }
                            popup.dataSource = [...popup.dataSource, user]
                        })
                        popup.title = '选择人员'
                        popup.placeholder = '输入姓名查询人员'
                        popup.isShow = true
                    }
                    this.setState(preState => ({
                        popup,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_selectProjectName = () => {
        let { popup, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: 1,
            limit: 500
        }
        selectProjectName(data).then(
            (res) => {
                if (!res.code) {
                    popup.dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let pro = {
                                value: item.table_id,
                                label: item.pro_name,
                            }
                            popup.dataSource = [...popup.dataSource, pro]
                        })

                        popup.title = '选择项目'
                        popup.placeholder = '输入项目名称查询'
                        popup.isShow = true
                    }
                    this.setState(preState => ({
                        popup,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = (title) => {
        if (title === '项目名称') {
            this.get_selectProjectName()
        } else if (title === '项目经理') {
            this.get_InitialValue()
        }
        this.setState(preState => ({
            popupTitle: title,
        }))
    }

    handleVisible = (title, name) => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.filedLabel === title) {
                item.visible = true
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleQuery = (value) => {
        let { popupTitle } = this.state
        if (popupTitle === '项目名称') {
            this.setState(preState => ({
                pro_name: value,
            }), () => {
                this.get_selectProjectName()
            })
        } else if (popupTitle === '项目经理') {
            this.setState(preState => ({
                userName: value,
            }), () => {
                this.get_InitialValue()
            })
        }
    }

    handleClose = () => {
        this.setState(preState => ({
            userName: '',
            pro_name: '',
            popup: {
                isShow: false,
                title: '',
                placeholder: '',
                multiple: false,
                disabled: false,
                dataSource: []
            }
        }))
    }

    handleSelect = (value) => {
        let { popup, Info, Form, popupTitle } = this.state
        if (popupTitle === '项目名称') {
            if (value.length) {
                value.forEach((item, index) => {
                    popup.dataSource.forEach((em, ex) => {
                        if (em.value === item) {
                            Info.TableInfo.forEach((m, x) => {
                                if (m.filedLabel === '项目名称') {
                                    m.value = em.label
                                    Form.project_id = em.value
                                }
                            })
                        }
                    })
                })
            }
        } else if (popupTitle === '项目经理') {
            if (value.length) {
                value.forEach((item, index) => {
                    popup.dataSource.forEach((em, ex) => {
                        if (em.value === item) {
                            Info.TableInfo.forEach((m, x) => {
                                if (m.filedLabel === '项目经理') {
                                    m.value = []
                                    m.value = [...m.value, em]
                                    Form.pro_manager = em.value
                                }
                            })
                        }
                    })
                })
            }
        }

        this.setState(preState => ({
            Info,
            Form,
            userName: '',
            pro_name: '',
            popup: {
                isShow: false,
                title: '',
                placeholder: '',
                multiple: false,
                disabled: false,
                dataSource: []
            }
        }))
    }

    handleRemove = (value) => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.filedLabel === '项目经理') {
                item.value.forEach((em, ex) => {
                    if (em.value === value) {
                        item.value.splice(ex, 1)
                    }
                })
            }
        })

        this.setState(preState => ({
            Info
        }))
    }

    handleDate = (title) => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.filedLabel === title) {
                item.visible = false
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handlePresent = (data) => {
        const { Info, Form } = this.state
        Form.status = 2
        Form.pro_address = data.pro_address
        Form.start = dayjs(data.start).format('YYYY-MM-DD')
        Form.end = dayjs(data.end).format('YYYY-MM-DD')

        createDispatchApply(Form).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '提交成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '提交失败,请检查表单!',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info, popup } = this.state
        return (
            <div>
                <NavBar menuName='新建派遣申请' />

                <AddConfig key={FormKey} Info={Info} buttonEvent={this.handleButton} visibleEvent={this.handleVisible} dateEvent={this.handleDate} removeEvent={this.handleRemove} presentEvent={this.handlePresent} auditEvent={this.handleAudit} />

                <UserPopup popup={popup} closeEvent={this.handleClose} queryEvent={this.handleQuery} selectEvent={this.handleSelect} />
            </div>
        )
    }
}

export default withRouter(NewItem)
