import React, { Component } from 'react'

export default class DateTime extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current_time: '',
    }
  }

  componentDidMount() {
    let time = new Date() //获取当前时间戳
    this.getFamtterTimer(time)
  }

  getFamtterTimer = (time) => { //传入时间
    let date = new Date(time),
      Y = date.getFullYear(), // 年  
      M = date.getMonth() + 1, //月  
      D = date.getDate(); //日
    // h = date.getHours(), //小时
    // m = date.getMinutes(), //分钟
    // s = date.getSeconds(); //秒数
    // document.getElementById("time").innerHTML = Y + '-' + getZero(M) + '-' + getZero(D) + ' ' + getZero(h) + ':' +
    //   getZero(m) + ':' + getZero(s);
    let currentTime = Y + '-' + this.getZero(M) + '-' + this.getZero(D)
    this.setState(preState => ({
      current_time: currentTime
    }))
  }

  getZero = (num) => { // 个位数前补0
    if (parseInt(num) < 10) {
      num = '0' + num;
    }
    return num;
  }

  render() {
    let { current_time } = this.state
    return (
      <div>
        {current_time}
      </div>
    )
  }
}
