import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { approvalProcess } from "../../utils/api"
import NavBar from "../components/NavRar"
import workList from "./Work"
import projectList from "./Project"
import Icon from "../../accets/img/icon_19.png"

class Process extends Component {
  constructor(props) {
    super(props)
    this.state = {
      processList: []
    }
  }

  componentDidMount() {
    this.get_myProcess()
  }

  get_myProcess = () => {
    let { processList } = this.state
    let menuList = [...workList, ...projectList]
    approvalProcess().then(
      (res) => {
        if (!res.code) {
          processList = []
          if (res.data.flows.length) {
            res.data.flows.forEach((item, index) => {
              let value = {
                key: index + 1,
                table_id: item.table_id,
                path: item.deteilUrl,
                apply_date: item.created_at,
                title: item.functionName,
                status: item.review_status,
              }
              processList = [...processList, value]
            })

            processList.forEach((item, index) => {
              item.icon = Icon
              menuList.forEach((em, ex) => {
                if (item.title === em.title) {
                  item.icon = em.icon
                }
              })
            })
          }

          this.setState(preState => ({
            processList
          })
          )
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  get_details(item) {
    this.props.history.push({
      pathname: item.path,
      query: {
        'tableId': item.table_id,
      }
    })
  }

  render() {
    let { processList } = this.state
    return (
      <div>
        <NavBar menuName='我的流程' />

        <div className='prompt_list'>
          {
            processList.map((item, index) => {
              return (
                <div key={index} onClick={() => this.get_details(item)}>
                  <div>{item.apply_date}</div>
                  <div>
                    <div>
                      <div style={{ width: 0, height: 0, marginRight: 0 }}></div>
                      <div>
                        <img src={item.icon} alt=""></img>
                        <div>我的{item.title}。</div>
                      </div>
                    </div>
                    {
                      item.status === 2 ? <div className='icon_02'>未审批</div> : item.status === 3 ? <div className='icon_03'>已驳回</div> : item.status === 4 ? <div className='icon_05'>已完成</div>:item.status === 5 ? <div className='icon_04'>已终止</div>:''
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default withRouter(Process)
