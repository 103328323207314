import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { RecognitionPriceDetail } from "../../../utils/public"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_56.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '认质核价审批',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '认价类别',
                        filedName: 'class',
                        filedType: '单选',
                        placeholder: '请选择',
                        disabled: true,
                        required: true,
                        message: '',
                        value: [],
                        radioList: [
                            { value: 1, label: "项目类" },
                            { value: 2, label: "科研类" },
                        ]
                    },
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                        unShow:true
                    },
                    {
                        filedLabel: '合同甲方',
                        filedName: 'party_a',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '合同乙方',
                        filedName: 'party_b',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '开票类型',
                        filedName: 'inv_type',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '税率(%)',
                        filedName: 'tax',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '付款方式',
                        filedName: 'pay_model',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '是否含运费',
                        filedName: 'is_fare',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '备注',
                        filedName: 'info',
                        filedType: '文本域',
                        placeholder: '请输入备注',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                ],
                ListInfo: [
                    {
                        plateName: '清单明细',
                        unWind: false,
                        dataSource: []
                    },
                ]
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_RecognitionPriceDetail(tableId)
        }
    }

    get_RecognitionPriceDetail = (tableId) => {
        const { Info } = this.state;
        let data = {
            table_id: tableId
        }
        RecognitionPriceDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.status === 2 || res.data.status === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '附件') {
                                if (!res.data.enclosure || !res.data.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.status
                    Info.apply.userName = res.data.applyUsername
                    Info.apply.deName = res.data.applyrDeName
                    Info.apply.apply_date = res.data.apply_day
                    Info.apply.process = res.data.reviewAudit
                    Info.apply.process.editPermission = res.data.editPermission

                    Info.ListInfo[0].dataSource = []
                    if (res.data.class === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '项目名称') {
                                item.unShow = false
                                item.value = res.data.pro_name
                            }
                        })

                        res.data.details.forEach((item, index) => {
                            let key = index + 1
                            let dataSource = {
                                key: key,
                                list: [
                                    {
                                        filedLabel: '材料设备名称',
                                        filedName: 'name_' + key,
                                        filedType: '文本',
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.name,
                                    },
                                    {
                                        filedLabel: '暂定数量',
                                        filedName: 'number_' + key,
                                        filedType: '文本',
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.number,
                                    },
                                    {
                                        filedLabel: '报审单价(元)',
                                        filedName: 'price_' + key,
                                        filedType: '文本',
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.price,
                                    },
                                    {
                                        filedLabel: '暂定总价(元)',
                                        filedName: 'all_price_' + key,
                                        filedType: '文本',
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.all_price,
                                    },
                                ]
                            }
                            Info.ListInfo[0].dataSource = [...Info.ListInfo[0].dataSource, dataSource]
                        })
                    } else {
                        res.data.details.forEach((item, index) => {
                            let key = index + 1
                            let dataSource = {
                                key: key,
                                list: [
                                    {
                                        filedLabel: '植物',
                                        filedName: 'name_' + key,
                                        filedType: '文本',
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.name,
                                    },
                                    {
                                        filedLabel: '数量',
                                        filedName: 'number_' + key,
                                        filedType: '文本',
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.number,
                                    },
                                    {
                                        filedLabel: '成品苗生产单价',
                                        filedName: 'price_' + key,
                                        filedType: '文本',
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.price,
                                    },
                                    {
                                        filedLabel: '合计',
                                        filedName: 'all_price_' + key,
                                        filedType: '文本',
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.all_price,
                                    },
                                ]
                            }
                            Info.ListInfo[0].dataSource = [...Info.ListInfo[0].dataSource, dataSource]
                        })
                    }


                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '认价类别') {
                            item.value = []
                            item.value = [...item.value, res.data.class]
                        } else if (item.filedLabel === '合同甲方') {
                            item.value = res.data.party_a
                        } else if (item.filedLabel === '合同乙方') {
                            item.value = res.data.party_b
                        } else if (item.filedLabel === '开票类型') {
                            item.value = res.data.inv_type
                        } else if (item.filedLabel === '税率(%)') {
                            item.value = res.data.tax
                        } else if (item.filedLabel === '付款方式') {
                            item.value = res.data.pay_model
                        } else if (item.filedLabel === '是否含运费') {
                            item.value = res.data.is_fare
                        } else if (item.filedLabel === '备注') {
                            item.value = res.data.info
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.enclosure
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleWind = (name) => {
        let { Info } = this.state
        Info.ListInfo.forEach((item, index) => {
            if (item.plateName === name) {
                item.unWind = !item.unWind
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='认质核价审批(358工程)' />

                <AddConfig key={FormKey} Info={Info} windEvent={this.handleWind} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
