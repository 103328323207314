import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { selectUserName } from "../../../utils/api"
import { createWeekly, weeklyDetail } from "../../../utils/oa"
import UserPopup from "../../../components/UserPopup"
import Icon from "../../../accets/img/icon_01.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '工作周报',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入项目名称',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '本周完成工作',
                        filedName: 'over_content',
                        filedType: '文本域',
                        placeholder: '请输入本周完成工作',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '下周工作计划',
                        filedName: 'next_week',
                        filedType: '文本域',
                        placeholder: '请输入下周工作计划',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '备注',
                        filedName: 'info',
                        filedType: '文本域',
                        placeholder: '请输入备注',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: [],
                    },
                    {
                        filedLabel: '接收人',
                        filedName: 'copy',
                        filedType: '抄送',
                        disabled: false,
                        required: true,
                        message: '',
                        value: [],
                    },
                ]
            },
            Form: {},
            userName: '',
            popup: {
                isShow: false,
                title:'选择人员',
                placeholder: '输入姓名查询人员',
                multiple: false,
                disabled: false,
                dataSource: []
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_weeklyDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let { Info } = this.state;
        let userInfo = storage.getItem('userInfo');
        console.log(userInfo)

        Info.apply.userName = userInfo.name
        Info.apply.deName = userInfo.deName
        Info.apply.apply_date = ''

        this.setState(preState => ({
            Info
        })
        )
    }

    get_weeklyDetail = (tableId) => {
        const { Info, Form } = this.state;
        let data = {
            table_id: tableId
        }
        weeklyDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1) {
                        Form.table_id = tableId

                        if (res.data.Detail.readUserName) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.filedLabel === '接收人') {
                                    item.value = []
                                    res.data.Detail.readUserName.forEach((em, ex) => {
                                        let value = {
                                            label: em.username,
                                            value: em.table_id
                                        }
                                        item.value = [...item.value, value]
                                    })
                                }
                            })
                        }
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.filedLabel === '接收人') {
                                item.unShow = true
                            } else if (item.filedLabel === '附件') {
                                if (!res.data.Detail.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status === 2 ? 4 : res.data.Detail.status
                    Info.apply.userName = res.data.Detail.username
                    Info.apply.deName = res.data.Detail.deName
                    Info.apply.apply_date = res.data.Detail.created_at
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission                   

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.pro_name
                        } else if (item.filedLabel === '本周完成工作') {
                            item.value = res.data.Detail.over_content
                        } else if (item.filedLabel === '下周工作计划') {
                            item.value = res.data.Detail.next_week
                        } else if (item.filedLabel === '备注') {
                            item.value = res.data.Detail.info
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.Detail.enclosure
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        Form,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { popup, userName } = this.state
        let data = {
            username: userName,
            page: 1,
            limit: 500
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    popup.dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                value: item.table_id,
                                label: item.username,
                            }
                            popup.dataSource = [...popup.dataSource, user]
                        })
                        popup.isShow = true
                    }
                    this.setState(preState => ({
                        popup,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = () => {
        this.get_InitialValue()
    }

    handleQuery = (value) => {
        this.setState(preState => ({
            userName: value,
        }), () => {
            this.get_InitialValue()
        })
    }

    handleClose = () => {
        this.setState(preState => ({
            userName: '',
            popup: {
                isShow: false,
                placeholder: '输入姓名查询人员',
                multiple: false,
                disabled: false,
                dataSource: []
            }
        }))
    }

    handleSelect = (value) => {
        let { popup, Info } = this.state
        let selectList = []
        if (value.length) {
            value.forEach((item, index) => {
                popup.dataSource.forEach((em, ex) => {
                    if (em.value === item) {
                        selectList = [...selectList, em]
                    }
                })
            })
        }

        Info.TableInfo.forEach((item, index) => {
            if (item.filedLabel === '接收人') {
                item.value = [...item.value, ...selectList]
            }
        })

        this.setState(preState => ({
            Info,
            userName: '',
            popup: {
                isShow: false,
                placeholder: '输入姓名查询人员',
                multiple: false,
                disabled: false,
                dataSource: []
            }
        }))
    }

    handleRemove = (value) => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.filedLabel === '接收人') {
                item.value.forEach((em, ex) => {
                    if (em.value === value) {
                        item.value.splice(ex, 1)
                    }
                })
            }
        })

        this.setState(preState => ({
            Info
        }))
    }

    handleFile = (fileList, fileName) => {
        let { Form, Info } = this.state
        let storeList = []
        if (fileList.length) {
            fileList.forEach((item, index) => {
                storeList.push(item.table_id)
            })
        }
        Form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Form,
            Info
        })
        )
    }

    handlePresent = (data) => {
        const { Form, Info } = this.state
        Form.status = 2
        Form.pro_name = data.pro_name
        Form.over_content = data.over_content
        Form.next_week = data.next_week
        Form.info = data.info
        Info.TableInfo.forEach((item, index) => {
            if (item.filedLabel === '接收人') {
                if (item.value.length) {
                    let read_user = []
                    item.value.forEach((em, ex) => {
                        read_user.push(em.value)
                    })
                    Form.read_users = JSON.stringify(read_user)
                }
            }
        })

        createWeekly(Form).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '提交成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        // let select = {
                        //     audit_id: res.audit_id,
                        //     tableName: res.tableName,
                        //     table_id: res.table_id,
                        //     data: res.data,
                        // }

                        // Info.select = select

                        // this.setState({
                        //     Info
                        // })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '提交失败,请检查表单!',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info, popup } = this.state
        return (
            <div>
                <NavBar menuName='新建周报' />

                <AddConfig key={FormKey} Info={Info} buttonEvent={this.handleButton} removeEvent={this.handleRemove} fileEvent={this.handleFile} presentEvent={this.handlePresent} />

                <UserPopup popup={popup} closeEvent={this.handleClose} queryEvent={this.handleQuery} selectEvent={this.handleSelect} />
            </div>
        )
    }
}

export default withRouter(NewItem)
