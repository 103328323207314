import React, { Component } from 'react'
import { PullToRefresh, InfiniteScroll } from 'antd-mobile'
import { withRouter } from "react-router-dom";
import { RightOutline } from 'antd-mobile-icons'
import Add from "../../accets/img/add.png"

class Content extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    getNextData = () => {
        this.props.TabEvent(2)
    }

    loadMore = () => {
        this.props.LimitEvent()
    }

    handleNew = (pathname) => {
        this.props.history.push(pathname)
    }

    get_details(pathname, tableId) {
        this.props.history.push({
            pathname: pathname,
            query: {
                'tableId': tableId,
            }
        })
    }

    render() {
        let { menuName, pathname, dataSource, status, TabEvent, Loading } = this.props
        return (
            <PullToRefresh onRefresh={async () => { this.getNextData() }}>
                <div className='new_content' onClick={() => this.handleNew(pathname)}>
                    <div>
                        <img src={Add} alt=""></img>
                        <div>{menuName}</div>
                    </div>
                    <RightOutline fontSize={20} color='#3396FB' />
                </div>

                {
                    <div className='content_tab'>
                        <div className={status === 2 ? 'activity' : ''} onClick={() => TabEvent(2)}>审批中</div>
                        <div className={status === 4 ? 'activity' : ''} onClick={() => TabEvent(4)}>已完成</div>
                        <div className={!status ? 'activity' : ''} onClick={() => TabEvent('')}>所有</div>
                    </div>
                }

                <div className='content_list'>

                    {
                        dataSource.map((item, index) => {
                            return (
                                <div key={index} onClick={() => {
                                    if (menuName !== '新增出勤签到') {
                                        this.get_details(pathname, item.table_id)
                                    }
                                }}>
                                    <div className='userInfo'>
                                        <div>
                                            <div>{item.username}</div>
                                            <div>{item.username}</div>
                                        </div>
                                        <div>部门名称: {item.deName}</div>
                                    </div>
                                    <div>

                                        {
                                            item.content.map((em, ex) => {
                                                return (
                                                    <div key={ex}>
                                                        <div>{em.label}</div>
                                                        <div>{em.value}</div>
                                                    </div>
                                                )
                                            })
                                        }

                                        {/* <div>
                                            <div>项目名称</div>
                                            <div>大连裕景项目</div>
                                        </div>
                                        <div>
                                            <div>本周完成工作</div>
                                            <div>完成蒙顶山禅茶文化体验中心...</div>
                                        </div>
                                        <div>
                                            <div>下周工作计划</div>
                                            <div>继续完成成都一环路桥体提升...</div>
                                        </div>
                                        <div>
                                            <div>备注</div>
                                            <div>/</div>
                                        </div> */}

                                        {

                                        }

                                    </div>
                                    <div>
                                        <div>申请日期</div>
                                        <div>{item.apply_date}</div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
                <InfiniteScroll threshold={50} loadMore={() => { this.loadMore() }} hasMore={Loading} />
            </PullToRefresh>
        )
    }
}

export default withRouter(Content)
