import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { CostProgressDetail } from "../../../utils/public"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_63.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '工程进度款审批',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '是否盖章',
                        filedName: 'is_seal',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                label: '是',
                                value: 1,
                            }, {
                                label: '否',
                                value: 2,
                            }
                        ]
                    },
                    {
                        filedLabel: '印章名称',
                        filedName: 'seal_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '进度期数',
                        filedName: 'stage',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '合同金额(元)',
                        filedName: 'con_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '付款方式',
                        filedName: 'term_payment',
                        filedType: '文本域',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '本期计量申报金额(元)',
                        filedName: 'mea_dec',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '本期计量审定金额(元)',
                        filedName: 'metering',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '累计计量审定金额(元)',
                        filedName: 'grand_meter',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '进度款支付比例(%)',
                        filedName: 'pay_pro',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '本期应付金额(元)',
                        filedName: 'pay_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '累计应付金额(元)',
                        filedName: 'grand_pay_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '累计支付比例(%)',
                        filedName: 'grand_pro',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '备注',
                        filedName: 'info',
                        filedType: '文本域',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                ],
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_CostProgressDetail(tableId)
        }
    }

    get_CostProgressDetail = (tableId) => {
        const { Info } = this.state;
        let data = {
            table_id: tableId
        }
        CostProgressDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 2 || res.data.Detail.status === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '附件') {
                                if (!res.data.Detail.enclosure || !res.data.Detail.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUserName
                    Info.apply.deName = res.data.Detail.applyDeName
                    Info.apply.apply_date = res.data.Detail.apply_day
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission


                    if (res.data.Detail.is_seal === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '印章名称') {
                                item.unShow = false
                                item.value = res.data.Detail.sealNames
                            }
                        })
                    }

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '是否盖章') {
                            item.value = []
                            item.value = [...item.value, Number(res.data.Detail.is_seal)]
                        } else if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.pro_name
                        } else if (item.filedLabel === '进度期数') {
                            item.value = res.data.Detail.stage
                        } else if (item.filedLabel === '合同金额(元)') {
                            item.value = res.data.Detail.set_money
                        } else if (item.filedLabel === '付款方式') {
                            item.value = res.data.Detail.term_payment
                        } else if (item.filedLabel === '本期计量申报金额(元)') {
                            item.value = res.data.Detail.mea_dec
                        } else if (item.filedLabel === '本期计量审定金额(元)') {
                            item.value = res.data.Detail.metering
                        } else if (item.filedLabel === '累计计量审定金额(元)') {
                            item.value = res.data.Detail.grand_meter
                        } else if (item.filedLabel === '进度款支付比例(%)') {
                            item.value = res.data.Detail.pay_pro
                        } else if (item.filedLabel === '本期应付金额(元)') {
                            item.value = res.data.Detail.pay_money
                        } else if (item.filedLabel === '累计应付金额(元)') {
                            item.value = res.data.Detail.grand_pay_money
                        } else if (item.filedLabel === '累计支付比例(%)') {
                            item.value = res.data.Detail.grand_pro
                        } else if (item.filedLabel === '备注') {
                            item.value = res.data.Detail.info
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.Detail.enclosure || []
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='工程进度款审批' />

                <AddConfig key={FormKey} Info={Info} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
