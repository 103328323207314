import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { auxiliary_options, getDecisionDetail } from "../../../utils/public"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_22.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '投标决策审批表',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '联营自营',
                        filedName: 'operation',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: []
                    },
                    {
                        filedLabel: '申请编号',
                        filedName: 'number',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '工程编号',
                        filedName: 'eng_number',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '默认',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目地址',
                        filedName: 'pro_address',
                        filedType: '默认',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '建设单位(招标人)',
                        filedName: 'con_unit',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '集团名称',
                        filedName: 'group_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '招标代理名称',
                        filedName: 'bidding_agency',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '开标时间',
                        filedName: 'opening_time',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '资质要求',
                        filedName: 'qua_require',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '工期(天)',
                        filedName: 'con_period',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目性质',
                        filedName: 'pro_nature',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: []
                    },
                    {
                        filedLabel: '项目经理资质要求',
                        filedName: 'manager_qua',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '技术负责人资质要求',
                        filedName: 'technical_qua',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目管理人员资质要求',
                        filedName: 'management_qua',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '答辩团队要求',
                        filedName: 'defense_team',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '业绩要求',
                        filedName: 'per_requirements',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '样品要求',
                        filedName: 'sample_request',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '投标保证金金额(元)',
                        filedName: 'tender_bond',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '投标保证金退还时间',
                        filedName: 'tender_refund_time',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '履约保证金金额(元)',
                        filedName: 'bid_bond_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '履约保证金退还时间',
                        filedName: 'bid_refund_time',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '预付款付款条件',
                        filedName: 'advance_charge',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '进度款付款条件',
                        filedName: 'progress_payment',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '质保期',
                        filedName: 'guarantee_period',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '养护期',
                        filedName: 'curing_period',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '质保金(合同总价的x%)',
                        filedName: 'guarantee_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '踏勘要求',
                        filedName: 'survey_requirements',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '合同履约性质',
                        filedName: 'performance_nature',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: []
                    },
                    {
                        filedLabel: '其他隐性要求',
                        filedName: 'hidden_conditions',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '开标要求',
                        filedName: 'bid_opening_requirements',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '备注',
                        filedName: 'remarks',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                ]
            },
        }
    }

    componentDidMount() {
        this.get_auxiliary_options()
    }

    get_auxiliary_options = () => {
        let { Info } = this.state
        auxiliary_options().then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '联营自营') {
                            for (let key in res.data.operationMode) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.operationMode[key]
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        } else if (item.filedLabel === '项目性质') {
                            for (let key in res.data.projectNature) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.projectNature[key]
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        } else if (item.filedLabel === '合同履约性质') {
                            for (let key in res.data.naturePerformance) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.naturePerformance[key]
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        }
                    })

                    this.setState(preState => ({
                        Info
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_DecisionDetail(tableId)
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_DecisionDetail = (tableId) => {
        const { Info, Form } = this.state;
        let data = {
            table_id: tableId
        }
        getDecisionDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 2 || res.data.Detail.status === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '附件') {
                                if (!res.data.Detail.enclosure || !res.data.Detail.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.username
                    Info.apply.deName = res.data.Detail.applyUserDeName
                    Info.apply.apply_date = res.data.Detail.apply_day
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '联营自营') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.operation]
                        } else if (item.filedLabel === '申请编号') {
                            item.value = res.data.Detail.number
                        } else if (item.filedLabel === '工程编号') {
                            item.value = res.data.Detail.eng_number
                        } else if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.pro_name
                        } else if (item.filedLabel === '项目地址') {
                            item.value = res.data.Detail.pro_address
                        } else if (item.filedLabel === '建设单位(招标人)') {
                            item.value = res.data.Detail.con_unit
                        } else if (item.filedLabel === '集团名称') {
                            item.value = res.data.Detail.group_name
                        } else if (item.filedLabel === '招标代理名称') {
                            item.value = res.data.Detail.bidding_agency
                        } else if (item.filedLabel === '开标时间') {
                            item.value = res.data.Detail.opening_time ? new Date(res.data.Detail.opening_time) : ''
                        } else if (item.filedLabel === '资质要求') {
                            item.value = res.data.Detail.qua_require
                        } else if (item.filedLabel === '工期(天)') {
                            item.value = res.data.Detail.con_period
                        } else if (item.filedLabel === '项目性质') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.pro_nature]
                        } else if (item.filedLabel === '项目经理资质要求') {
                            item.value = res.data.Detail.manager_qua
                        } else if (item.filedLabel === '技术负责人资质要求') {
                            item.value = res.data.Detail.technical_qua
                        } else if (item.filedLabel === '项目管理人员资质要求') {
                            item.value = res.data.Detail.management_qua
                        } else if (item.filedLabel === '答辩团队要求') {
                            item.value = res.data.Detail.defense_team
                        } else if (item.filedLabel === '业绩要求') {
                            item.value = res.data.Detail.per_requirements
                        } else if (item.filedLabel === '样品要求') {
                            item.value = res.data.Detail.sample_request
                        } else if (item.filedLabel === '投标保证金金额(元)') {
                            item.value = res.data.Detail.tender_bond
                        } else if (item.filedLabel === '投标保证金退还时间') {
                            item.value = res.data.Detail.tender_refund_time ? new Date(res.data.Detail.tender_refund_time) : ''
                        } else if (item.filedLabel === '履约保证金金额(元)') {
                            item.value = res.data.Detail.bid_bond_money
                        } else if (item.filedLabel === '履约保证金退还时间') {
                            item.value = res.data.Detail.bid_refund_time ? new Date(res.data.Detail.bid_refund_time) : ''
                        } else if (item.filedLabel === '预付款付款条件') {
                            item.value = res.data.Detail.advance_charge
                        } else if (item.filedLabel === '进度款付款条件') {
                            item.value = res.data.Detail.progress_payment
                        } else if (item.filedLabel === '质保期') {
                            item.value = res.data.Detail.guarantee_period
                        } else if (item.filedLabel === '养护期') {
                            item.value = res.data.Detail.curing_period
                        } else if (item.filedLabel === '质保金(合同总价的x%)') {
                            item.value = res.data.Detail.guarantee_money
                        } else if (item.filedLabel === '踏勘要求') {
                            item.value = res.data.Detail.survey_requirements
                        } else if (item.filedLabel === '合同履约性质') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.performance_nature]
                        } else if (item.filedLabel === '其他隐性要求') {
                            item.value = res.data.Detail.hidden_conditions
                        } else if (item.filedLabel === '开标要求') {
                            item.value = res.data.Detail.bid_opening_requirements
                        } else if (item.filedLabel === '备注') {
                            item.value = res.data.Detail.remarks
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.Detail.enclosure || []
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        Form,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='投标决策审批表' />

                <AddConfig key={FormKey} Info={Info} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
