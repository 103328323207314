import React, { Component } from 'react'
import md5 from 'md5'
import { Upload } from 'antd';
import { Toast, ProgressBar } from 'antd-mobile'
import { CloseOutline } from 'antd-mobile-icons'
import { fileUpload, deleteFile } from "../utils/api"
import attachImg from "../accets/img/attach.png"

export default class UserAttach extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fileList: [],//存放上传信息：比如路径、文件名
            file: {},
            fileChunkList: [],
            is_download: 1,
            isProgress: false,
            percent: 0,
            fileMd5: '',
        };
    }

    upload = React.createRef();

    componentDidMount() {
        this.setState(preState => ({
            fileList: this.props.fileList
        }))
    }

    updateChange = ({ file, fileList }) => {
        this.setState(preState => ({
            file: file,
            is_download: 1
        }), () => {
            this.createFileChunk()
        })
    }

    createFileChunk = () => {
        let { file, fileChunkList, isProgress } = this.state
        let chunkSize = 1024 * 1024  // 切片每次1M
        let filecount = Math.ceil(file.size / chunkSize)
        let currentChunk = 0 // 当前上传的chunk
        let index = 0
        fileChunkList = []
        while (currentChunk < file.size) {
            const chunk = file.slice(currentChunk, currentChunk + chunkSize);
            fileChunkList.push({ file: chunk, index, filecount }); // 可以加入很多信息。比如MD5
            currentChunk += chunkSize;
            index += 1;
        }

        if (filecount > 5) {
            isProgress = true
        }

        fileChunkList.forEach((item, index) => {
            if (index === 0) {
                let reader = new FileReader()
                let fileDate = item.file
                reader.readAsDataURL(fileDate)
                reader.onload = (e) => {
                    this.setState(preState => ({
                        fileChunkList,
                        isProgress,
                        fileMd5: md5(e.target.result)
                    }), () => {
                        this.fileUpload(0)
                    })
                }
            }
        })


    }

    fileUpload = (uploadIndex) => {
        let { file, fileChunkList, is_download, fileList, fileMd5 } = this.state
        fileChunkList.forEach((item, index) => {//分片传输
            if (index === uploadIndex) {
                let reader = new FileReader()
                let fileDate = item.file
                let data = {
                    file: '',
                    name: file.name,
                    index: item.index,
                    filecount: item.filecount,
                    MD5: fileMd5,
                    is_download: is_download
                }

                reader.readAsDataURL(fileDate)
                reader.onload = (e) => {
                    data.file = e.target.result

                    fileUpload(data).then(
                        (res) => {
                            if (!res.code) {
                                if (res.data.result) {
                                    let index = res.data.index
                                    this.fileUpload(index)

                                    this.setState(preState => ({
                                        percent: Math.ceil((100 / item.filecount) * index)
                                    })
                                    )
                                } else {
                                    let value = {
                                        filename: file.name,
                                        table_id: res.data.att_id,
                                        path: res.data.path,
                                    }
                                    fileList.push(value)
                                    this.props.fileEvent(fileList, this.props.filedName)
                                    Toast.show({
                                        icon: 'success',
                                        content: '上传成功',
                                    })
                                    this.setState(preState => ({
                                        fileList,
                                        percent: 0,
                                        isProgress: false,
                                        fileMd5: ''
                                    })
                                    )
                                }
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            }
        })
    }

    // 移除文件
    removeFile = (index) => {
        const { fileList } = this.state
        fileList.splice(index, 1)
        this.props.fileEvent(fileList, this.props.filedName)
        this.setState(preState => ({
            fileList
        })
        )
    }

    get_deleteFile = (index) => {
        const { fileList } = this.state
        let data = {
            file_id: fileList[index].table_id
        }
        deleteFile(data).then(
            (res) => {
                if (!res.code) {
                    this.removeFile(index)
                } else {
                    Toast.show({
                        icon: 'fail',
                        content: '删除失败',
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        const { percent, isProgress, fileList } = this.state
        return (
            <div className='fileList'>
                <div>
                    <Upload
                        showUploadList={false}
                        beforeUpload={(file, fileList) => {
                            return false
                        }}
                        onChange={this.updateChange}   //上传状态改变事件                      
                    >
                        <img ref={this.upload} src={attachImg} alt="" ></img>
                    </Upload>
                    {
                        isProgress ? <ProgressBar percent={percent} text style={{
                            '--track-width': '8px',
                        }} /> : ''
                    }
                </div>
                <div>
                    {
                        fileList.length ? fileList.map((item, index) => {
                            return (
                                <div key={index}>
                                    <div>{item.filename}</div>
                                    <div>
                                        <CloseOutline color="#3396FB" onClick={() => {
                                            this.get_deleteFile(index)
                                        }} />
                                    </div>
                                </div>
                            )
                        }) : ''
                    }
                </div>
            </div >
        )
    }
}
