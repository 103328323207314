import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { PatentRenewalDetail } from "../../../utils/public"
import { reviewApplyResult, } from "../../../utils/api"
import Icon from "../../../accets/img/icon_60.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '专利续费付款审批',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '合同类别',
                        filedName: 'con_type',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入合同类别',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '合同名称',
                        filedName: 'con_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入合同名称',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '合同编号',
                        filedName: 'con_code',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入合同编号',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '付款金额(元)',
                        filedName: 'pay_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入付款金额(元)',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '公司名称',
                        filedName: 'company',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入公司名称',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '开户行',
                        filedName: 'bank_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入开户行',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '银行账号',
                        filedName: 'bank_num',
                        filedType: '文本',
                        placeholder: '请输入银行账号',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '付款单位',
                        filedName: 'pay_unit',
                        filedType: '单选',
                        placeholder: '请选择',
                        disabled: true,
                        required: true,
                        message: '请选择',
                        value: [],
                        radioList: [
                            { value: 1, label: "四川天艺生态园林集团股份有限公司" },
                            { value: 2, label: "四川天艺优境环境科技有限公司" },
                            { value: 3, label: "四川天艺致远物联网科技有限公司" },
                            { value: 4, label: '四川天艺传奇生态茶业有限公司' }
                        ]
                    },

                    {
                        filedLabel: '是否欠票',
                        filedName: 'has_owing',
                        filedType: '单选',
                        placeholder: '请选择',
                        disabled: true,
                        required: true,
                        message: '请选择',
                        value: [],
                        radioList: [
                            {
                                value: 1,
                                label: '是'
                            },
                            {
                                value: 2,
                                label: '否'
                            },
                        ]
                    },
                    {
                        filedLabel: '欠票单位',
                        filedName: 'unit_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入欠票单位',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '欠票类型',
                        filedName: 'invo_type',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入欠票类型',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '欠票金额',
                        filedName: 'money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入欠票金额',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '欠票原因',
                        filedName: 'cause',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入欠票原因',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '补票时间',
                        filedName: 'replenish_invo',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '请选择出补票时间',
                        value: '',
                    },
                    {
                        filedLabel: '备注',
                        filedName: 'info',
                        filedType: '文本域',
                        placeholder: '请输入备注',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                ],
                ListInfo: [
                    {
                        plateName: '专利续费明细',
                        unWind: false,
                        dataSource: []
                    },
                ]
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_PatentRenewalDetail(tableId)
        }
    }

    get_PatentRenewalDetail = (tableId) => {
        const { Info, Form } = this.state;
        let data = {
            table_id: tableId
        }
        PatentRenewalDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 2 || res.data.Detail.status === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (!res.data.Detail.enclosure.length) {
                                if (item.filedLabel === '附件') {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUserName
                    Info.apply.deName = res.data.Detail.applyDeName
                    Info.apply.apply_date = res.data.Detail.apply_date
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.ListInfo[0].dataSource = []
                    res.data.Detail.details.forEach((item, index) => {
                        let key = index + 1
                        let dataSource = {
                            key: key,
                            list: [
                                {
                                    filedLabel: '成果名称',
                                    filedName: 'ach_name_' + key,
                                    filedType: '文本',
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.ach_name,
                                },
                                {
                                    filedLabel: '专利类型',
                                    filedName: 'ach_class_' + key,
                                    filedType: '文本',
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.ach_class,
                                },
                                {
                                    filedLabel: '专利领域',
                                    filedName: 'ach_area_' + key,
                                    filedType: '文本',
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.ach_area,
                                },
                                {
                                    filedLabel: '续费金额(元)',
                                    filedName: 'renewal_' + key,
                                    filedType: '文本',
                                    placeholder: '请输入续费金额(元)',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.renewal,
                                },
                            ]
                        }
                        Info.ListInfo[0].dataSource = [...Info.ListInfo[0].dataSource, dataSource]
                    })


                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '合同类别') {
                            item.value = res.data.Detail.con_type
                        } else if (item.filedLabel === '合同名称') {
                            item.value = res.data.Detail.con_name
                        } else if (item.filedLabel === '合同编号') {
                            item.value = res.data.Detail.con_code
                        } else if (item.filedLabel === '付款金额(元)') {
                            item.value = res.data.Detail.pay_money
                        } else if (item.filedLabel === '公司名称') {
                            item.value = res.data.Detail.company
                        } else if (item.filedLabel === '开户行') {
                            item.value = res.data.Detail.bank_name
                        } else if (item.filedLabel === '银行账号') {
                            item.value = res.data.Detail.bank_num
                        } else if (item.filedLabel === '付款单位') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.pay_unit]
                        } else if (item.filedLabel === '备注') {
                            item.value = res.data.Detail.info
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.Detail.enclosure
                        } else if (item.filedLabel === '是否欠票') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.has_owing]
                        } else if (item.filedLabel === '欠票单位') {
                            item.value = res.data.Detail.unit_name || '/'
                        } else if (item.filedLabel === '欠票类型') {
                            item.value = res.data.Detail.invo_type || '/'
                        } else if (item.filedLabel === '欠票金额') {
                            item.value = res.data.Detail.money || '/'
                        } else if (item.filedLabel === '欠票原因') {
                            item.value = res.data.Detail.cause || '/'
                        } else if (item.filedLabel === '补票时间') {
                            item.value = res.data.Detail.replenish_invo ? new Date(res.data.Detail.replenish_invo) : ''
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        Form,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleWind = (name) => {
        let { Info } = this.state
        Info.ListInfo.forEach((item, index) => {
            if (item.plateName === name) {
                item.unWind = !item.unWind
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info, popup } = this.state
        return (
            <div>
                <NavBar menuName='专利续费付款审批' />

                <AddConfig key={FormKey} Info={Info}  windEvent={this.handleWind} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
