import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { biddingMarginDetail, getMarginAuxiliary } from "../../../utils/public"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_26.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '投标保证金支付申请',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '申请编号',
                        filedName: 'apply_number',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '是否盖章',
                        filedName: 'is_seal',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                label: '是',
                                value: 1,
                            }, {
                                label: '否',
                                value: 2,
                            }
                        ]
                    },
                    {
                        filedLabel: '印章名称',
                        filedName: 'seal_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '投标保证金台账状态',
                        filedName: 'book_status',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '投标申请编号',
                        filedName: 'bidding_apply_number',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目地址',
                        filedName: 'pro_address',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '建设单位(招标人)',
                        filedName: 'con_unit',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '招标代理',
                        filedName: 'bidding_agency',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '开标时间',
                        filedName: 'opening_time',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '保证金到账截止时间',
                        filedName: 'payment_date',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '缴纳方式',
                        filedName: 'payment_method',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: []
                    },
                    {
                        filedLabel: '保证金金额(元)',
                        filedName: 'margin_amount',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '保函手续费(元)',
                        filedName: 'commission',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow:true
                    },
                    {
                        filedLabel: '资质要求',
                        filedName: 'qua_require',
                        filedType: '文本域',
                        placeholder: '请输入备注',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '账户名',
                        filedName: 'account_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '账户开户行',
                        filedName: 'bank_deposit',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '账号',
                        filedName: 'bank_account',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '备注',
                        filedName: 'remarks',
                        filedType: '文本域',
                        placeholder: '请输入备注',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                ],
                ListInfo: []
            },
        }
    }

    componentDidMount() {
        this.get_MarginAuxiliary()
    }

    get_MarginAuxiliary = () => {
        let { Info } = this.state
        getMarginAuxiliary().then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '缴纳方式') {
                            for (let key in res.data.payment_method) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.payment_method[key]
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        }
                    })

                    this.setState(preState => ({
                        Info
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_biddingMarginDetail(tableId)
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_biddingMarginDetail = (tableId) => {
        const { Info } = this.state;
        let data = {
            table_id: tableId
        }
        biddingMarginDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 2 || res.data.Detail.status === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '附件') {
                                if (!res.data.Detail.enclosure || !res.data.Detail.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.apply_username
                    Info.apply.deName = res.data.Detail.applyUserDeName
                    Info.apply.apply_date = res.data.Detail.apply_day
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    if (res.data.Detail.is_seal === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '印章名称') {
                                item.unShow = false
                                item.value = res.data.Detail.sealNames
                            }
                        })
                    }

                    if (res.data.Detail.payment_method === 3) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '保函手续费(元)') {
                                item.unShow = false
                                item.value = res.data.Detail.commission
                            }
                        })
                    }


                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '申请编号') {
                            item.value = res.data.Detail.apply_number
                        } else if (item.filedLabel === '是否盖章') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.is_seal]
                        } else if (item.filedLabel === '投标保证金台账状态') {
                            item.value = res.data.Detail.book_status
                        } else if (item.filedLabel === '投标申请编号') {
                            item.value = res.data.Detail.bidding_apply_number
                        } else if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.bidds.pro_name
                        } else if (item.filedLabel === '项目地址') {
                            item.value = res.data.Detail.bidds.pro_address
                        } else if (item.filedLabel === '建设单位(招标人)') {
                            item.value = res.data.Detail.bidds.con_unit
                        } else if (item.filedLabel === '招标代理') {
                            item.value = res.data.Detail.bidds.bidding_agency
                        } else if (item.filedLabel === '开标时间') {
                            item.value = res.data.Detail.bidds.opening_time ? new Date(res.data.Detail.bidds.opening_time) : ''
                        } else if (item.filedLabel === '保证金到账截止时间') {
                            item.value = res.data.Detail.payment_date ? new Date(res.data.Detail.payment_date) : ''
                        } else if (item.filedLabel === '缴纳方式') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.payment_method]
                        } else if (item.filedLabel === '保证金金额(元)') {
                            item.value = res.data.Detail.margin_amount
                        } else if (item.filedLabel === '资质要求') {
                            item.value = res.data.Detail.bidds.qua_require
                        } else if (item.filedLabel === '账户名') {
                            item.value = res.data.Detail.account_name
                        } else if (item.filedLabel === '账户开户行') {
                            item.value = res.data.Detail.bank_deposit
                        } else if (item.filedLabel === '账号') {
                            item.value = res.data.Detail.bank_account
                        } else if (item.filedLabel === '备注') {
                            item.value = res.data.Detail.remarks
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.Detail.enclosure
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='投标保证金支付申请' />

                <AddConfig key={FormKey} Info={Info} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
