import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { responsibilityReviewDetail } from "../../../utils/project"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_24.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '项目责任书评审',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目编号',
                        filedName: 'pro_number',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '是否内部',
                        filedName: 'is_inside',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                label: '是',
                                value: 1,
                            }, {
                                label: '否',
                                value: 2,
                            }
                        ]
                    },
                    {
                        filedLabel: '评审表编号',
                        filedName: 'number',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '文件编号',
                        filedName: 'file_code',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '工程地点',
                        filedName: 'pro_address',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '担保形式',
                        filedName: 'security',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目利润(%)',
                        filedName: 'profit',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '风险金(%)',
                        filedName: 'risk_fund',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '是否盖章',
                        filedName: 'is_seal',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                label: '是',
                                value: 1,
                            }, {
                                label: '否',
                                value: 2,
                            }
                        ]
                    },
                    {
                        filedLabel: '印章名称',
                        filedName: 'seal_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '责任人',
                        filedName: 'res_user',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '身份证号码',
                        filedName: 'card',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '联系方式',
                        filedName: 'phone',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '居住地址',
                        filedName: 'address',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '邮箱',
                        filedName: 'email',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '是否购买社保',
                        filedName: 'is_social',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                label: '是',
                                value: 1
                            },
                            {
                                label: '否',
                                value: 2
                            }
                        ]
                    }, {
                        filedLabel: '购买时间',
                        filedName: 'buy_time',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '紧急联系人',
                        filedName: 'contact',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '其他说明',
                        filedName: 'other_info',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '备注',
                        filedName: 'info',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                ]
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_responsibilityReviewDetail(tableId)
        }
    }

    get_responsibilityReviewDetail = (tableId) => {
        const { Info } = this.state;
        let data = {
            table_id: tableId
        }
        responsibilityReviewDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 2 || res.data.Detail.status === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '附件') {
                                if (!res.data.Detail.enclosure || !res.data.Detail.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUserName
                    Info.apply.deName = res.data.Detail.applyUserDeName
                    Info.apply.apply_date = res.data.Detail.created_at
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    if (res.data.Detail.is_seal === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '印章名称') {
                                item.unShow = false
                                item.value = res.data.Detail.sealNames
                            }
                        })
                    }

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.proName
                        } else if (item.filedLabel === '项目编号') {
                            item.value = res.data.Detail.proNumber
                        } else if (item.filedLabel === '是否内部') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.is_inside]
                        } else if (item.filedLabel === '评审表编号') {
                            item.value = res.data.Detail.res_code
                        } else if (item.filedLabel === '文件编号') {
                            item.value = res.data.Detail.file_code
                        } else if (item.filedLabel === '工程地点') {
                            item.value = res.data.Detail.pro_address
                        } else if (item.filedLabel === '担保形式') {
                            item.value = res.data.Detail.securityName
                        } else if (item.filedLabel === '项目利润(%)') {
                            item.value = res.data.Detail.profit
                        } else if (item.filedLabel === '风险金(%)') {
                            item.value = res.data.Detail.risk_fund
                        } else if (item.filedLabel === '是否盖章') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.is_seal]
                        } else if (item.filedLabel === '责任人') {
                            item.value = res.data.Detail.res_user
                        } else if (item.filedLabel === '身份证号码') {
                            item.value = res.data.Detail.card
                        } else if (item.filedLabel === '联系方式') {
                            item.value = res.data.Detail.phone
                        } else if (item.filedLabel === '居住地址') {
                            item.value = res.data.Detail.address
                        } else if (item.filedLabel === '邮箱') {
                            item.value = res.data.Detail.email
                        } else if (item.filedLabel === '是否购买社保') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.is_social]
                        } else if (item.filedLabel === '购买时间') {
                            item.value = res.data.Detail.buy_time ? new Date(res.data.Detail.buy_time) : ''
                        } else if (item.filedLabel === '紧急联系人') {
                            item.value = res.data.Detail.contact
                        } else if (item.filedLabel === '其他说明') {
                            item.value = res.data.Detail.other_info
                        } else if (item.filedLabel === '备注') {
                            item.value = res.data.Detail.info
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.Detail.enclosure || []
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='项目责任书评审' />

                <AddConfig key={FormKey} Info={Info} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
