import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import dayjs from 'dayjs'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { createUserLeave, lookLeaveDetail } from "../../../utils/oa"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_06.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                apply: {
                    title: '请假申请',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '请假类型',
                        filedName: 'class',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: false,
                        required: true,
                        message: '请选择请假类型',
                        value: [],
                        radioList: [
                            {
                                label: '病假',
                                value: 1,
                            }, {
                                label: '事假',
                                value: 2,
                            }, {
                                label: '丧假',
                                value: 3,
                            }, {
                                label: '婚假',
                                value: 4,
                            }, {
                                label: '产假',
                                value: 5,
                            }, {
                                label: '产检假',
                                value: 6,
                            }, {
                                label: '陪产假',
                                value: 7,
                            }, {
                                label: '哺乳假',
                                value: 8,
                            }
                        ]
                    },
                    {
                        filedLabel: '请假开始时间',
                        filedName: 'start',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'minute',
                        placeholder: '',
                        disabled: false,
                        required: true,
                        message: '请选择开始日期',
                        value: '',
                    },
                    {
                        filedLabel: '请假结束时间',
                        filedName: 'end',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'minute',
                        placeholder: '',
                        disabled: false,
                        required: true,
                        message: '请选择结束日期',
                        value: '',
                    },
                    {
                        filedLabel: '请假天数',
                        filedName: 'leave_day',
                        filedType: '文本',
                        nodeType: 'number',
                        placeholder: '请输入请假天数',
                        disabled: false,
                        required: true,
                        message: '请输入请假天数',
                        value: '',
                    },
                    {
                        filedLabel: '请假原因',
                        filedName: 'matter',
                        filedType: '文本域',
                        placeholder: '请输入请假原因',
                        disabled: false,
                        required: true,
                        message: '请输入请假原因',
                        value: '',
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: [],
                    },
                ]
            },
            Form: {},
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_lookLeaveDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let { Info } = this.state;
        let userInfo = storage.getItem('userInfo');

        Info.apply.userName = userInfo.name
        Info.apply.deName = userInfo.deName
        Info.apply.apply_date = ''

        this.setState(preState => ({
            Info
        })
        )
    }

    get_lookLeaveDetail = (tableId) => {
        const { Info, Form } = this.state;
        let data = {
            table_id: tableId
        }
        lookLeaveDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        Form.table_id = tableId
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.filedLabel === '附件') {
                                if (!res.data.Detail.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUserName
                    Info.apply.deName = res.data.Detail.deName
                    Info.apply.apply_date = res.data.Detail.apply_date
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '请假类型') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.class]
                        } else if (item.filedLabel === '请假开始时间') {
                            item.value = new Date(res.data.Detail.start)
                        } else if (item.filedLabel === '请假结束时间') {
                            item.value = new Date(res.data.Detail.end)
                        } else if (item.filedLabel === '请假天数') {
                            item.value = res.data.Detail.leave_day
                        } else if (item.filedLabel === '请假原因') {
                            item.value = res.data.Detail.matter
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.Detail.enclosure
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        Form,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = (title) => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.filedLabel === title) {
                item.visible = true
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleFile = (fileList, fileName) => {
        let { Form, Info } = this.state
        let storeList = []
        if (fileList.length) {
            fileList.forEach((item, index) => {
                storeList.push(item.table_id)
            })
        }
        Form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Form,
            Info
        })
        )
    }

    handleDate = (title) => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.filedLabel === title) {
                item.visible = false
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handlePresent = (data) => {
        const { Info, Form } = this.state
        let date = new Date()
        Form.status = 2
        Form.apply_date = dayjs(date).format('YYYY-MM-DD')
        Form.class = data.class[0]
        Form.start = dayjs(data.start).format('YYYY-MM-DD HH:mm:ss')
        Form.end = dayjs(data.end).format('YYYY-MM-DD HH:mm:ss')
        Form.leave_day = data.leave_day
        Form.matter = data.matter

        createUserLeave(Form).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '提交成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '提交失败,请检查表单!',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='新增请假申请' />

                <AddConfig key={FormKey} Info={Info} visibleEvent={this.handleButton} fileEvent={this.handleFile} dateEvent={this.handleDate} presentEvent={this.handlePresent} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
