import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import dayjs from 'dayjs'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { createComResearch, comResearchDetail } from "../../../utils/oa"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_03.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '综合/科研费用付款审批',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '合同类别',
                        filedName: 'con_type',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入合同类别',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '合同名称',
                        filedName: 'con_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入合同名称',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '合同编号',
                        filedName: 'con_code',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入合同编号',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '合同金额(元)',
                        filedName: 'con_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入合同金额',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '已累计付款(元)',
                        filedName: 'paid_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入已累计付款',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '剩余未付款(元)',
                        filedName: 'not_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入剩余未付款',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '付款单位',
                        filedName: 'pay_unit',
                        filedType: '单选',
                        placeholder: '请选择',
                        disabled: false,
                        required: true,
                        message: '请选择费用类型',
                        value: [],
                        radioList: [
                            { value: 1, label: "四川天艺生态园林集团股份有限公司" },
                            { value: 2, label: "四川天艺优境环境科技有限公司" },
                            { value: 3, label: "四川天艺致远物联网科技有限公司" },
                            { value: 4, label: '四川天艺传奇生态茶业有限公司' }
                        ]
                    },
                    {
                        filedLabel: '备注',
                        filedName: 'info',
                        filedType: '文本域',
                        placeholder: '请输入备注',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: [],
                    },
                    {
                        filedLabel: '是否欠票',
                        filedName: 'has_owing',
                        filedType: '单选',
                        placeholder: '请选择',
                        disabled: false,
                        required: true,
                        message: '请选择',
                        value: [],
                        radioList: [
                            {
                                value: 1,
                                label: '是'
                            },
                            {
                                value: 2,
                                label: '否'
                            },
                        ]
                    },
                    {
                        filedLabel: '欠票单位',
                        filedName: 'unit_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入欠票单位',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '欠票类型',
                        filedName: 'invo_type',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入欠票类型',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '欠票金额',
                        filedName: 'money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入欠票金额',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '欠票原因',
                        filedName: 'cause',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入欠票原因',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '补票时间',
                        filedName: 'replenish_invo',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '请选择出补票时间',
                        value: '',
                    },
                    {
                        filedLabel: '费用类型',
                        filedName: 'class',
                        filedType: '单选',
                        placeholder: '请选择',
                        disabled: false,
                        required: true,
                        message: '请选择费用类型',
                        value: [],
                        radioList: [
                            {
                                value: 1,
                                label: '综合费用'
                            },
                            {
                                value: 2,
                                label: '科研费用'
                            },
                        ]
                    },
                ],
                ListInfo: [
                    {
                        plateName: '费用明细',
                        unWind: false,
                        dataSource: []
                    },
                ]
            },
            Form: {},
            pro_name: '',
            expense_01: [
                { value: 1, label: '办公用品' },
                { value: 2, label: '其他费用' }
            ],
            expense_02: [
                { value: 1, label: '委托研发费用' },
                { value: 2, label: '直接投入费用' }
            ],
            popup: {
                isShow: false,
                title: '选择项目',
                placeholder: '输入项目名称查询',
                multiple: false,
                disabled: false,
                dataSource: []
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_comResearchDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let { Info, Form } = this.state;
        let userInfo = storage.getItem('userInfo');

        Info.apply.userName = userInfo.name
        Info.apply.deName = userInfo.deName
        Info.apply.apply_date = ''

        this.setState(preState => ({
            Info,
            Form
        })
        )
    }

    get_comResearchDetail = (tableId) => {
        const { Info, Form, expense_01, expense_02 } = this.state;
        let data = {
            table_id: tableId
        }
        comResearchDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        Form.table_id = tableId
                        Form.class = res.data.Detail.class
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (!res.data.Detail.enclosure.length) {
                                if (item.filedLabel === '附件') {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUserName
                    Info.apply.deName = res.data.Detail.deName
                    Info.apply.apply_date = res.data.Detail.apply_date
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.ListInfo[0].dataSource = []
                    res.data.Detail.details = JSON.parse(res.data.Detail.details)
                    res.data.Detail.details.forEach((item, index) => {
                        let key = index + 1
                        let dataSource = {
                            key: key,
                            list: [
                                {
                                    filedLabel: '费用类别',
                                    filedName: 'money_class_' + key,
                                    filedType: '单选',
                                    placeholder: '请选择',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: [item.money_class],
                                    radioList: Form.class === 1 ? expense_01 : expense_02
                                },
                                {
                                    filedLabel: '本次付款金额(元)',
                                    filedName: 'pay_money_' + key,
                                    filedType: '文本',
                                    placeholder: '请输入本次付款金额',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.pay_money,
                                },
                                {
                                    filedLabel: '公司名称',
                                    filedName: 'company_name_' + key,
                                    filedType: '文本',
                                    placeholder: '请输入公司名称',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.company_name,
                                },
                                {
                                    filedLabel: '开户行',
                                    filedName: 'bank_' + key,
                                    filedType: '文本',
                                    placeholder: '请输入开户行',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.bank,
                                },
                                {
                                    filedLabel: '银行账号',
                                    filedName: 'bank_number_' + key,
                                    filedType: '文本',
                                    placeholder: '请输入银行账号',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.bank_number,
                                },
                            ]
                        }
                        Info.ListInfo[0].dataSource = [...Info.ListInfo[0].dataSource, dataSource]
                    })


                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '合同类别') {
                            item.value = res.data.Detail.con_type
                        } else if (item.filedLabel === '合同名称') {
                            item.value = res.data.Detail.con_name
                        } else if (item.filedLabel === '合同编号') {
                            item.value = res.data.Detail.con_code
                        } else if (item.filedLabel === '合同金额(元)') {
                            item.value = res.data.Detail.con_money
                        } else if (item.filedLabel === '已累计付款(元)') {
                            item.value = res.data.Detail.paid_money
                        } else if (item.filedLabel === '剩余未付款(元)') {
                            item.value = res.data.Detail.not_money
                        } else if (item.filedLabel === '备注') {
                            item.value = res.data.Detail.info
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.Detail.enclosure
                        } else if (item.filedLabel === '是否欠票') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.has_owing]
                        } else if (item.filedLabel === '欠票单位') {
                            item.value = res.data.Detail.unit_name || '/'
                        } else if (item.filedLabel === '欠票类型') {
                            item.value = res.data.Detail.invo_type || '/'
                        } else if (item.filedLabel === '欠票金额') {
                            item.value = res.data.Detail.money || '/'
                        } else if (item.filedLabel === '欠票原因') {
                            item.value = res.data.Detail.cause || '/'
                        } else if (item.filedLabel === '补票时间') {
                            item.value = res.data.Detail.replenish_invo ? new Date(res.data.Detail.replenish_invo) : ''
                        } else if (item.filedLabel === '费用类型') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.class]
                        }else if (item.filedLabel === '付款单位') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.pay_unit]
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        Form,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleVisible = (title, name) => {
        let { Info } = this.state
        if (title === '补票时间' || title === '是否欠票' || title === '费用类型' || title === '付款单位') {
            Info.TableInfo.forEach((item, index) => {
                if (item.filedLabel === title) {
                    item.visible = true
                }
            })
        } else if (title === '费用类别') {
            Info.ListInfo[0].dataSource.forEach((item, index) => {
                item.list.forEach((em, ex) => {
                    if (em.filedName === name) {
                        em.visible = true
                    }
                })
            })
        }
        this.setState(preState => ({
            Info
        })
        )
    }

    handleDate = (title, name) => {
        let { Info } = this.state
        if (title === '补票时间' || title === '是否欠票' || title === '费用类型' || title === '付款单位') {
            Info.TableInfo.forEach((item, index) => {
                if (item.filedLabel === title) {
                    item.visible = false
                }
            })
        } else if (title === '费用类别') {
            Info.ListInfo[0].dataSource.forEach((item, index) => {
                item.list.forEach((em, ex) => {
                    if (em.filedName === name) {
                        em.visible = false
                    }
                })
            })
        }
        this.setState(preState => ({
            Info
        })
        )
    }

    handleFile = (fileList, fileName) => {
        let { Form, Info } = this.state
        let storeList = []
        if (fileList.length) {
            fileList.forEach((item, index) => {
                storeList.push(item.table_id)
            })
        }
        Form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Form,
            Info
        })
        )
    }

    handleWind = (name) => {
        let { Info } = this.state
        Info.ListInfo.forEach((item, index) => {
            if (item.plateName === name) {
                item.unWind = !item.unWind
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleRadio = (title, value) => {
        let { Form, Info, expense_01, expense_02 } = this.state
        let dataSource = {
            key: 1,
            list: [
                {
                    filedLabel: '费用类别',
                    filedName: 'money_class_1',
                    filedType: '单选',
                    placeholder: '请选择',
                    disabled: false,
                    required: false,
                    message: '',
                    value: [],
                    radioList: []
                },
                {
                    filedLabel: '本次付款金额(元)',
                    filedName: 'pay_money_1',
                    filedType: '文本',
                    placeholder: '请输入本次付款金额',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '公司名称',
                    filedName: 'company_name_1',
                    filedType: '文本',
                    placeholder: '请输入公司名称',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '开户行',
                    filedName: 'bank_1',
                    filedType: '文本',
                    placeholder: '请输入开户行',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '银行账号',
                    filedName: 'bank_number_1',
                    filedType: '文本',
                    placeholder: '请输入银行账号',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
            ]
        }
        Info.ListInfo[0].dataSource = []
        if (value[0] === 1) {
            dataSource.list[0].radioList = expense_01
        } else if (value[0] === 2) {
            dataSource.list[0].radioList = expense_02
        }
        Info.ListInfo[0].dataSource = [...Info.ListInfo[0].dataSource, dataSource]
        Form.class = value[0]
        this.setState(preState => ({
            Info,
            Form
        })
        )
    }

    handleNew = (name) => {
        let { Info, Form, expense_01, expense_02 } = this.state
        if (!Form.class) {
            Toast.show({
                icon: 'fail',
                content: '请选择费用类型!',
            })
            return
        }
        if (Info.ListInfo[0].dataSource.length > 4) {        
            return
        }
        let key = Info.ListInfo[0].dataSource.length + 1
        let value = {
            key: key,
            list: [
                {
                    filedLabel: '费用类别',
                    filedName: 'money_class_' + key,
                    filedType: '单选',
                    placeholder: '请选择',
                    disabled: false,
                    required: false,
                    message: '',
                    value: [],
                    radioList: Form.class === 1 ? expense_01 : expense_02
                },
                {
                    filedLabel: '本次付款金额(元)',
                    filedName: 'pay_money_' + key,
                    filedType: '文本',
                    placeholder: '请输入本次付款金额',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '公司名称',
                    filedName: 'company_name_' + key,
                    filedType: '文本',
                    placeholder: '请输入公司名称',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '开户行',
                    filedName: 'bank_' + key,
                    filedType: '文本',
                    placeholder: '请输入开户行',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '银行账号',
                    filedName: 'bank_number_' + key,
                    filedType: '文本',
                    placeholder: '请输入银行账号',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
            ]
        }
        Info.ListInfo[0].dataSource = [...Info.ListInfo[0].dataSource, value]
        this.setState(preState => ({
            Info
        })
        )
    }

    handlePresent = (data) => {
        const { Form, Info } = this.state
        let date = new Date()
        Form.status = 2
        Form.apply_date = dayjs(date).format('YYYY-MM-DD')
        Form.con_type = data.con_type
        Form.con_name = data.con_name
        Form.con_code = data.con_code
        Form.con_money = data.con_money
        Form.paid_money = data.paid_money
        Form.not_money = data.not_money
        Form.class = data.class[0]
        Form.pay_unit=data.pay_unit[0]
        Form.info = data.info
        Form.has_owing = data.has_owing[0]
        Form.unit_name = data.unit_name
        Form.invo_type = data.invo_type
        Form.money = data.money
        Form.cause = data.cause
        Form.replenish_invo = data.replenish_invo ? dayjs(data.replenish_invo).format('YYYY-MM-DD') : ''

        Form.details = []
        Info.ListInfo[0].dataSource.forEach((item, index) => {
            let value = {
                key: item.key,
                money_class: '',
                pay_money: '',
                company_name: '',
                bank: '',
                bank_number: '',
            }
            item.list.forEach((em, ex) => {
                value.money_class = data['money_class_' + item.key].length ? data['money_class_' + item.key][0] : ''
                value.pay_money = data['pay_money_' + item.key]
                value.company_name = data['company_name_' + item.key]
                value.bank = data['bank_' + item.key]
                value.bank_number = data['bank_number_' + item.key]
            })
            Form.details = [...Form.details, value]
        })

        Form.details = JSON.stringify(Form.details)

        createComResearch(Form).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '提交成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '提交失败,请检查表单!',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='新增综合/科研费用' />

                <AddConfig key={FormKey} Info={Info} visibleEvent={this.handleVisible} fileEvent={this.handleFile} dateEvent={this.handleDate} windEvent={this.handleWind} newEvent={this.handleNew} radioEvent={this.handleRadio} presentEvent={this.handlePresent} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
