import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import dayjs from 'dayjs'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { selectUserName } from "../../../utils/api"
import { BlankingSelectProject, createConstructionLog, ConstructionLogDetail } from "../../../utils/project"
import UserPopup from "../../../components/UserPopup"
import Icon from "../../../accets/img/icon_20.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '施工日志',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '上午天气',
                        filedName: 'am',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                label: '阴天',
                                value: 1,
                            }, {
                                label: '晴天',
                                value: 2,
                            }, {
                                label: '雨天',
                                value: 3,
                            },
                        ]
                    },
                    {
                        filedLabel: '下午天气',
                        filedName: 'pm',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                label: '阴天',
                                value: 1,
                            }, {
                                label: '晴天',
                                value: 2,
                            }, {
                                label: '雨天',
                                value: 3,
                            },
                        ]
                    },
                    {
                        filedLabel: '最高气温',
                        filedName: 'high',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入最高气温',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '最低气温',
                        filedName: 'low',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入最低气温',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '接收人',
                        filedName: 'read_user',
                        filedType: '抄送',
                        disabled: false,
                        required: true,
                        message: '',
                        value: [],
                    },
                ],
                ListInfo: [
                    {
                        plateName: '材料日志',
                        unWind: false,
                        dataSource: [
                            {
                                key: 1,
                                list: [
                                    {
                                        filedLabel: '收货品类',
                                        filedName: 'material_class_1',
                                        filedType: '文本',
                                        placeholder: '请输入收货品类',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '规格型号',
                                        filedName: 'material_model_1',
                                        filedType: '文本',
                                        placeholder: '请输入规格型号',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '计量单位',
                                        filedName: 'material_unit_1',
                                        filedType: '文本',
                                        placeholder: '请输入计量单位',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '实收数量',
                                        filedName: 'material_number_1',
                                        filedType: '文本',
                                        placeholder: '请输入实收数量',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '备注',
                                        filedName: 'material_info_1',
                                        filedType: '文本',
                                        placeholder: '请输入备注',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        plateName: '劳务日志',
                        unWind: false,
                        dataSource: [
                            {
                                key: 1,
                                list: [
                                    {
                                        filedLabel: '施工班组',
                                        filedName: 'work_team_1',
                                        filedType: '文本',
                                        placeholder: '请输入施工班组',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '工作人数',
                                        filedName: 'work_number_1',
                                        filedType: '文本',
                                        placeholder: '请输入工作人数',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '施工内容',
                                        filedName: 'work_content_1',
                                        filedType: '单选',
                                        visible: false,
                                        placeholder: '',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: [],
                                        radioList: [
                                            {
                                                label: '土建',
                                                value: 1,
                                            }, {
                                                label: '主体',
                                                value: 2,
                                            },
                                        ]
                                    },
                                    {
                                        filedLabel: '备注',
                                        filedName: 'work_info_1',
                                        filedType: '文本',
                                        placeholder: '请输入备注',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        plateName: '机械日志',
                        unWind: false,
                        dataSource: [
                            {
                                key: 1,
                                list: [
                                    {
                                        filedLabel: '机械类型',
                                        filedName: 'machinery_type_1',
                                        filedType: '文本',
                                        placeholder: '请输入机械类型',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '台班用时',
                                        filedName: 'machinery_time_1',
                                        filedType: '文本',
                                        placeholder: '请输入台班用时',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '工作内容',
                                        filedName: 'machinery_content_1',
                                        filedType: '文本',
                                        placeholder: '请输入工作内容',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '备注',
                                        filedName: 'machinery_info_1',
                                        filedType: '文本',
                                        placeholder: '请输入备注',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        plateName: '',
                        list: [
                            {
                                filedLabel: '当日施工进展',
                                filedName: 'progress',
                                filedType: '文本域',
                                placeholder: '当日施工进展',
                                disabled: false,
                                required: false,
                                message: '',
                                value: '',
                            },
                            {
                                filedLabel: '需要协调问题',
                                filedName: 'problem',
                                filedType: '文本域',
                                placeholder: '请输入需要协调问题',
                                disabled: false,
                                required: false,
                                message: '',
                                value: '',
                            },
                            {
                                filedLabel: '现场图片',
                                filedName: 'enclosure',
                                filedType: '附件',
                                placeholder: '',
                                disabled: false,
                                required: false,
                                message: '',
                                value: [],
                            },
                        ],
                    }
                ]
            },
            Form: {},
            userName: '',
            pro_name: '',
            popupTitle: '',
            popup: {
                isShow: false,
                title: '',
                placeholder: '',
                multiple: false,
                disabled: false,
                dataSource: []
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_ConstructionLogDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let { Info } = this.state;
        let userInfo = storage.getItem('userInfo');

        Info.apply.userName = userInfo.name
        Info.apply.deName = userInfo.deName
        Info.apply.apply_date = ''

        this.setState(preState => ({
            Info
        })
        )
    }

    get_ConstructionLogDetail = (tableId) => {
        const { Info, Form } = this.state;
        let data = {
            table_id: tableId
        }
        ConstructionLogDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1) {
                        Form.table_id = tableId

                        if (res.data.Detail.readUserName) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.filedLabel === '接收人') {
                                    item.value = []
                                    res.data.Detail.readUser.forEach((em, ex) => {
                                        let value = {
                                            label: em.userName,
                                            value: em.table_id
                                        }
                                        item.value = [...item.value, value]
                                    })
                                }
                            })
                        }
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.filedLabel === '接收人') {
                                item.unShow = true
                            }
                        })

                        Info.ListInfo.forEach((item, index) => {
                            if (item.plateName) {
                                item.dataSource.forEach((em, ex) => {
                                    em.list.forEach((m, x) => {
                                        m.disabled = true
                                    })
                                })
                            }
                        })

                        Info.ListInfo[3].list.forEach((item, index) => {
                            item.disabled = true

                            if (item.filedLabel === '现场图片') {
                                if (!res.data.Detail.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status === 2 ? 4 : res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUsername
                    Info.apply.deName = res.data.Detail.deName
                    Info.apply.apply_date = res.data.Detail.created_at
                    if (res.data.Detail.reviewAudit) {
                        res.data.Detail.reviewAudit.ccReading.forEach((item, index) => {
                            item.username = item.userName
                        })
                    }
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.proName
                        } else if (item.filedLabel === '上午天气') {
                            item.value = []
                            item.value = [...item.value, Number(res.data.Detail.am)]
                        } else if (item.filedLabel === '下午天气') {
                            item.value = []
                            item.value = [...item.value, Number(res.data.Detail.pm)]
                        } else if (item.filedLabel === '最高气温') {
                            item.value = res.data.Detail.high
                        } else if (item.filedLabel === '最低气温') {
                            item.value = res.data.Detail.low
                        }
                    })

                    if (res.data.Detail.mac_log) {
                        let dataSource = JSON.parse(res.data.Detail.mac_log)
                        Info.ListInfo[0].dataSource = []
                        dataSource.forEach((item, index) => {
                            let key = index + 1
                            let value = {
                                key: key,
                                list: [
                                    {
                                        filedLabel: '收货品类',
                                        filedName: 'material_class_' + key,
                                        filedType: '文本',
                                        placeholder: '请输入收货品类',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.class,
                                    },
                                    {
                                        filedLabel: '规格型号',
                                        filedName: 'material_model_' + key,
                                        filedType: '文本',
                                        placeholder: '请输入规格型号',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.model,
                                    },
                                    {
                                        filedLabel: '计量单位',
                                        filedName: 'material_unit_' + key,
                                        filedType: '文本',
                                        placeholder: '请输入计量单位',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.unit,
                                    },
                                    {
                                        filedLabel: '实收数量',
                                        filedName: 'material_number_' + key,
                                        filedType: '文本',
                                        placeholder: '请输入实收数量',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.number,
                                    },
                                    {
                                        filedLabel: '备注',
                                        filedName: 'material_info_' + key,
                                        filedType: '文本',
                                        placeholder: '请输入备注',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.info,
                                    },
                                ]
                            }
                            Info.ListInfo[0].dataSource = [...Info.ListInfo[0].dataSource, value]
                        })
                    }

                    if (res.data.Detail.art_log) {
                        let dataSource = JSON.parse(res.data.Detail.art_log)
                        Info.ListInfo[1].dataSource = []
                        dataSource.forEach((item, index) => {
                            let key = index + 1
                            let value = {
                                key: key,
                                list: [
                                    {
                                        filedLabel: '施工班组',
                                        filedName: 'work_team_' + key,
                                        filedType: '文本',
                                        placeholder: '请输入施工班组',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.team,
                                    },
                                    {
                                        filedLabel: '工作人数',
                                        filedName: 'work_number_' + key,
                                        filedType: '文本',
                                        placeholder: '请输入工作人数',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.number,
                                    },
                                    {
                                        filedLabel: '施工内容',
                                        filedName: 'work_content_' + key,
                                        filedType: '单选',
                                        visible: false,
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: [],
                                        radioList: [
                                            {
                                                label: '土建',
                                                value: 1,
                                            }, {
                                                label: '主体',
                                                value: 2,
                                            },
                                        ]
                                    },
                                    {
                                        filedLabel: '备注',
                                        filedName: 'work_info_' + key,
                                        filedType: '文本',
                                        placeholder: '请输入备注',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.info,
                                    },
                                ]
                            }
                            if (item.content) {
                                value.list[2].value.push(Number(item.content))
                            }
                            Info.ListInfo[1].dataSource = [...Info.ListInfo[1].dataSource, value]
                        })
                    }

                    if (res.data.Detail.mec_log) {
                        let dataSource = JSON.parse(res.data.Detail.mec_log)
                        Info.ListInfo[2].dataSource = []
                        dataSource.forEach((item, index) => {
                            let key = index + 1
                            let value = {
                                key: key,
                                list: [
                                    {
                                        filedLabel: '机械类型',
                                        filedName: 'machinery_type_' + key,
                                        filedType: '文本',
                                        placeholder: '请输入机械类型',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.type,
                                    },
                                    {
                                        filedLabel: '台班用时',
                                        filedName: 'machinery_time_' + key,
                                        filedType: '文本',
                                        placeholder: '请输入台班用时',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.time,
                                    },
                                    {
                                        filedLabel: '工作内容',
                                        filedName: 'machinery_content_' + key,
                                        filedType: '文本',
                                        placeholder: '请输入工作内容',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.content,
                                    },
                                    {
                                        filedLabel: '备注',
                                        filedName: 'machinery_info_' + key,
                                        filedType: '文本',
                                        placeholder: '请输入备注',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.info,
                                    },
                                ]
                            }
                            Info.ListInfo[2].dataSource = [...Info.ListInfo[2].dataSource, value]
                        })
                    }

                    Info.ListInfo[3].list.forEach((item, index) => {
                        if (item.filedLabel === '当日施工进展') {
                            item.value = res.data.Detail.progress
                        } else if (item.filedLabel === '需要协调问题') {
                            item.value = res.data.Detail.problem
                        } else if (item.filedLabel === '现场图片') {
                            item.value = res.data.Detail.enclosure
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        Form,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_InitialValue = () => {
        let { popup, userName } = this.state
        let data = {
            username: userName,
            page: 1,
            limit: 500
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    popup.dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                value: item.table_id,
                                label: item.username,
                            }
                            popup.dataSource = [...popup.dataSource, user]
                        })
                        popup.title = '选择人员'
                        popup.placeholder = '输入姓名查询人员'
                        popup.isShow = true
                    }
                    this.setState(preState => ({
                        popup,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_BlankingSelectProject = () => {
        let { popup, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: 1,
            limit: 500
        }
        BlankingSelectProject(data).then(
            (res) => {
                if (!res.code) {
                    popup.dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let pro = {
                                value: item.table_id,
                                label: item.pro_name,
                            }
                            popup.dataSource = [...popup.dataSource, pro]
                        })

                        popup.title = '选择项目'
                        popup.placeholder = '输入项目名称查询'
                        popup.isShow = true
                    }
                    this.setState(preState => ({
                        popup,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = (title) => {
        if (title === '项目名称') {
            this.get_BlankingSelectProject()
        } else if (title === '接收人') {
            this.get_InitialValue()
        }
        this.setState(preState => ({
            popupTitle: title,
        }))
    }

    handleVisible = (title, name) => {
        let { Info } = this.state
        if (title === '上午天气' || title === '下午天气') {
            Info.TableInfo.forEach((item, index) => {
                if (item.filedLabel === title) {
                    item.visible = true
                }
            })
        } else if (title === '施工内容') {
            Info.ListInfo[1].dataSource.forEach((item, index) => {
                item.list.forEach((em, ex) => {
                    if (em.filedName === name) {
                        em.visible = true
                    }
                })
            })
        }
        this.setState(preState => ({
            Info
        })
        )
    }

    handleDate = (title, name) => {
        let { Info } = this.state
        if (title === '上午天气' || title === '下午天气') {
            Info.TableInfo.forEach((item, index) => {
                if (item.filedLabel === title) {
                    item.visible = false
                }
            })
        } else if (title === '施工内容') {
            Info.ListInfo[1].dataSource.forEach((item, index) => {
                item.list.forEach((em, ex) => {
                    if (em.filedName === name) {
                        em.visible = false
                    }
                })
            })
        }
        this.setState(preState => ({
            Info
        })
        )
    }

    handleWind = (name) => {
        let { Info } = this.state
        Info.ListInfo.forEach((item, index) => {
            if (item.plateName === name) {
                item.unWind = !item.unWind
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleNew = (name) => {
        let { Info } = this.state
        if (name === '材料日志') {
            let key = Info.ListInfo[0].dataSource.length + 1
            let value = {
                key: key,
                list: [
                    {
                        filedLabel: '收货品类',
                        filedName: 'material_class_' + key,
                        filedType: '文本',
                        placeholder: '请输入收货品类',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '规格型号',
                        filedName: 'material_model_' + key,
                        filedType: '文本',
                        placeholder: '请输入规格型号',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '计量单位',
                        filedName: 'material_unit_' + key,
                        filedType: '文本',
                        placeholder: '请输入计量单位',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '实收数量',
                        filedName: 'material_number_' + key,
                        filedType: '文本',
                        placeholder: '请输入实收数量',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '备注',
                        filedName: 'material_info_' + key,
                        filedType: '文本',
                        placeholder: '请输入备注',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                ]
            }
            Info.ListInfo[0].dataSource = [...Info.ListInfo[0].dataSource, value]
        } else if (name === '劳务日志') {
            let key = Info.ListInfo[1].dataSource.length + 1
            let value = {
                key: key,
                list: [
                    {
                        filedLabel: '施工班组',
                        filedName: 'work_team_' + key,
                        filedType: '文本',
                        placeholder: '请输入施工班组',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '工作人数',
                        filedName: 'work_number_' + key,
                        filedType: '文本',
                        placeholder: '请输入工作人数',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '施工内容',
                        filedName: 'work_content_' + key,
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                label: '土建',
                                value: 1,
                            }, {
                                label: '主体',
                                value: 2,
                            },
                        ]
                    },
                    {
                        filedLabel: '备注',
                        filedName: 'work_info_' + key,
                        filedType: '文本',
                        placeholder: '请输入备注',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                ]
            }
            Info.ListInfo[1].dataSource = [...Info.ListInfo[1].dataSource, value]
        } else if (name === '机械日志') {
            let key = Info.ListInfo[2].dataSource.length + 1
            let value = {
                key: key,
                list: [
                    {
                        filedLabel: '机械类型',
                        filedName: 'machinery_type_' + key,
                        filedType: '文本',
                        placeholder: '请输入机械类型',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '台班用时',
                        filedName: 'machinery_time_' + key,
                        filedType: '文本',
                        placeholder: '请输入台班用时',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '工作内容',
                        filedName: 'machinery_content_' + key,
                        filedType: '文本',
                        placeholder: '请输入工作内容',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '备注',
                        filedName: 'machinery_info_' + key,
                        filedType: '文本',
                        placeholder: '请输入备注',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                ]
            }
            Info.ListInfo[2].dataSource = [...Info.ListInfo[2].dataSource, value]
        }
        this.setState(preState => ({
            Info
        })
        )
    }

    handleQuery = (value) => {
        let { popupTitle } = this.state
        if (popupTitle === '项目名称') {
            this.setState(preState => ({
                pro_name: value,
            }), () => {
                this.get_BlankingSelectProject()
            })
        } else if (popupTitle === '接收人') {
            this.setState(preState => ({
                userName: value,
            }), () => {
                this.get_InitialValue()
            })
        }
    }

    handleClose = () => {
        this.setState(preState => ({
            userName: '',
            pro_name: '',
            popup: {
                isShow: false,
                title: '',
                placeholder: '',
                multiple: false,
                disabled: false,
                dataSource: []
            }
        }))
    }

    handleSelect = (value) => {
        let { popup, Info, Form, popupTitle } = this.state
        let selectList = []

        if (popupTitle === '项目名称') {
            if (value.length) {
                value.forEach((item, index) => {
                    popup.dataSource.forEach((em, ex) => {
                        if (em.value === item) {
                            Info.TableInfo.forEach((m, x) => {
                                if (m.filedLabel === '项目名称') {
                                    m.value = em.label
                                    Form.project_id = em.value
                                }
                            })
                        }
                    })
                })
            }
        } else if (popupTitle === '接收人') {
            if (value.length) {
                value.forEach((item, index) => {
                    popup.dataSource.forEach((em, ex) => {
                        if (em.value === item) {
                            selectList = [...selectList, em]
                        }
                    })
                })
            }

            Info.TableInfo.forEach((item, index) => {
                if (item.filedLabel === '接收人') {
                    item.value = [...item.value, ...selectList]
                }
            })
        }

        this.setState(preState => ({
            Info,
            Form,
            userName: '',
            pro_name: '',
            popup: {
                isShow: false,
                title: '',
                placeholder: '',
                multiple: false,
                disabled: false,
                dataSource: []
            }
        }))
    }

    handleRemove = (value) => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.filedLabel === '接收人') {
                item.value.forEach((em, ex) => {
                    if (em.value === value) {
                        item.value.splice(ex, 1)
                    }
                })
            }
        })

        this.setState(preState => ({
            Info
        }))
    }

    handleFile = (fileList, fileName) => {
        let { Form, Info } = this.state
        let storeList = []
        if (fileList.length) {
            fileList.forEach((item, index) => {
                storeList.push(item.table_id)
            })
        }
        Form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Form,
            Info
        })
        )
    }

    handlePresent = (data) => {
        const { Form, Info } = this.state
        let date = new Date()
        Form.status = 2
        Form.apply_date = dayjs(date).format('YYYY-MM-DD')
        Form.am = data.am.length ? data.am[0] : ''
        Form.pm = data.pm.length ? data.pm[0] : ''
        Form.high = data.high
        Form.low = data.low
        Form.progress = data.progress
        Form.problem = data.problem
        Info.TableInfo.forEach((item, index) => {
            if (item.filedLabel === '接收人') {
                if (item.value.length) {
                    let read_user = []
                    item.value.forEach((em, ex) => {
                        read_user.push(em.value)
                    })
                    Form.readUser = JSON.stringify(read_user)
                }
            }
        })
        Form.mac_log = []
        Info.ListInfo[0].dataSource.forEach((item, index) => {
            let value = {
                key: item.key,
                class: '',
                model: '',
                unit: '',
                number: '',
                info: ''
            }
            item.list.forEach((em, ex) => {
                value.class = data['material_' + 'class_' + item.key]
                value.model = data['material_' + 'model_' + item.key]
                value.unit = data['material_' + 'unit_' + item.key]
                value.number = data['material_' + 'number_' + item.key]
                value.info = data['material_' + 'info_' + item.key]
            })
            Form.mac_log = [...Form.mac_log, value]
        })
        Form.art_log = []
        Info.ListInfo[1].dataSource.forEach((item, index) => {
            let value = {
                key: item.key,
                team: '',
                number: '',
                content: '',
                info: ''
            }
            item.list.forEach((em, ex) => {
                value.team = data['work_' + 'team_' + item.key]
                value.number = data['work_' + 'number_' + item.key]
                value.content = data['work_' + 'content_' + item.key].length ? data['work_' + 'content_' + item.key][0] : []
                value.info = data['work_' + 'info_' + item.key]
            })
            Form.art_log = [...Form.art_log, value]
        })
        Form.mec_log = []
        Info.ListInfo[2].dataSource.forEach((item, index) => {
            let value = {
                key: item.key,
                type: '',
                time: '',
                content: '',
                info: ''
            }
            item.list.forEach((em, ex) => {
                value.type = data['machinery_' + 'type_' + item.key]
                value.time = data['machinery_' + 'time_' + item.key]
                value.content = data['machinery_' + 'content_' + item.key]
                value.info = data['machinery_' + 'info_' + item.key]
            })
            Form.mec_log = [...Form.mec_log, value]
        })

        Form.mac_log = JSON.stringify(Form.mac_log)
        Form.art_log = JSON.stringify(Form.art_log)
        Form.mec_log = JSON.stringify(Form.mec_log)

        createConstructionLog(Form).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '提交成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        // let select = {
                        //     audit_id: res.audit_id,
                        //     tableName: res.tableName,
                        //     table_id: res.table_id,
                        //     data: res.data,
                        // }

                        // Info.select = select

                        // this.setState({
                        //     Info
                        // })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '提交失败,请检查表单!',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info, popup } = this.state
        return (
            <div>
                <NavBar menuName='新建施工日志' />

                <AddConfig key={FormKey} Info={Info} buttonEvent={this.handleButton} visibleEvent={this.handleVisible} dateEvent={this.handleDate} removeEvent={this.handleRemove} windEvent={this.handleWind} newEvent={this.handleNew} fileEvent={this.handleFile} presentEvent={this.handlePresent} />

                <UserPopup popup={popup} closeEvent={this.handleClose} queryEvent={this.handleQuery} selectEvent={this.handleSelect} />
            </div>
        )
    }
}

export default withRouter(NewItem)
