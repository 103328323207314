import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { DevelopmentApplicationDetail } from "../../../utils/public"
import { reviewApplyResult } from "../../../utils/api"
import { getCompanyDeName } from "../../../utils/oa"
import Icon from "../../../accets/img/icon_48.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '研发项目',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目领域',
                        filedName: 'pro_area',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目编号',
                        filedName: 'pro_code',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目等级',
                        filedName: 'pro_grade',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                value: 1,
                                label: '一般项目(10万(含)以下)'
                            },
                            {
                                value: 2,
                                label: '重点项目(10万以上)'
                            }
                        ]
                    },
                    {
                        filedLabel: '项目类型',
                        filedName: 'pro_type',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                value: 1,
                                label: 'A.理论基础研究'
                            },
                            {
                                value: 2,
                                label: 'B.应用基础研究'
                            },
                            {
                                value: 3,
                                label: 'c.应用研究'
                            }
                        ]
                    },
                    {
                        filedLabel: '类别',
                        filedName: 'class',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                value: 1,
                                label: '合作研发'
                            },
                            {
                                value: 2,
                                label: '自主研发'
                            },
                        ]
                    },
                    {
                        filedLabel: '部门名称',
                        filedName: 'de_name',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: []
                    },
                    {
                        filedLabel: '项目经费(元)',
                        filedName: 'pro_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目负责人',
                        filedName: 'pro_manager',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '合作单位',
                        filedName: 'coo_unit',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '联系电话',
                        filedName: 'phone',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目启动时间',
                        filedName: 'start_time',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '计划结束时间',
                        filedName: 'end_time',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                ],
                ListInfo: [
                    {
                        plateName: '项目组成员信息',
                        unWind: false,
                        dataSource: []
                    },
                    {
                        plateName: '直接费用',
                        unWind: false,
                        dataSource: []
                    },
                    {
                        plateName: '间接费用',
                        unWind: false,
                        dataSource: []
                    },
                    {
                        plateName: '',
                        list: [
                            {
                                filedLabel: '预期成果',
                                filedName: 'expected_results',
                                filedType: '文本域',
                                placeholder: '',
                                disabled: true,
                                required: false,
                                message: '',
                                value: '',
                            },
                            {
                                filedLabel: '结题目标',
                                filedName: 'closing_goal',
                                filedType: '文本域',
                                placeholder: '',
                                disabled: true,
                                required: false,
                                message: '',
                                value: '',
                            },
                            {
                                filedLabel: '项目要点归纳',
                                filedName: 'key_points',
                                filedType: '文本域',
                                placeholder: '',
                                disabled: true,
                                required: false,
                                message: '',
                                value: '',
                            },
                            {
                                filedLabel: '备注',
                                filedName: 'info',
                                filedType: '文本域',
                                placeholder: '',
                                disabled: true,
                                required: false,
                                message: '',
                                value: '',
                            },
                            {
                                filedLabel: '项目申请书',
                                filedName: 'enclosure',
                                filedType: '附件',
                                placeholder: '',
                                disabled: true,
                                required: false,
                                message: '',
                                value: [],
                            },
                        ]
                    },
                ]
            },
        }
    }

    componentDidMount() {
        this.get_CompanyDeName()
    }

    get_CompanyDeName = () => {
        let { Info } = this.state
        getCompanyDeName().then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '部门名称') {
                            item.radioList = []
                            res.data.departments.forEach((em, ex) => {
                                let value = {
                                    value: em.id,
                                    label: em.name
                                }
                                item.radioList = [...item.radioList, value]
                            })
                        }
                    })
                    this.setState(preState => ({
                        Info
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_DevelopmentApplicationDetail(tableId)
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_DevelopmentApplicationDetail = (tableId) => {
        const { Info } = this.state;
        let data = {
            table_id: tableId
        }
        DevelopmentApplicationDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 2 || res.data.Detail.status === 4) {
                        Info.ListInfo[3].list.forEach((item, index) => {
                            if (item.filedLabel === '项目申请书') {
                                if (!res.data.Detail.enclosure || !res.data.Detail.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUserName
                    Info.apply.deName = res.data.Detail.applyUserDeName
                    Info.apply.apply_date = res.data.Detail.created_at
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.ListInfo[0].dataSource = []
                    let information = JSON.parse(res.data.Detail.information)
                    information.forEach((item, index) => {
                        let key = index + 1
                        let dataSource = {
                            key: key,
                            list: [
                                {
                                    filedLabel: '姓名',
                                    filedName: 'name_' + key,
                                    filedType: '文本',
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.name,
                                },
                                {
                                    filedLabel: '单位',
                                    filedName: 'unit_' + key,
                                    filedType: '文本',
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.unit,
                                },
                            ]
                        }
                        Info.ListInfo[0].dataSource = [...Info.ListInfo[0].dataSource, dataSource]
                    })


                    Info.ListInfo[1].dataSource = []
                    let direct = JSON.parse(res.data.Detail.direct)
                    direct.forEach((item, index) => {
                        let key = index + 1
                        let dataSource = {
                            key: key,
                            list: [
                                {
                                    filedLabel: '项目等级',
                                    filedName: 'direct_expense_' + key,
                                    filedType: '单选',
                                    visible: false,
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: [item.direct_expense],
                                    radioList: [
                                        { value: 1, label: '设备费' },
                                        { value: 2, label: '材料费' },
                                        { value: 3, label: '测试化验加工费' },
                                        { value: 4, label: '燃料动力费' },
                                        { value: 5, label: '差旅费/会议费' },
                                        { value: 6, label: '出版/文献/信息传播/知识产权事务费' },
                                        { value: 7, label: '劳务费' },
                                        { value: 8, label: '专家咨询费' },
                                        { value: 9, label: '其他费用' },
                                    ]
                                },
                                {
                                    filedLabel: '天艺支出',
                                    filedName: 'direct_ty_money_' + key,
                                    filedType: '文本',
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.direct_ty_money,
                                },
                                {
                                    filedLabel: '合作单位支出',
                                    filedName: 'direct_hz_money_' + key,
                                    filedType: '文本',
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.direct_hz_money,
                                },
                            ]
                        }
                        Info.ListInfo[1].dataSource = [...Info.ListInfo[1].dataSource, dataSource]
                    })


                    Info.ListInfo[2].dataSource = []
                    let indirect = JSON.parse(res.data.Detail.indirect)
                    indirect.forEach((item, index) => {
                        let key = index + 1
                        let dataSource = {
                            key: key,
                            list: [
                                {
                                    filedLabel: '项目等级',
                                    filedName: 'overhead' + key,
                                    filedType: '单选',
                                    visible: false,
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: [item.overhead],
                                    radioList: [
                                        { value: 1, label: '绩效支出' },
                                    ]
                                },
                                {
                                    filedLabel: '天艺支出',
                                    filedName: 'indirect_ty_money_' + key,
                                    filedType: '文本',
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.indirect_ty_money,
                                },
                                {
                                    filedLabel: '合作单位支出',
                                    filedName: 'indirect_hz_money_' + key,
                                    filedType: '文本',
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.indirect_hz_money,
                                },
                            ]
                        }
                        Info.ListInfo[1].dataSource = [...Info.ListInfo[1].dataSource, dataSource]
                    })



                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.pro_name
                        } else if (item.filedLabel === '项目领域') {
                            item.value = res.data.Detail.pro_area
                        } else if (item.filedLabel === '项目编号') {
                            item.value = res.data.Detail.pro_code
                        } else if (item.filedLabel === '项目等级') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.pro_grade]
                        } else if (item.filedLabel === '项目类型') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.pro_type]
                        } else if (item.filedLabel === '类别') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.class]
                        } else if (item.filedLabel === '部门名称') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.de_id]
                        } else if (item.filedLabel === '项目经费(元)') {
                            item.value = res.data.Detail.pro_money
                        } else if (item.filedLabel === '项目负责人') {
                            item.value = res.data.Detail.pro_manager
                        } else if (item.filedLabel === '合作单位') {
                            item.value = res.data.Detail.coo_unit
                        } else if (item.filedLabel === '联系电话') {
                            item.value = res.data.Detail.phone
                        } else if (item.filedLabel === '项目启动时间') {
                            item.value = res.data.Detail.start_time ? new Date(res.data.Detail.start_time) : ''
                        } else if (item.filedLabel === '计划结束时间') {
                            item.value = res.data.Detail.end_time ? new Date(res.data.Detail.end_time) : ''
                        }
                    })

                    Info.ListInfo[3].list.forEach((item, index) => {
                        if (item.filedLabel === '预期成果') {
                            item.value = res.data.Detail.expected_results
                        } else if (item.filedLabel === '结题目标') {
                            item.value = res.data.Detail.closing_goal
                        } else if (item.filedLabel === '项目要点归纳') {
                            item.value = res.data.Detail.key_points
                        } else if (item.filedLabel === '备注') {
                            item.value = res.data.Detail.info
                        } else if (item.filedLabel === '项目申请书') {
                            item.value = res.data.Detail.enclosure || []
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleWind = (name) => {
        let { Info } = this.state
        Info.ListInfo.forEach((item, index) => {
            if (item.plateName === name) {
                item.unWind = !item.unWind
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='研发项目' />

                <AddConfig key={FormKey} Info={Info} windEvent={this.handleWind} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
