import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import AMapLoader from '@amap/amap-jsapi-loader';
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { createUserSign } from "../../../utils/oa"
import Icon from "../../../accets/img/icon_57.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                apply: {
                    title: '出勤签到',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '签到时间',
                        filedName: 'start',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'minute',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: new Date(),
                    },
                    {
                        filedLabel: '位置',
                        filedName: 'address',
                        filedType: '默认',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                ]
            },
            Form: {},
        }
    }

    componentDidMount() {
        this.get_userInfo()
        this.get_Geolocation()
    }

    get_userInfo = () => {
        let { Info } = this.state;
        let userInfo = storage.getItem('userInfo');

        Info.apply.userName = userInfo.name
        Info.apply.deName = userInfo.deName
        Info.apply.apply_date = ''

        this.setState(preState => ({
            Info,
        })
        )
    }

    get_Geolocation() {
        let _this = this
        AMapLoader.load({
            key: "e091f26fd34d16a2e7d93f6779b4b838",                     // 申请好的Web端开发者Key，首次调用 load 时必填
            version: "2.0",              // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            plugins: [''],               // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        }).then((AMap) => {
            AMap.plugin(['AMap.Geolocation', 'AMap.Geocoder'], function () {
                var geolocation = new window.AMap.Geolocation({
                    enableHighAccuracy: true, // 是否使用高精度定位，默认：true
                    timeout: 10000, // 设置定位超时时间，默认：无穷大
                    offset: [10, 20],  // 定位按钮的停靠位置的偏移量
                    zoomToAccuracy: true,  //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                    position: 'RB' //  定位按钮的排放位置,  RB表示右下
                });

                var geocoder = new AMap.Geocoder({
                    city: '全国' // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                })

                geolocation.getCurrentPosition(function (status, result) {
                    if (status === 'complete') {
                        let position = result.position
                        geocoder.getAddress(position, function (status, result) {
                            if (status === 'complete' && result.info === 'OK') {
                                _this.get_Assign(result.regeocode.formattedAddress, position)
                            } else {
                                console.log(result)
                            }
                        })

                    } else {
                        if (result.info === 'TIMEOUT') {
                            alert('定位超时，请稍后再试');
                        } else {
                            alert('定位失败，请检查企业微信定位权限是否开启');
                        }
                    }
                });


            });
        }).catch(e => {
            console.log(e);
        })
    }

    get_Assign = (address, position) => {
        let { Info, Form } = this.state;
        Info.TableInfo.forEach((item, index) => {
            if (item.filedLabel === '位置') {
                item.value = address
                Form.long = position.lng
                Form.lat = position.lat
                Form.address = address
            }
        })

        this.setState(preState => ({
            Info,
            Form,
        })
        )
    }

    handlePresent = (data) => {
        const { Form } = this.state
        Form.status = 2

        createUserSign(Form).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '提交成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    Toast.show({
                        icon: 'fail',
                        content: res.data[0] || '提交失败,请检查表单!',
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { Info } = this.state
        return (
            <div>
                <NavBar menuName='新增出勤签到' />

                <AddConfig Info={Info} presentEvent={this.handlePresent} />
            </div>
        )
    }
}

export default withRouter(NewItem)
