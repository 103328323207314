import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { researchProjectAuxiliary, researchProjectDetail } from "../../../utils/public"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_23.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '成果转化项目立项申请',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '立项类别',
                        filedName: 'pro_class',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: [],
                        radioList: []
                    },
                    {
                        filedLabel: '二级项目类别',
                        filedName: 'two_level',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: [],
                        radioList: []
                    },
                    {
                        filedLabel: '项目编号',
                        filedName: 'pro_number',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '立项时间',
                        filedName: 'cre_day',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目经理',
                        filedName: 'pro_manager',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '省份',
                        filedName: 'province',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: [],
                        radioList: []
                    },
                    {
                        filedLabel: '城市',
                        filedName: 'city',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: [],
                        radioList: []
                    },
                    {
                        filedLabel: '中标单位',
                        filedName: 'select_unit',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: [],
                        radioList: []
                    },
                    {
                        filedLabel: '中标金额(元)',
                        filedName: 'select_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '预计开工时间',
                        filedName: 'plan_open_day',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '要求竣工时间',
                        filedName: 'over_day',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '工期(天)',
                        filedName: 'duration',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '施工面积(㎡)',
                        filedName: 'build_area',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '总包单位',
                        filedName: 'gen_unit',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '总包联系人',
                        filedName: 'gen_proxy',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '总包联系方式',
                        filedName: 'gen_contact',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '业主单位',
                        filedName: 'owner_unit',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '业主联系人',
                        filedName: 'owner_proxy',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '业主联系方式',
                        filedName: 'contact',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                    {
                        filedLabel: '竣工时间',
                        filedName: 'over_time',
                        filedType: '默认',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '人工工日',
                        filedName: 'peo_date',
                        filedType: '默认',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '人工成本(元)',
                        filedName: 'peo_cost',
                        filedType: '默认',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '结算金额(元)',
                        filedName: 'set_money',
                        filedType: '默认',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '结算面积(㎡)',
                        filedName: 'set_area',
                        filedType: '默认',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '其他费用',
                        filedName: 'other_cost',
                        filedType: '默认',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                ],
                provinces: []
            },
        }
    }

    componentDidMount() {
        this.get_researchProjectAuxiliary()
    }

    get_researchProjectAuxiliary = () => {
        let { Info } = this.state
        researchProjectAuxiliary().then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '立项类别') {
                            for (let key in res.data.researchProjectMode) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.researchProjectMode[key]
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        } else if (item.filedLabel === '省份') {
                            res.data.provinces.forEach((tem, dex) => {
                                let value = {
                                    value: Number(tem.value),
                                    label: tem.label
                                }
                                item.radioList = [...item.radioList, value]
                            })
                        } else if (item.filedLabel === '中标单位') {
                            for (let key in res.data.projectResearchUnit) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.projectResearchUnit[key]
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        } else if (item.filedLabel === '二级项目类别') {
                            for (let key in res.data.researchProjectTwoLevelMode) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.researchProjectTwoLevelMode[key]
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        provinces: res.data.provinces
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_researchProjectDetail(tableId)
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_researchProjectDetail = (tableId) => {
        const { Info, provinces } = this.state;
        let data = {
            table_id: tableId
        }
        researchProjectDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.status === 2 || res.data.status === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '附件') {
                                if (!res.data.enclosure || !res.data.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })

                        if (res.data.over_time) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.filedLabel === '竣工时间') {
                                    item.unShow = false
                                    item.value = res.data.over_time
                                } else if (item.filedLabel === '人工工日') {
                                    item.unShow = false
                                    item.value = res.data.peo_date
                                } else if (item.filedLabel === '人工成本(元)') {
                                    item.unShow = false
                                    item.value = res.data.peo_cost
                                }else if (item.filedLabel === '结算金额(元)') {
                                    item.unShow = false
                                    item.value = res.data.set_money
                                }else if (item.filedLabel === '结算面积(㎡)') {
                                    item.unShow = false
                                    item.value = res.data.set_area
                                }
                            })
                        }
                    }

                    Info.apply.status = res.data.status
                    Info.apply.userName = res.data.applyUsername
                    Info.apply.deName = res.data.applyDeName
                    Info.apply.apply_date = res.data.created_at
                    Info.apply.process = res.data.reviewAudit
                    Info.apply.process.editPermission = res.data.editPermission

                    provinces.forEach((item, index) => {
                        if (item.value === res.data.province) {
                            Info.TableInfo.forEach((tem, dex) => {
                                if (tem.filedLabel === '城市') {
                                    item.children.forEach((em, ex) => {
                                        let value = {
                                            value: Number(em.value),
                                            label: em.label
                                        }
                                        tem.radioList = [...tem.radioList, value]
                                    })
                                }
                            })
                        }
                    })

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '立项类别') {
                            item.value = []
                            item.value = [...item.value, Number(res.data.pro_class)]
                        } else if (item.filedLabel === '二级项目类别') {
                            item.value = []
                            item.value = [...item.value, Number(res.data.two_level)]
                        } else if (item.filedLabel === '项目编号') {
                            item.value = res.data.pro_number
                        } else if (item.filedLabel === '立项时间') {
                            item.value = new Date(res.data.cre_day)
                        } else if (item.filedLabel === '项目经理') {
                            item.value = res.data.pro_manager
                        } else if (item.filedLabel === '项目名称') {
                            item.value = res.data.pro_name
                        }else if (item.filedLabel === '省份') {
                            item.value = []
                            item.value = [...item.value, Number(res.data.province)]
                        }else if (item.filedLabel === '城市') {
                            item.value = []
                            item.value = [...item.value, Number(res.data.city)]
                        }else if (item.filedLabel === '中标单位') {
                            item.value = []
                            item.value = [...item.value, Number(res.data.select_unit)]
                        }else if (item.filedLabel === '中标金额(元)') {
                            item.value = res.data.select_money
                        }else if (item.filedLabel === '预计开工时间') {
                            item.value = new Date(res.data.plant_open_day)
                        }else if (item.filedLabel === '要求竣工时间') {
                            item.value = new Date(res.data.over_day)
                        }else if (item.filedLabel === '工期(天)') {
                            item.value = res.data.duration
                        }else if (item.filedLabel === '施工面积(㎡)') {
                            item.value = res.data.build_area
                        }else if (item.filedLabel === '总包单位') {
                            item.value = res.data.gen_unit
                        }else if (item.filedLabel === '总包联系人') {
                            item.value = res.data.gen_proxy
                        }else if (item.filedLabel === '总包联系方式') {
                            item.value = res.data.gen_contact
                        }else if (item.filedLabel === '业主单位') {
                            item.value = res.data.owner_unit
                        }else if (item.filedLabel === '业主联系人') {
                            item.value = res.data.owner_proxy
                        }else if (item.filedLabel === '业主联系方式') {
                            item.value = res.data.contact
                        }else if (item.filedLabel === '附件') {
                            item.value = res.data.enclosure || []
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='成果转化项目立项申请(358工程)' />

                <AddConfig key={FormKey} Info={Info} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
