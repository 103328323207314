import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { flowReviewRead, flowCcRead, userReadWeekly, readConstructionLog } from "../../utils/api"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    get_details(item) {
        if (this.props.TabName === '流程待办') {
            this.handleFlowReview(item.table_id)
        } else if (this.props.TabName === '流程待阅') {
            this.handleFlowCc(item.table_id)
        } else if (this.props.TabName === '周报待阅') {
            this.handleWeekly(item.table_id)
        } else if (this.props.TabName === '施工日志') {
            this.handleLog(item.table_id)
        }

        this.props.history.push({
            pathname: item.url,
            query: {
                'tableId': item.table_id,
            }
        })
    }

    handleFlowReview = (tableId) => {
        let data = {
            table_id: tableId
        }
        flowReviewRead(data).then(
            (res) => {
                if (!res.code) { }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleFlowCc = (tableId) => {
        let data = {
            table_id: tableId
        }
        flowCcRead(data).then(
            (res) => {
                if (!res.code) { }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleWeekly = (tableId) => {
        let data = {
            table_id: tableId
        }
        userReadWeekly(data).then(
            (res) => {
                if (!res.code) { }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleLog = (tableId) => {
        let data = {
            table_id: tableId
        }
        readConstructionLog(data).then(
            (res) => {
                if (!res.code) { }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { promptList } = this.props
        return (
            <div className='prompt_list'>
                {
                    promptList.map((item, index) => {
                        return (
                            <div key={index} onClick={() => this.get_details(item)}>
                                <div>{item.apply_date}</div>
                                <div>
                                    <div>
                                        {
                                            item.is_read !== 0 ? <div className='read'></div> : <div className='unread'></div>
                                        }
                                        <div>
                                            <img src={item.icon} alt=""></img>
                                            <div>{item.title}</div>
                                        </div>
                                    </div>
                                    {
                                        item.status === 0 ? <div className='notapproved'>未审批</div> : item.status ? <div className='approved'>已审批</div> : ''
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

export default withRouter(List)


