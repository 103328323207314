import React, { Component } from 'react'
import { Input } from 'antd-mobile'

export default class UserInput extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        let { type, placeholder, disabled, value, onChange } = this.props
        return (
            <Input
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                value={value}
                onChange={(e) => {
                    onChange(e)
                }}
            />
        )
    }
}
