import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { researchContractAuxiliary, SettlementPaymentDetail } from "../../../utils/public"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_32.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '成果转化项目付款审批',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '项目付款',
                        filedName: 'is_pro',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                label: '是',
                                value: 1,
                            }, {
                                label: '否',
                                value: 2,
                            }
                        ]
                    },
                    {
                        filedLabel: '合同名称',
                        filedName: 'con_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '合同编号',
                        filedName: 'con_number',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '合同类别',
                        filedName: 'con_type',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '合同总金额(元)',
                        filedName: 'con_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '本次付款(元)',
                        filedName: 'pay_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '付款说明',
                        filedName: 'pay_info',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '本次开票金额(元)',
                        filedName: 'invo_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '前期已开票金额(元)',
                        filedName: 'acc_invoice',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '已累计付款(元)',
                        filedName: 'total_pay_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '费用类型',
                        filedName: 'money_type',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '付款单位',
                        filedName: 'pay_unit',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [],
                        unShow: true
                    },
                    {
                        filedLabel: '是否欠票',
                        filedName: 'has_owing',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                label: '是',
                                value: 1,
                            }, {
                                label: '否',
                                value: 2,
                            }
                        ]
                    },
                    {
                        filedLabel: '欠票单位',
                        filedName: 'unit_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '欠票类型',
                        filedName: 'invo_type',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '欠票金额(元)',
                        filedName: 'money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '欠票原因',
                        filedName: 'cause',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '补票时间',
                        filedName: 'replenish_invo',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '付款类别',
                        filedName: 'pay_type',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            { value: 1, label: "预付款" },
                            { value: 2, label: "进度计量" },
                            { value: 3, label: "结算审批" },
                        ],
                        unShow: true
                    },
                    {
                        filedLabel: '付款节点',
                        filedName: 'pay_class',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '累计进度计量金额(元)',
                        filedName: 'pro_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '结算金额(元)',
                        filedName: 'set_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '出库单据',
                        filedName: 'outbound',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '收款账号',
                        filedName: 'bank_number',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '收款户名',
                        filedName: 'accout_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '收款银行',
                        filedName: 'bank_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '联系人',
                        filedName: 'contact',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '联系电话',
                        filedName: 'phone',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '备注',
                        filedName: 'info',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        unShow: true
                    },
                ]
            },
        }
    }

    componentDidMount() {
        this.get_researchContractAuxiliary()
    }

    get_researchContractAuxiliary = () => {
        let { Info } = this.state
        researchContractAuxiliary().then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '付款单位') {
                            for (let key in res.data.projectResearchUnit) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.projectResearchUnit[key]
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        }
                    })

                    this.setState(preState => ({
                        Info,
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_SettlementPaymentDetail(tableId)
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_SettlementPaymentDetail = (tableId) => {
        const { Info } = this.state;
        let data = {
            table_id: tableId
        }
        SettlementPaymentDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.status === 2 || res.data.status === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '附件') {
                                if (!res.data.enclosure || !res.data.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.status
                    Info.apply.userName = res.data.applyUsername
                    Info.apply.deName = res.data.applyDeName
                    Info.apply.apply_date = res.data.apply_day
                    Info.apply.process = res.data.reviewAudit
                    Info.apply.process.editPermission = res.data.editPermission

                    if (res.data.is_pro === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '项目名称') {
                                item.unShow = false
                                item.value = res.data.proName
                            } else if (item.filedLabel === '合同类别') {
                                item.value = res.data.con_type
                            } else if (item.filedLabel === '付款说明') {
                                item.unShow = false
                                item.value = res.data.pay_info
                            } else if (item.filedLabel === '本次开票金额(元)') {
                                item.unShow = false
                                item.value = res.data.invo_money
                            } else if (item.filedLabel === '前期已开票金额(元)') {
                                item.unShow = false
                                item.value = res.data.used_invo_money
                            } else if (item.filedLabel === '付款类别') {
                                item.unShow = false
                                item.value = []
                                item.value = [...item.value, res.data.pay_type]
                            } else if (item.filedLabel === '联系人') {
                                item.unShow = false
                                item.value = res.data.contacts
                            } else if (item.filedLabel === '联系电话') {
                                item.unShow = false
                                item.value = res.data.phone
                            }
                        })

                        if (res.data.pay_type === 1) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.filedLabel === '付款节点') {
                                    item.unShow = false
                                    item.value = res.data.pay_class
                                }
                            })
                        } else if (res.data.pay_type === 2) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.filedLabel === '累计进度计量金额(元)') {
                                    item.unShow = false
                                    item.value = res.data.add_money
                                }
                            })
                        } else if (res.data.pay_type === 3) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.filedLabel === '结算金额(元)') {
                                    item.unShow = false
                                    item.value = res.data.add_money
                                }
                            })
                        }
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '合同类别') {
                                item.value = res.data.contractDetail.con_type === 1 ? '采购合同' : res.data.contractDetail.con_type === 3 ? '其他合同' : ''
                            } else if (item.filedLabel === '已累计付款(元)') {
                                item.unShow = false
                                item.value = res.data.used_pay_money
                            } else if (item.filedLabel === '费用类型') {
                                item.unShow = false
                                item.value = res.data.invo_type
                            } else if (item.filedLabel === '付款单位') {
                                item.unShow = false
                                item.value = []
                                item.value = [...item.value, res.data.pay_unit]
                            } else if (item.filedLabel === '付款节点') {
                                item.unShow = false
                                item.value = res.data.pay_class
                            } else if (item.filedLabel === '出库单据') {
                                item.unShow = false
                                item.value = res.data.outbound || ''
                            }
                        })
                    }

                    if (res.data.is_owing === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '欠票单位') {
                                item.value = res.data.unit_name
                                item.unShow = false
                            } else if (item.filedLabel === '欠票类型') {
                                item.value = res.data.invo_type
                                item.unShow = false
                            } else if (item.filedLabel === '欠票金额(元)') {
                                item.value = res.data.money
                                item.unShow = false
                            } else if (item.filedLabel === '欠票原因') {
                                item.value = res.data.cause
                                item.unShow = false
                            } else if (item.filedLabel === '补票时间') {
                                item.value = res.data.replenish_invo ? new Date(res.data.replenish_invo) : ''
                                item.unShow = false
                            }
                        })
                    }

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '项目付款') {
                            item.value = []
                            item.value = [...item.value, res.data.is_pro]
                        } else if (item.filedLabel === '合同名称') {
                            item.value = res.data.con_name
                        } else if (item.filedLabel === '合同编号') {
                            item.value = res.data.con_num
                        } else if (item.filedLabel === '合同总金额(元)') {
                            item.value = res.data.con_money
                        } else if (item.filedLabel === '本次付款(元)') {
                            item.value = res.data.pay_money
                        } else if (item.filedLabel === '是否欠票') {
                            item.value = []
                            item.value = [...item.value, res.data.is_owing]
                        } else if (item.filedLabel === '收款账号') {
                            item.value = res.data.col_number
                        } else if (item.filedLabel === '收款户名') {
                            item.value = res.data.col_username
                        } else if (item.filedLabel === '收款银行') {
                            item.value = res.data.bank
                        } else if (item.filedLabel === '备注') {
                            item.value = res.data.con_info
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.enclosure || []
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='成果转化项目付款审批(358工程)' />

                <AddConfig key={FormKey} Info={Info} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
