import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { contractAuxiliaryData, contractApprovalDetail } from "../../../utils/public"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_21.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '公司合同审批',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '合同编号',
                        filedName: 'code',
                        filedType: '默认',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '科研院合同',
                        filedName: 'is_sc',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                label: '是',
                                value: 1,
                            }, {
                                label: '否',
                                value: 2,
                            }
                        ]
                    }, {
                        filedLabel: '是否盖章',
                        filedName: 'is_seal',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                label: '是',
                                value: 1,
                            }, {
                                label: '否',
                                value: 0,
                            }
                        ]
                    },
                    {
                        filedLabel: '印章名称',
                        filedName: 'seal_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '收支类别',
                        filedName: 'pay_type',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [],
                    },
                    {
                        filedLabel: '结算合同方式',
                        filedName: 'set_model',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [],
                    },
                    {
                        filedLabel: '合同类别',
                        filedName: 'con_category',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [],
                    },
                    {
                        filedLabel: '合同性质',
                        filedName: 'con_nature',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [],
                    },
                    {
                        filedLabel: '合同版本类别',
                        filedName: 'version_type',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            { value: 1, label: "标准版本" },
                            { value: 2, label: "其他版本" },
                        ],
                    },
                    {
                        filedLabel: '合同名称',
                        filedName: 'con_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入合同名称',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '主合同名称',
                        filedName: 'main_name',
                        filedType: '默认',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '主合同编号',
                        filedName: 'main_code',
                        filedType: '默认',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '合同甲方',
                        filedName: 'first_party',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入合同甲方',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '合同乙方',
                        filedName: 'party_b',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入合同乙方',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '合同总金额(元)',
                        filedName: 'con_money',
                        filedType: '文本',
                        nodeType: 'number',
                        placeholder: '合同总金额(元)',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '税率(%)',
                        filedName: 'tax',
                        filedType: '文本',
                        nodeType: 'number',
                        placeholder: '请输入税率(%)',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '发票类型',
                        filedName: 'invo_type',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [],
                    },
                    {
                        filedLabel: '合同支付条款',
                        filedName: 'pay_clause',
                        filedType: '文本域',
                        placeholder: '请输入合同支付条款',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '其他特殊条款',
                        filedName: 'other_clause',
                        filedType: '文本域',
                        placeholder: '请输入其他特殊条款',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '相关说明',
                        filedName: 'info',
                        filedType: '文本域',
                        placeholder: '请输入相关说明',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                ]
            },
        }
    }

    componentDidMount() {
        this.get_contractAuxiliaryData()
    }

    get_contractAuxiliaryData = () => {
        let { Info } = this.state
        contractAuxiliaryData().then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '收支类别') {
                            for (let key in res.data.pay_type) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.pay_type[key]
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        } else if (item.filedLabel === '结算合同方式') {
                            for (let key in res.data.natureMethod) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.natureMethod[key]
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        } else if (item.filedLabel === '合同类别') {
                            for (let key in res.data.natureType) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.natureType[key],
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        } else if (item.filedLabel === '合同性质') {
                            for (let key in res.data.NatureContract) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.NatureContract[key]
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        } else if (item.filedLabel === '发票类型') {
                            for (let key in res.data.invoice_type) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.invoice_type[key]
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        }
                    })

                    console.log(Info)

                    this.setState(preState => ({
                        Info
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_contractApprovalDetail(tableId)
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_contractApprovalDetail = (tableId) => {
        const { Info, Form } = this.state;
        let data = {
            table_id: tableId
        }
        contractApprovalDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 2 || res.data.Detail.status === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '附件') {
                                if (!res.data.Detail.enclosure || !res.data.Detail.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    if (res.data.Detail.is_seal === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '印章名称') {
                                item.unShow = false
                                item.value = res.data.Detail.sealNames
                            }
                        })
                    }

                    if (res.data.Detail.con_nature === 2) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '主合同编号') {
                                item.value = res.data.Detail.main.code
                                item.unShow = false
                            } else if (item.filedLabel === '主合同名称') {
                                item.value = res.data.Detail.main.con_name
                                item.unShow = false
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUserName
                    Info.apply.deName = res.data.Detail.aplyUserDeName
                    Info.apply.apply_date = res.data.Detail.apply_date
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '合同编号') {
                            item.value = res.data.Detail.code
                        } else if (item.filedLabel === '科研院合同') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.is_sc]
                        } else if (item.filedLabel === '是否盖章') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.is_seal]
                        } else if (item.filedLabel === '收支类别') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.pay_type]
                        } else if (item.filedLabel === '结算合同方式') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.set_model]
                        } else if (item.filedLabel === '合同类别') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.con_category]
                        } else if (item.filedLabel === '合同性质') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.con_nature]
                        } else if (item.filedLabel === '合同版本类别') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.version_type]
                        } else if (item.filedLabel === '合同名称') {
                            item.value = res.data.Detail.con_name
                        } else if (item.filedLabel === '合同甲方') {
                            item.value = res.data.Detail.first_party
                        } else if (item.filedLabel === '合同乙方') {
                            item.value = res.data.Detail.party_b
                        } else if (item.filedLabel === '合同总金额(元)') {
                            item.value = res.data.Detail.con_money
                        } else if (item.filedLabel === '税率(%)') {
                            item.value = res.data.Detail.tax
                        } else if (item.filedLabel === '发票类型') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.invo_type]
                        } else if (item.filedLabel === '合同支付条款') {
                            item.value = res.data.Detail.pay_clause
                        } else if (item.filedLabel === '其他特殊条款') {
                            item.value = res.data.Detail.other_clause
                        } else if (item.filedLabel === '相关说明') {
                            item.value = res.data.Detail.info
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.Detail.enclosure || []
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        Form,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='新增公司合同审批' />

                <AddConfig key={FormKey} Info={Info} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
