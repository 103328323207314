import React, { Component } from 'react'
import { CheckList, Popup, SearchBar, Button, } from 'antd-mobile'

export default class UserPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: []
        }
    }

    SearchText = (value) => {
        this.props.queryEvent(value)
    }

    checkChange = (value) => {
        this.setState(preState => ({
            selected: value
        }))
    }

    setSelected = () => {
        let { selected } = this.state
        this.props.selectEvent(selected)
    }

    render() {
        let { popup, value } = this.props
        return (
            <Popup
                visible={popup.isShow}
                showCloseButton
                onMaskClick={() => this.props.closeEvent()}
                onClose={() => this.props.closeEvent()}
                destroyOnClose
                bodyStyle={{
                    borderTopLeftRadius: '15px',
                    borderTopRightRadius: '15px',
                    //maxHeight: '40vh',
                    backgroundColor: '#FFFFFF'
                }}
            >
                <div className='popup_select'>
                    <div>{popup.title}</div>
                    <div>
                        <SearchBar
                            placeholder={popup.placeholder}
                            onChange={(e) => this.SearchText(e)}
                        />
                    </div>
                    <div>
                        <CheckList
                            multiple={popup.multiple}
                            disabled={popup.disabled}
                            onChange={(e) => this.checkChange(e)}
                        >
                            {
                                popup.dataSource.map(item => (
                                    <CheckList.Item key={item.value} value={item.value}>
                                        {item.label}
                                    </CheckList.Item>
                                ))
                            }
                        </CheckList>
                    </div>
                    <Button block type='submit' color='primary' size='large' onClick={() => this.setSelected()}>
                        确定
                    </Button>
                </div>
            </Popup >          
        )
    }
}
