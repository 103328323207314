import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { vehicleDispatchDetail } from "../../../utils/oa"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_44.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '车辆派遣',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '车辆类型',
                        filedName: 've_type',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: [],
                        radioList: [
                            { value: 1, label: "商务用车" },
                            { value: 2, label: "项目用车" },
                        ]
                    },
                    {
                        filedLabel: '派遣开始时间',
                        filedName: 'start',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '派遣结束时间',
                        filedName: 'end',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '派遣项目',
                        filedName: 'pro_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '驾驶员',
                        filedName: 'driver',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                ],
                ListInfo: []
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_vehicleDispatchDetail(tableId)
        }
    }

    get_vehicleDispatchDetail = (tableId) => {
        const { Info } = this.state;
        let data = {
            table_id: tableId
        }
        vehicleDispatchDetail(data).then(
            (res) => {
                if (!res.code) {
                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUserName
                    Info.apply.deName = res.data.Detail.applyUserDeName
                    Info.apply.apply_date = res.data.Detail.created_at
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '车辆类型') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.ve_type]
                        } else if (item.filedLabel === '派遣开始时间') {
                            item.value = res.data.Detail.start ? new Date(res.data.Detail.start) : ''
                        } else if (item.filedLabel === '派遣结束时间') {
                            item.value = res.data.Detail.end ? new Date(res.data.Detail.end) : ''
                        } else if (item.filedLabel === '派遣项目') {
                            item.value = res.data.Detail.proName
                        } else if (item.filedLabel === '驾驶员') {
                            item.value = res.data.Detail.driverName
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='车辆派遣' />

                <AddConfig key={FormKey} Info={Info} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
