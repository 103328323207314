import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import dayjs from 'dayjs'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import UserPopup from "../../../components/UserPopup"
import { useSealApplyAuxiliaryData, createUseApply, useSealApplyDetail } from "../../../utils/oa"
import { selectAvailableSeal, selectProjectName, reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_08.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                apply: {
                    title: '用章申请',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '涉及工程资料',
                        filedName: 'is_eng',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: false,
                        required: true,
                        message: '请选择是否涉及工程资料',
                        value: [],
                        radioList: [
                            {
                                label: '是',
                                value: 1,
                            }, {
                                label: '否',
                                value: 2,
                            }
                        ]
                    },
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '工程资料分类',
                        filedName: 'eng_class',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                label: '不涉及金额',
                                value: 1,
                            }, {
                                label: '签证变更',
                                value: 2,
                            },
                            {
                                label: '收款等涉及金额',
                                value: 3,
                            },
                            {
                                label: '结算',
                                value: 4,
                            },
                            {
                                label: '授权及法人签字',
                                value: 5,
                            }
                        ],
                        unShow: true
                    },
                    {
                        filedLabel: '358工程用章',
                        filedName: 'is_res_pro',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: false,
                        required: true,
                        message: '请选择是否为358工程用章',
                        value: [],
                        radioList: [
                            {
                                label: '是',
                                value: 1,
                            }, {
                                label: '否',
                                value: 2,
                            },
                        ],
                    },
                    {
                        filedLabel: '用印时间',
                        filedName: 'use_time',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: true,
                        message: '请选择用印时间',
                        value: '',
                    },
                    {
                        filedLabel: '用印份数',
                        filedName: 'useNum',
                        filedType: '文本',
                        nodeType: 'number',
                        placeholder: '请输入用印份数',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '用印文件',
                        filedName: 'fileNames',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入用印文件名称',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '印章名称',
                        filedName: 'seal_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '用印事由',
                        filedName: 'yes_by',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入用印事由',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '备注',
                        filedName: 'remarks',
                        filedType: '文本域',
                        placeholder: '请输入备注',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: [],
                    },
                ]
            },
            Form: {},
            seal_name: '',
            pro_name: '',
            popupTitle: '',
            popup: {
                isShow: false,
                title: '',
                placeholder: '',
                multiple: false,
                disabled: false,
                dataSource: []
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_useSealApplyDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let { Info } = this.state;
        let userInfo = storage.getItem('userInfo');

        Info.apply.userName = userInfo.name
        Info.apply.deName = userInfo.deName
        Info.apply.apply_date = ''

        this.setState(preState => ({
            Info
        }), () => {
            this.get_useSealApplyAuxiliaryData()
        })
    }

    get_useSealApplyAuxiliaryData = () => {
        const { Form } = this.state;
        useSealApplyAuxiliaryData().then(
            (res) => {
                if (!res.code) {
                    Form.apply_user = res.data.apply_user.user_id
                    Form.de_id = res.data.apply_user.de_id

                    this.setState(preState => ({
                        Form,
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_useSealApplyDetail = (tableId) => {
        const { Info, Form } = this.state;
        let data = {
            table_id: tableId
        }
        useSealApplyDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        Form.table_id = tableId
                        Form.apply_user = res.data.Detail.apply_user
                        Form.de_id = res.data.Detail.de_id
                        Form.seal_id = res.data.Detail.seal_id
                        Form.project_id = res.data.Detail.project_id

                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.filedLabel === '附件') {
                                if (!res.data.Detail.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    if (res.data.Detail.is_eng === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '项目名称') {
                                item.unShow = false
                            } else if (item.filedLabel === '工程资料分类') {
                                item.unShow = false
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.username
                    Info.apply.deName = res.data.Detail.deName
                    Info.apply.apply_date = res.data.Detail.created_at
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '涉及工程资料') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.is_eng]
                        } else if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.projectName
                        } else if (item.filedLabel === '工程资料分类') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.eng_class]
                        } else if (item.filedLabel === '358工程用章') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.is_res_pro]
                        }else if (item.filedLabel === '用印时间') {
                            item.value = new Date(res.data.Detail.use_time)
                        } else if (item.filedLabel === '用印份数') {
                            item.value = res.data.Detail.use_num
                        } else if (item.filedLabel === '用印文件') {
                            item.value = res.data.Detail.usefiles
                        } else if (item.filedLabel === '印章名称') {
                            item.value = res.data.Detail.sealnames
                        } else if (item.filedLabel === '用印事由') {
                            item.value = res.data.Detail.yes_by
                        } else if (item.filedLabel === '备注') {
                            item.value = res.data.Detail.remarks
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.Detail.enclosure || []
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        Form,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_seal = () => {
        let { popup, seal_name } = this.state
        let data = {
            seal_name: seal_name,
        }
        selectAvailableSeal(data).then(
            (res) => {
                if (!res.code) {
                    popup.dataSource = []
                    if (res.data.length) {
                        res.data.forEach((item, index) => {
                            let seal = {
                                value: item.table_id,
                                label: item.name,
                            }
                            popup.dataSource = [...popup.dataSource, seal]
                        })

                        popup.title = '选择印章'
                        popup.placeholder = '输入印章名称查询'
                        popup.isShow = true
                        popup.multiple = true
                    }
                    this.setState(preState => ({
                        popup,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_selectProjectName = () => {
        let { popup, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: 1,
            limit: 500
        }
        selectProjectName(data).then(
            (res) => {
                if (!res.code) {
                    popup.dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let pro = {
                                value: item.table_id,
                                label: item.pro_name,
                            }
                            popup.dataSource = [...popup.dataSource, pro]
                        })

                        popup.title = '选择项目'
                        popup.placeholder = '输入项目名称查询'
                        popup.isShow = true
                    }
                    this.setState(preState => ({
                        popup,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleVisible = (title, name) => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.filedLabel === title) {
                item.visible = true
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleButton = (title) => {
        if (title === '项目名称') {
            this.get_selectProjectName()
        } else if (title === '印章名称') {
            this.get_seal()
        }
        this.setState(preState => ({
            popupTitle: title,
        }))
    }

    handleQuery = (value) => {
        let { popupTitle } = this.state
        if (popupTitle === '项目名称') {
            this.setState(preState => ({
                pro_name: value,
            }), () => {
                this.get_selectProjectName()
            })
        } else if (popupTitle === '印章名称') {
            this.setState(preState => ({
                seal_name: value,
            }), () => {
                this.get_seal()
            })
        }
    }

    handleClose = () => {
        this.setState(preState => ({
            seal_name: '',
            pro_name: '',
            popup: {
                isShow: false,
                title: '',
                placeholder: '',
                multiple: false,
                disabled: false,
                dataSource: []
            }
        }))
    }

    handleSelect = (value) => {
        let { popup, Info, Form, popupTitle } = this.state

        if (popupTitle === '项目名称') {
            if (value.length) {
                value.forEach((item, index) => {
                    popup.dataSource.forEach((em, ex) => {
                        if (em.value === item) {
                            Info.TableInfo.forEach((m, x) => {
                                if (m.filedLabel === '项目名称') {
                                    m.value = em.label
                                    Form.project_id = em.value
                                }
                            })
                        }
                    })
                })
            }
        } else if (popupTitle === '印章名称') {
            Form.seal_id = []
            if (value.length) {
                value.forEach((item, index) => {
                    popup.dataSource.forEach((em, ex) => {
                        if (em.value === item) {
                            Info.TableInfo.forEach((m, x) => {
                                if (m.filedLabel === '印章名称') {
                                    m.value = m.value + ' ' + em.label
                                   
                                }
                            })
                            Form.seal_id = [...Form.seal_id, em.value]
                        }
                    })
                })
                Form.seal_id = JSON.stringify(Form.seal_id)
            }
        }

        this.setState(preState => ({
            Info,
            Form,
            seal_name: '',
            pro_name: '',
            popup: {
                isShow: false,
                title: '',
                placeholder: '',
                multiple: false,
                disabled: false,
                dataSource: []
            }
        }))
    }

    handleFile = (fileList, fileName) => {
        let { Form, Info } = this.state
        let storeList = []
        if (fileList.length) {
            fileList.forEach((item, index) => {
                storeList.push(item.table_id)
            })
        }
        Form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Form,
            Info
        })
        )
    }

    handleDate = (title) => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.filedLabel === title) {
                item.visible = false
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleRadio = (title, value) => {
        let { Info } = this.state
        if (value[0] === 1) {
            Info.TableInfo.forEach((item, index) => {
                if (item.filedLabel === '项目名称') {
                    item.unShow = false
                } else if (item.filedLabel === '工程资料分类') {
                    item.unShow = false
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                if (item.filedLabel === '项目名称') {
                    item.unShow = true
                } else if (item.filedLabel === '工程资料分类') {
                    item.unShow = true
                }
            })
        }
        this.setState(preState => ({
            Info
        })
        )
    }

    handlePresent = (data) => {
        const { Info, Form } = this.state
        Form.status = 2
        Form.is_eng = data.is_eng[0]
        Form.is_res_pro = data.is_res_pro[0]
        Form.eng_class = data.eng_class ? data.eng_class[0] : ''
        Form.use_time = dayjs(data.use_time).format('YYYY-MM-DD')
        Form.useNum = data.useNum
        Form.fileNames = data.fileNames
        Form.yes_by = data.yes_by
        Form.remarks = data.remarks

        createUseApply(Form).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '提交成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '提交失败,请检查表单!',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info, popup } = this.state
        return (
            <div>
                <NavBar menuName='新增用章申请' />

                <AddConfig key={FormKey} Info={Info} buttonEvent={this.handleButton} visibleEvent={this.handleVisible} fileEvent={this.handleFile} dateEvent={this.handleDate} radioEvent={this.handleRadio} presentEvent={this.handlePresent} auditEvent={this.handleAudit} />

                <UserPopup popup={popup} closeEvent={this.handleClose} queryEvent={this.handleQuery} selectEvent={this.handleSelect} />
            </div>
        )
    }
}

export default withRouter(NewItem)
