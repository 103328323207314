import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { NavBar } from 'antd-mobile'
import {LeftOutline } from 'antd-mobile-icons'
import Record from "../../accets/img/record.png"


class NavRar extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    get_Back = () => {
        window.history.back(-1)
    }

    get_weekly=()=>{
        this.props.history.push('/public/human/weekly/List')
    }

    render() {
        let { menuName } = this.props
        const right = (<div className='record' onClick={()=>this.get_weekly()}>
            <img src={Record} alt=""></img>
            <div>历史记录</div>
        </div>)
        return (
            <div>
                {
                    menuName === '新建周报' ? <NavBar onBack={() => this.get_Back()} right={right} backArrow={<LeftOutline fontSize={20} color='#3396FB'/>}>{menuName}</NavBar> : <NavBar onBack={() => this.get_Back()} backArrow={<LeftOutline fontSize={20} color='#3396FB'/>}>{menuName}</NavBar>
                }
            </div>
        )
    }
}

export default withRouter(NavRar)
