import React, { Component } from 'react'
import { Form, Button, Popup, TextArea, SearchBar, CheckList, Toast } from 'antd-mobile'
import { AddOutline, CloseCircleFill, RightOutline, DownOutline, UpOutline } from 'antd-mobile-icons'
import UserInput from "../../components/UserInput"
import UserDatePicker from "../../components/UserDatePicker"
import UserRadio from "../../components/UserRadio"
import UserSelector from "../../components/UserSelector"
import UserTextArea from "../../components/UserTextArea"
import UserAttach from "../../components/UserAttach"
import FileList from "../../components/FileList"
import DateTime from "../../components/DateTime"
import Point from "../../accets/img/point.png"
import Complete from "../../accets/img/complete.png"
import In_process from "../../accets/img/in_process.png"
import excess from "../../accets/img/excess.png"
import drop from "../../accets/img/drop.png"
import adjustPrice from "../../accets/img/adjustPrice.png"
import { reviewApplyResult } from "../../utils/api"

export default class AddConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ideaShow: false,
      ideaType: 3,
      appointShow: false,
      selectList: [],
      form: {},
    }
  }

  static getDerivedStateFromProps(props, state) {
    let { form, selectList } = state
    if (props.Info.select) {
      form.audit_id = props.Info.select.audit_id
      form.tableName = props.Info.select.tableName
      form.table_id = props.Info.select.table_id
      selectList = []
      props.Info.select.data.forEach((item, index) => {
        let value = {
          value: item.table_id,
          label: item.name
        }
        selectList = [...selectList, value]
      })
      return { appointShow: true, selectList, form }
    } else {
      return null
    }
  }

  InitItem = (item) => {
    var Node = null
    switch (item.filedType) {
      case '文本':
        Node = <UserInput type={item.nodeType} placeholder={item.placeholder} disabled={item.disabled} />
        break;
      case '日期':
        Node = <UserDatePicker visible={item.visible} precision={item.nodeType} title={item.filedLabel} name={item.filedName} disabled={item.disabled} pickerEvent={this.handleDate} />
        break;
      case '单选':
        Node = <UserRadio visible={item.visible} radioList={item.radioList} title={item.filedLabel} name={item.filedName} disabled={item.disabled} pickerEvent={this.handleDate} radioEvent={this.handleRadio} />
        break; case '多选':
        Node = <UserSelector selectList={item.selectList} title={item.filedLabel} name={item.filedName} disabled={item.disabled} />
        break;
      case '文本域':
        Node = <UserTextArea placeholder={item.placeholder} disabled={item.disabled} />
        break;
      case '附件':
        if (item.disabled) {
          Node = <FileList fileList={item.value} />
        } else {
          Node = <UserAttach fileEvent={this.handleFile} filedName={item.filedName} fileList={item.value} />
        }
        break;
      default:
        Node = null
        break;
    }

    return Node
  }

  onFinish = (form) => {
    this.props.presentEvent(form)
  }

  handleIdea = (value) => {
    this.setState(preState => ({
      ideaShow: true,
      ideaType: value,
      ideaValue: ''
    })
    )
  }

  closeIdea = () => {
    this.setState(preState => ({
      ideaShow: false,
    }))
  }

  textChange = (value) => {
    this.setState(preState => ({
      ideaValue: value,
    })
    )
  }

  saveIdea = () => {
    let { ideaType, ideaValue } = this.state
    if (ideaType === 4 || ((ideaType === 3 || ideaType === 5) && ideaValue)) {
      this.setState(preState => ({
        ideaShow: false,
      }), this.props.auditEvent(ideaType, ideaValue))
    } else {
      Toast.show({
        icon: 'fail',
        content: '请填写审批意见',
      })
    }
  }

  checkChange = (value) => {
    let { form } = this.state

    form.flowReviewUser = value[0]

    this.setState(preState => ({
      form
    }))
  }

  setSelected = () => {
    let { form } = this.state

    if (!form.flowReviewUser) {
      Toast.show({
        icon: 'fail',
        content: '请指定下一级审核人员',
      })
      return
    }

    reviewApplyResult(form).then(
      (res) => {
        if (!res.code) {
          Toast.show({
            icon: 'success',
            content: '提交成功',
          })
          this.setState({
            appointShow: false,
          }, () => {
            setTimeout(() => {
              window.history.back(-1)
            })
          })
        } else {
          Toast.show({
            icon: 'fail',
            content: '提交失败',
          })
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  handleFile = (fileList, fileName) => {
    this.props.fileEvent(fileList, fileName)
  }

  handleDate = (title, name) => {
    this.props.dateEvent(title, name)
  }

  handleRadio = (title, value) => {
    this.props.radioEvent(title, value)
  }


  render() {
    let { ideaShow, appointShow, selectList, ideaType } = this.state
    let { Info } = this.props
    return (
      <div>
        <Form
          onFinish={this.onFinish}
          footer={!Info.apply.status || Info.apply.status === 1 || (Info.apply.status === 3 && Info.apply.process.editPermission) ? <Button block type='submit' color='primary' size='large'>
            提交
          </Button> : ''}
          style={{ marginBottom: !Info.apply.status || Info.apply.status === 1 || (Info.apply.status === 3 && Info.apply.process.editPermission) ? '0px' : Info.apply.status === 4 || (Info.apply.status === 2 && !Info.apply.process.reviewPermission) ? '50px' : '120px' }}
        >
          <div className='form_title'>
            <div>
              <img src={Info.apply.icon} alt=""></img>
              <div>{Info.apply.title}</div>
            </div>
            {
              Info.apply.apply_date ? <div>{Info.apply.apply_date}</div> : <DateTime />
            }
          </div>

          <div className='userInfo'>
            <div>
              <div>{Info.apply.userName}</div>
              <div>{Info.apply.userName}</div>
            </div>
            <div>部门名称: {Info.apply.deName}</div>
          </div>

          <div className='form_content'>
            {
              /* 表单内容 */
              Info.TableInfo.map((item, index) => {
                if (!item.unShow) {
                  if (item.filedType === '默认') {
                    if (item.filedLabel === '总利润率(%)') {
                      return (
                        <Form.Item
                          key={index}
                          name={item.filedName}
                          label={item.filedLabel}
                        >
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <div style={{ marginRight: '10px', color: !item.value ? '#333333' : item.status ? 'red' : '#00dc9e' }}>{item.value || '/'}</div>
                            {
                              item.value ? <img style={{ width: '10px' }} src={item.status ? excess : drop} alt=""></img> : ''
                            }
                          </div>
                        </Form.Item>
                      )
                    } else {
                      return (
                        <Form.Item
                          key={index}
                          name={item.filedName}
                          label={item.filedLabel}
                        >
                          <div>{item.value}</div>
                        </Form.Item>
                      )
                    }
                  } else if ((item.filedType === '抄送' && (!Info.apply.status || Info.apply.status === 1 || (Info.apply.status === 3 && Info.apply.process.editPermission)))) {
                    return (
                      <Form.Item
                        key={index}
                        name={item.filedName}
                        label={item.filedLabel}
                      >
                        <div className='copyList'>
                          {
                            !item.value.length ? <div className='empty' onClick={() => this.props.buttonEvent(item.filedLabel)}>
                              <AddOutline fontSize={30} color='#999999' />
                            </div> : <div className='ccPersonList'>

                              {
                                item.value.map((em, ex) => {
                                  return (
                                    <div className='ccPerson' key={ex}>
                                      <div>
                                        <div onClick={() => this.props.removeEvent(em.value)}>
                                          <CloseCircleFill color='#000000' fontSize={14} />
                                          {em.label}
                                        </div>
                                        <div>
                                          {em.label}
                                        </div>
                                      </div>
                                      {
                                        item.filedLabel !== '项目经理' ? <RightOutline /> : ''
                                      }
                                    </div>
                                  )
                                })
                              }

                              {
                                item.filedLabel !== '项目经理' ? <div className='empty' onClick={() => this.props.buttonEvent(item.filedLabel)}>
                                  <AddOutline fontSize={30} color='#999999' />
                                </div> : ''
                              }

                            </div>
                          }

                          {
                            this.InitItem(item)
                          }
                        </div>
                      </Form.Item>
                    )
                  } else if (item.filedType === '抄送' && (Info.apply.status === 2 || Info.apply.status === 4 || (Info.apply.status === 3 && !Info.apply.process.editPermission))) {
                    return (
                      <Form.Item
                        key={index}
                        name={item.filedName}
                        label={item.filedLabel}
                      >
                        <div className='copyList'>
                          {
                            item.value.length ? <div className='ccPersonList'>

                              {
                                item.value.map((em, ex) => {
                                  return (
                                    <div className='ccPerson' key={ex}>
                                      <div>
                                        <div>
                                          <CloseCircleFill color='#000000' fontSize={14} style={{ opacity: 0 }} />
                                          {em.label}
                                        </div>
                                        <div>
                                          {em.label}
                                        </div>
                                      </div>
                                      {
                                        item.value.length !== (ex + 1) ? <RightOutline /> : ''
                                      }
                                    </div>
                                  )
                                })
                              }

                            </div> : ''
                          }

                          {
                            this.InitItem(item)
                          }
                        </div>
                      </Form.Item>
                    )
                  } else if (item.filedType === '选择') {
                    return (
                      <Form.Item
                        key={index}
                        name={item.filedName}
                        label={item.filedLabel}
                      >
                        <div onClick={() => {
                          if (!item.disabled) {
                            this.props.buttonEvent(item.filedLabel)
                          }
                        }}>
                          <div>{item.value}</div>
                          <div>
                            <span>{!item.value ? '请选择' : ''}</span>
                            <RightOutline color='#999999' fontSize={15} />
                          </div>
                        </div>
                      </Form.Item>
                    )
                  } else if (item.required) {
                    return (
                      <Form.Item
                        key={index}
                        name={item.filedName}
                        label={item.filedLabel}
                        initialValue={item.value}
                        layout={item.filedType === '文本' || item.filedType === '文本域' || item.filedType === '附件' || item.filedType === '多选' ? 'vertical' : 'horizontal'}
                        rules={[{ required: true, message: item.message }]}
                        onClick={() => {
                          if (!item.disabled) {
                            if (item.filedType === '日期' || item.filedType === '单选') {
                              this.props.visibleEvent(item.filedLabel, item.filedName)
                            }
                          }
                        }}
                      >
                        {
                          this.InitItem(item)
                        }
                      </Form.Item>
                    )
                  } else {
                    return (
                      <Form.Item
                        key={index}
                        name={item.filedName}
                        label={item.filedLabel}
                        initialValue={item.value}
                        layout={item.filedType === '文本' || item.filedType === '文本域' || item.filedType === '附件' || item.filedType === '多选' ? 'vertical' : 'horizontal'}
                        onClick={() => {
                          if (!item.disabled) {
                            if (item.filedType === '日期' || item.filedType === '单选') {
                              this.props.visibleEvent(item.filedLabel, item.filedName)
                            }
                          }
                        }}
                      >
                        {
                          this.InitItem(item)
                        }
                      </Form.Item>
                    )
                  }
                }
              })
            }

            {
              /* 表单内容 */
              Info.ListInfo && Info.ListInfo.length ? Info.ListInfo.map((item, index) => {
                if (!item.unShow) {
                  return (
                    <div key={index}>
                      {
                        item.plateName ? <div className='plate'>
                          <div className='title' onClick={() => this.props.windEvent(item.plateName)}>
                            <div>{item.plateName}</div>
                            {
                              item.unWind ? <div>
                                <div>收起</div>
                                <UpOutline color='#999999' fontSize={15} />
                              </div> : <div>
                                <div>展开</div>
                                <DownOutline color='#999999' fontSize={15} />
                              </div>
                            }
                          </div>

                          <div style={{ display: item.unWind ? 'block' : 'none' }}>
                            {
                              item.dataSource && item.dataSource.length ? item.dataSource.map((em, ex) => {
                                return (
                                  <div key={ex}>
                                    {
                                      em.list.map((m, x) => {
                                        // return (
                                        //   <div key={x} className="dataSource">
                                        //     <div>{x === 0 ? em.key + '、' : ''}</div>
                                        //     <Form.Item
                                        //       key={index}
                                        //       name={m.filedName}
                                        //       label={m.filedLabel}
                                        //       initialValue={m.value}
                                        //       layout='horizontal'
                                        //       onClick={() => {
                                        //         if (!m.disabled) {
                                        //           if (m.filedType === '日期' || m.filedType === '单选') {
                                        //             this.props.visibleEvent(m.filedLabel, m.filedName)
                                        //           }
                                        //         }
                                        //       }}
                                        //     >
                                        //       {
                                        //         this.InitItem(m)
                                        //       }
                                        //     </Form.Item>
                                        //   </div>
                                        // )
                                        if (m.filedType === '默认') {
                                          return (
                                            <div key={x} className="dataSource">
                                              <div>{x === 0 ? em.key + '、' : ''}</div>
                                              <Form.Item
                                                name={m.filedName}
                                                label={m.filedLabel}
                                                layout='horizontal'
                                              >
                                                <div>{m.value || '/'}</div>
                                              </Form.Item>
                                            </div>
                                          )
                                        } else if (m.filedType === '选择') {
                                          return (
                                            <div key={x} className="dataSource">
                                              <div>{x === 0 ? em.key + '、' : ''}</div>
                                              <Form.Item
                                                name={m.filedName}
                                                label={m.filedLabel}
                                                layout='horizontal'
                                              >
                                                <div onClick={() => this.props.buttonEvent(m.filedLabel)}>
                                                  <div>{m.value}</div>
                                                  <div>
                                                    <span>{!m.value ? '请选择' : ''}</span>
                                                    <RightOutline color='#999999' fontSize={15} />
                                                  </div>
                                                </div>
                                              </Form.Item>
                                            </div>
                                          )
                                        } else if (m.filedType === '抄送') {
                                          return (
                                            <div key={x} className="dataSource" style={{ alignItems: 'flex-start' }}>
                                              <div style={{ padding: '10px 0' }}>{x === 0 ? em.key + '、' : ''}</div>
                                              <Form.Item
                                                name={m.filedName}
                                                label={m.filedLabel}
                                              // layout='horizontal'
                                              >
                                                <div className='copyList'>
                                                  {
                                                    !m.value.length ? <div className='empty' onClick={() => this.props.buttonEvent(m.filedLabel, m.filedName)}>
                                                      <AddOutline fontSize={30} color='#999999' />
                                                    </div> : <div className='ccPersonList'>
                                                      {
                                                        m.value.map((e, i) => {
                                                          return (
                                                            <div className='ccPerson' key={i}>
                                                              <div>
                                                                <div onClick={() => this.props.removeEvent(e.value)}>
                                                                  <CloseCircleFill color='#000000' fontSize={14} />
                                                                  {e.label}
                                                                </div>
                                                                <div>
                                                                  {e.label}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          )
                                                        })
                                                      }
                                                    </div>
                                                  }

                                                  {
                                                    this.InitItem(m)
                                                  }
                                                </div>
                                              </Form.Item>
                                            </div>
                                          )
                                        } else {
                                          return (
                                            <div key={x} className="dataSource">
                                              <div>{x === 0 ? em.key + '、' : ''}</div>
                                              <Form.Item
                                                key={index}
                                                name={m.filedName}
                                                label={m.filedLabel}
                                                initialValue={m.value}
                                                layout='horizontal'
                                                onClick={() => {
                                                  if (!m.disabled) {
                                                    if (m.filedType === '日期' || m.filedType === '单选') {
                                                      this.props.visibleEvent(m.filedLabel, m.filedName)
                                                    }
                                                  }
                                                }}
                                              >
                                                {
                                                  this.InitItem(m)
                                                }
                                              </Form.Item>
                                            </div>
                                          )
                                        }
                                      })
                                    }
                                  </div>
                                )
                              }) : ''
                            }

                            {
                              item.dataColumn && item.dataColumn.length ? <div className='adjust_price'>
                                <div className='price_adjustment'>
                                  <div></div>
                                  <div>名称</div>
                                  <div>采购价(含税)</div>
                                  <div>采购价(不含税)</div>
                                </div>
                                {
                                  item.dataColumn.map((em, ex) => {
                                    return (
                                      <div key={ex}>
                                        {
                                          em.list.map((m, x) => {
                                            return (
                                              <div key={x} className='price_adjustment'>
                                                <div>{x === 0 ? em.key : ''}</div>
                                                <div>{m.name}</div>
                                                <div>
                                                  <div>
                                                    <div>
                                                      <div>单</div>
                                                      <img src={x !== 0 ? adjustPrice : ''} alt=""></img>
                                                    </div>
                                                    <div style={{ color: m.is_excess === 1 ? '#F53500' : m.is_excess === 1 ? '#00DC9E' : '#3396FB' }}>{m.purchase[0]}</div>
                                                    <img style={{ width: '10px' }} src={m.is_excess === 1 ? excess : m.is_excess === 2 ? drop : ''} alt=""></img>
                                                  </div>
                                                  <div>
                                                    <div>
                                                      <div>合</div>
                                                      <img src={x !== 0 ? adjustPrice : ''} alt=""></img>
                                                    </div>
                                                    <div style={{ color: m.is_excess === 1 ? '#F53500' : m.is_excess === 1 ? '#00DC9E' : '#3396FB' }}>{m.purchase[1]}</div>
                                                    <img style={{ width: '10px' }} src={m.is_excess === 1 ? excess : m.is_excess === 2 ? drop : ''} alt=""></img>
                                                  </div>
                                                </div>
                                                <div>
                                                  <div>
                                                    <div>
                                                      <div>单</div>
                                                      <img src={x !== 0 ? adjustPrice : ''} alt=""></img>
                                                    </div>
                                                    <div style={{ color: m.is_excess === 1 ? '#F53500' : m.is_excess === 1 ? '#00DC9E' : '#3396FB' }}>{m.procurement[0]}</div>
                                                    <img style={{ width: '10px' }} src={m.is_excess === 1 ? excess : m.is_excess === 2 ? drop : ''} alt=""></img>
                                                  </div>
                                                  <div>
                                                    <div>
                                                      <div>合</div>
                                                      <img src={x !== 0 ? adjustPrice : ''} alt=""></img>
                                                    </div>
                                                    <div style={{ color: m.is_excess === 1 ? '#F53500' : m.is_excess === 1 ? '#00DC9E' : '#3396FB' }}>{m.procurement[1]}</div>
                                                    <img style={{ width: '10px' }} src={m.is_excess === 1 ? excess : m.is_excess === 2 ? drop : ''} alt=""></img>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          })
                                        }
                                      </div>
                                    )
                                  })
                                }
                              </div> : ''
                            }
                          </div>


                          {
                            item.dataSource ? <div style={{ display: item.unWind ? 'flex' : 'none' }} onClick={() => this.props.newEvent(item.plateName)}>
                              <AddOutline color='#3396FB' fontSize={15} />
                              <div>新增{item.plateName}</div>
                            </div> : ''
                          }


                        </div> : item.list.map((em, ex) => {
                          if (!em.unShow) {
                            if (em.filedType === '默认') {
                              return (
                                <Form.Item
                                  key={ex}
                                  name={em.filedName}
                                  label={em.filedLabel}
                                >
                                  <div>{em.value}</div>
                                </Form.Item>
                              )
                            } else if (em.filedType === '抄送') {
                              return (
                                <Form.Item
                                  key={ex}
                                  name={em.filedName}
                                  label={em.filedLabel}
                                >
                                  <div className='copyList'>
                                    {
                                      !em.value.length ? <div className='empty' onClick={() => this.props.buttonEvent(em.filedLabel)}>
                                        <AddOutline fontSize={30} color='#999999' />
                                      </div> : <div className='ccPersonList'>

                                        {
                                          item.value.map((m, x) => {
                                            return (
                                              <div className='ccPerson' key={x}>
                                                <div>
                                                  <div onClick={() => this.props.removeEvent(m.value)}>
                                                    <CloseCircleFill color='#000000' fontSize={14} />
                                                    {m.label}
                                                  </div>
                                                  <div>
                                                    {m.label}
                                                  </div>
                                                </div>
                                                {
                                                  em.filedLabel !== '项目经理' ? <RightOutline /> : ''
                                                }
                                              </div>
                                            )
                                          })
                                        }

                                        {
                                          em.filedLabel !== '项目经理' ? <div className='empty' onClick={() => this.props.buttonEvent(em.filedLabel)}>
                                            <AddOutline fontSize={30} color='#999999' />
                                          </div> : ''
                                        }

                                      </div>
                                    }

                                    {
                                      this.InitItem(em)
                                    }
                                  </div>
                                </Form.Item>
                              )
                            } else if (em.filedType === '选择') {
                              return (
                                <Form.Item
                                  key={ex}
                                  name={em.filedName}
                                  label={em.filedLabel}
                                >
                                  <div onClick={() => this.props.buttonEvent(em.filedLabel)}>
                                    <div>{em.value}</div>
                                    <div>
                                      <span>{!em.value ? '请选择' : ''}</span>
                                      <RightOutline color='#999999' fontSize={15} />
                                    </div>
                                  </div>
                                </Form.Item>
                              )
                            } else if (em.required) {
                              return (
                                <Form.Item
                                  key={ex}
                                  name={em.filedName}
                                  label={em.filedLabel}
                                  initialValue={em.value}
                                  layout={em.filedType === '文本' || em.filedType === '文本域' || em.filedType === '附件' ? 'vertical' : 'horizontal'}
                                  rules={[{ required: true, message: em.message }]}
                                  onClick={() => {
                                    if (!em.disabled) {
                                      if (em.filedType === '日期' || em.filedType === '单选') {
                                        this.props.visibleEvent(em.filedLabel, em.filedName)
                                      }
                                    }
                                  }}
                                >
                                  {
                                    this.InitItem(em)
                                  }
                                </Form.Item>
                              )
                            } else {
                              return (
                                <Form.Item
                                  key={ex}
                                  name={em.filedName}
                                  label={em.filedLabel}
                                  initialValue={em.value}
                                  layout={em.filedType === '文本' || em.filedType === '文本域' || em.filedType === '附件' ? 'vertical' : 'horizontal'}
                                  onClick={() => {
                                    if (!em.disabled) {
                                      if (em.filedType === '日期' || em.filedType === '单选') {
                                        this.props.visibleEvent(em.filedLabel, em.filedName)
                                      }
                                    }
                                  }}
                                >
                                  {
                                    this.InitItem(em)
                                  }
                                </Form.Item>
                              )
                            }
                          }
                        })
                      }
                    </div>
                  )
                }
              }) : ''
            }
          </div>


          {
            /* 流程信息 */

            Info.apply.process.reviewAudit && Info.apply.process.reviewAudit.length ? <div className='process'>
              <div>流程信息</div>
              <div>
                <div>流程状态</div>
                {
                  Info.apply.status === 2 ? <div className='icon_02'>审核中</div> : Info.apply.status === 3 ? <div className='icon_03'>已驳回</div> : Info.apply.status === 5 ? <div className='icon_04'>已终止</div> : <div className='icon_05'>已完成</div>
                }
              </div>
              <div>
                {
                  Info.apply.process.reviewAudit.map((item, index) => {
                    return (
                      <div>
                        <div>
                          <div>
                            <div>{item.username}</div>
                            <div>{item.username}</div>
                          </div>
                          <div>
                            <div>
                              <div>职位:</div>
                              <div>{item.positionName}</div>
                            </div>
                            <div>
                              <div>审批状态:</div>
                              {
                                item.review_status === '未审核' ? <div className='icon_02'>审核中</div> : item.review_status === '已驳回' ? <div className='icon_03'>已驳回</div> : item.review_status === '已终止' ? <div className='icon_04'>已终止</div> : <div className='icon_01'>已同意</div>
                              }
                            </div>
                            <div>
                              <div>查看时间:</div>
                              <div>{item.read_time}</div>
                            </div>
                            <div>
                              <div>处理时间:</div>
                              <div>{item.review_time}</div>
                            </div>
                            <div>
                              <div>处理意见:</div>
                              <div>{item.opinion}</div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <img src={Point} alt=""></img>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div> : ''
          }

          {
            /* 抄送 */

            Info.apply.process.ccReading && Info.apply.process.ccReading.length ? <div className='copy'>
              <div>抄送</div>
              <div>
                {
                  Info.apply.process.ccReading.map((item, index) => {
                    return (<div key={index}>
                      <div>{item.username}</div>
                      <div>
                        {
                          item.is_read ? <div className='read'></div> : <div className='unread'></div>
                        }
                        <div>查看时间: {item.read_time || '/'}</div>
                      </div>
                    </div>)
                  })
                }
              </div>
            </div> : ''
          }

          {
            /* 状态 */
            Info.apply.status && Info.apply.status !== 1 ? <div>
              {
                Info.apply.status === 4 ? <div className='complete'>
                  <img src={Complete} alt=""></img>
                  <div>已完成</div>
                </div> : Info.apply.status === 2 && !Info.apply.process.reviewPermission ? <div className='in_process'>
                  <img src={In_process} alt=""></img>
                  <div>处理中</div>
                </div> : Info.apply.status === 2 && Info.apply.process.reviewPermission ? <div className='dispose'>
                  <div onClick={() => this.handleIdea(4)}>同意</div>
                  <div>
                    <div onClick={() => this.handleIdea(3)}>驳回</div>
                    <div onClick={() => this.handleIdea(5)}>终止</div>
                  </div>
                </div> : ''
              }
            </div> : ''
          }
        </Form>

        <Popup
          visible={ideaShow}
          showCloseButton
          onMaskClick={() => this.closeIdea()}
          onClose={() => this.closeIdea()}
          bodyStyle={{
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
            backgroundColor: '#FFFFFF'
          }}
        >
          <div className='opinion'>
            {
              ideaType === 4 ? <div>处理意见</div> : ideaType === 3 ? <div>驳回意见 <span>*</span></div> : <div>终止意见 <span>*</span></div>
            }
            <TextArea onChange={(e) => this.textChange(e)} />
            <div onClick={() => this.saveIdea()}>确定</div>
          </div>
        </Popup>

        <Popup
          visible={appointShow}
          destroyOnClose
          bodyStyle={{
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
            backgroundColor: '#FFFFFF'
          }}
        >
          <div className='popup_select'>
            <div>指定下级审核人员</div>
            <div style={{ display: 'none' }}>
              <SearchBar
                placeholder='输入姓名查询人员'
                onChange={(e) => this.SearchText(e)}
              />
            </div>
            <div>
              <CheckList onChange={(e) => this.checkChange(e)}>
                {
                  selectList.map(item => (
                    <CheckList.Item key={item.value} value={item.value}>
                      {item.label}
                    </CheckList.Item>
                  ))
                }
              </CheckList>
            </div>
            <Button block type='submit' color='primary' size='large' onClick={() => this.setSelected()}>
              确定
            </Button>
          </div>
        </Popup >


      </div>
    )
  }
}
