import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { ResTemporaryDetail } from "../../../utils/public"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_54.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '临时用工付款申请表',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '用工类别',
                        filedName: 'class',
                        filedType: '单选',
                        placeholder: '请选择',
                        disabled: true,
                        required: true,
                        message: '',
                        value: [],
                        radioList: [
                            { value: 1, label: "项目类" },
                            { value: 2, label: "科研类" },
                        ]
                    },
                    {
                        filedLabel: '统计月份',
                        filedName: 'month',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: [],
                        radioList: [
                            { value: 1, label: "1月" },
                            { value: 2, label: "2月" },
                            { value: 3, label: "3月" },
                            { value: 4, label: "4月" },
                            { value: 5, label: "5月" },
                            { value: 6, label: "6月" },
                            { value: 7, label: "7月" },
                            { value: 8, label: "8月" },
                            { value: 9, label: "9月" },
                            { value: 10, label: "10月" },
                            { value: 11, label: "11月" },
                            { value: 12, label: "12月" }
                        ]
                    },
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '苗圃名称',
                        filedName: 'plant_type',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                ],
                ListInfo: [
                    {
                        plateName: '工资明细',
                        unWind: false,
                        dataSource: []
                    },
                ]
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_ResTemporaryDetail(tableId)
        }
    }

    get_ResTemporaryDetail = (tableId) => {
        const { Info } = this.state;
        let data = {
            table_id: tableId
        }
        ResTemporaryDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.status === 2 || res.data.status === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '附件') {
                                if (!res.data.enclosure || !res.data.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.status
                    Info.apply.userName = res.data.applyUsername
                    Info.apply.deName = res.data.applyDeName
                    Info.apply.apply_date = res.data.apply_day
                    Info.apply.process = res.data.reviewAudit
                    Info.apply.process.editPermission = res.data.editPermission

                    Info.ListInfo[0].dataSource = []
                    let details = JSON.parse(res.data.content)
                    details.forEach((item, index) => {
                        let key = index + 1
                        let dataSource = {
                            key: key,
                            list: [
                                {
                                    filedLabel: '姓名',
                                    filedName: 'username_' + key,
                                    filedType: '文本',
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.username,
                                },
                                {
                                    filedLabel: '实发工资',
                                    filedName: 'wage_' + key,
                                    filedType: '文本',
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.wage,
                                },
                            ]
                        }
                        Info.ListInfo[0].dataSource = [...Info.ListInfo[0].dataSource, dataSource]
                    })

                    if (res.data.class === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '项目名称') {
                                item.unShow = false
                                item.value = res.data.pro_name
                            }
                        })
                    } else if (res.data.class === 2) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '苗圃名称') {
                                item.unShow = false
                                item.value = res.data.nurName
                            }
                        })
                    }


                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '用工类别') {
                            item.value = []
                            item.value = [...item.value, res.data.class]
                        } else if (item.filedLabel === '统计月份') {
                            item.value = []
                            item.value = [...item.value, res.data.month]
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.enclosure
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleWind = (name) => {
        let { Info } = this.state
        Info.ListInfo.forEach((item, index) => {
            if (item.plateName === name) {
                item.unWind = !item.unWind
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='临时用工付款申请表(358工程)' />

                <AddConfig key={FormKey} Info={Info} windEvent={this.handleWind} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
