import React, { Component } from 'react'
import { Selector } from 'antd-mobile'

export default class UserSelector extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        let { disabled, value, selectList, onChange } = this.props
        return (
            <Selector
                options={selectList}
                defaultValue={value}
                multiple
                disabled={disabled}
                onChange={(arr, extend) => onChange(extend)}
            />
        )
    }
}
