import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { RightOutline } from 'antd-mobile-icons'
import { indexData, approvalProcess } from "../../utils/api"
import workList from "./Work"
import projectList from "./Project"
import storage from '../../utils/localStorageTime'
import BackImg from "../../accets/img/backlog.png"
import FlowImg from "../../accets/img/flow_read.png"
import WeeklyImg from "../../accets/img/weekly_read.png"
import Icon from "../../accets/img/icon_19.png"

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isBacklog: false,
      processList: [],
      promptInfo: {
        flowsCcIsRead: false,
        flowsCcNumber: 0,
        flowsIsRead: false,
        flowsNumber: 0,
        weeklyIsRead: false,
        weeklyNumber: 0,
        ConstructionIsRead: false,
        ConstructionNumber: 0
      }
    }
  }

  componentDidMount() {
    this.get_userInfo()
  }

  get_userInfo = () => {
    let { isBacklog } = this.state
    if (storage.getItem('permission')) {
      let permission = storage.getItem('permission')
      permission.forEach((item, index) => {
        if (item === 'eng_personnel') {
          isBacklog = true
        }
      })
    }
    this.setState(preState => ({
      isBacklog
    }), () => {
      this.get_indexData()
      this.get_myProcess()
    })
  }

  get_indexData = () => {
    indexData().then(
      (res) => {
        if (!res.code) {
          this.setState(preState => ({
            promptInfo: res.data
          })
          )
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  get_myProcess = () => {
    let { processList } = this.state
    let menuList = [...workList, ...projectList]
    approvalProcess().then(
      (res) => {
        if (!res.code) {
          processList = []
          if (res.data.flows.length) {
            res.data.flows.forEach((item, index) => {
              let value = {
                key: index + 1,
                table_id: item.table_id,
                path: item.deteilUrl,
                title: item.functionName,
              }
              processList = [...processList, value]
            })

            processList.forEach((item, index) => {
              item.icon = Icon
              menuList.forEach((em, ex) => {
                if (item.title === em.title) {
                  item.icon = em.icon
                }
              })
            })
          }

          this.setState(preState => ({
            processList
          })
          )
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  get_prompt = (name) => {
    this.props.history.push(
      {
        pathname: '/prompt',
        state: {
          name: name,
        }
      }
    )
  }

  get_menus = (pathname) => {
    this.props.history.push(pathname)
  }

  render() {
    let { processList, promptInfo, isBacklog } = this.state
    return (
      <div className='page'>

        <div className='prompt'>
          <div onClick={() => this.get_prompt('流程待办')}>
            <div>
              <div>流程待办</div>
              <div>{promptInfo.flowsNumber}</div>
            </div>
            <img src={BackImg} alt=""></img>
          </div>

          {
            !isBacklog ? <div onClick={() => this.get_prompt('流程待阅')}>
              <div>
                <div>流程待阅</div>
                <div>{promptInfo.flowsCcNumber}</div>
              </div>
              <img src={FlowImg} alt=""></img>
            </div> : <div onClick={() => this.get_prompt('施工日志')}>
              <div>
                <div>施工日志</div>
                <div>{promptInfo.ConstructionNumber}</div>
              </div>
              <img src={FlowImg} alt=""></img>
            </div>
          }

          <div onClick={() => this.get_prompt('周报待阅')}>
            <div>
              <div>周报待阅</div>
              <div>{promptInfo.weeklyNumber}</div>
            </div>
            <img src={WeeklyImg} alt=""></img>
          </div>
        </div>

        <div className='my_process'>
          <div>
            <div>我的流程</div>
            <div onClick={() => this.get_menus('process')}>
              <div>查看更多</div>
              <RightOutline color='#3396FB' />
            </div>
          </div>
          <div>
            {
              processList.length ? processList.map((item, index) => {
                if (index < 2) {
                  return (
                    <div key={index}>
                      <img src={item.icon} alt=""></img>
                      <div>我的{item.title}。</div>
                    </div>
                  )
                }
              }) : <div>暂无流程···</div>
            }
          </div>
        </div>

        <div className='content'>
          <div>OA办公</div>
          <div>
            {
              workList.map((item, index) => {
                return (<div key={index} onClick={() => this.get_menus(item.path)}>
                  <div>
                    <img src={item.icon} alt=""></img>
                    {
                      item.isOften ? <div>常用</div> : ''
                    }
                  </div>
                  <div>{item.title}</div>
                </div>)
              })
            }
          </div>
        </div>

        <div className='content'>
          <div>工程项目</div>
          <div>
            {
              projectList.map((item, index) => {
                return (<div key={index} onClick={() => this.get_menus(item.path)}>
                  <div>
                    <img src={item.icon} alt=""></img>
                    {
                      item.isOften ? <div>常用</div> : ''
                    }
                  </div>
                  <div>{item.title}</div>
                </div>)
              })
            }
          </div>
        </div>

      </div>
    )
  }
}

export default withRouter(Home)
