import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { SettlementApprovalDetail } from "../../../utils/project"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_25.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '结算审批',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [{
                    filedLabel: '进度结算类别',
                    filedName: 'class',
                    filedType: '单选',
                    visible: false,
                    placeholder: '',
                    disabled: true,
                    required: false,
                    message: '',
                    value: [],
                    radioList: [
                        { value: 1, label: "材料" },
                        { value: 2, label: "劳务" },
                        { value: 3, label: "机械" },
                        { value: 7, label: "零星材料" },
                        { value: 8, label: "双包" },
                    ]
                },
                {
                    filedLabel: '是否内部',
                    filedName: 'is_inside',
                    filedType: '单选',
                    visible: false,
                    placeholder: '',
                    disabled: true,
                    required: false,
                    message: '',
                    value: [],
                    radioList: [
                        {
                            label: '是',
                            value: 1,
                        }, {
                            label: '否',
                            value: 2,
                        }
                    ]
                },
                {
                    filedLabel: '是否盖章',
                    filedName: 'is_seal',
                    filedType: '单选',
                    visible: false,
                    placeholder: '',
                    disabled: true,
                    required: false,
                    message: '',
                    value: [],
                    radioList: [
                        {
                            label: '是',
                            value: 1,
                        }, {
                            label: '否',
                            value: 2,
                        }
                    ]
                },
                {
                    filedLabel: '印章名称',
                    filedName: 'seal_name',
                    filedType: '选择',
                    placeholder: '请选择',
                    disabled: true,
                    required: false,
                    message: '',
                    value: '',
                    unShow: true
                },
                {
                    filedLabel: '合同名称',
                    filedName: 'con_name',
                    filedType: '文本',
                    nodeType: 'text',
                    placeholder: '',
                    disabled: true,
                    required: false,
                    message: '',
                    value: '',
                    unShow: true
                },
                {
                    filedLabel: '合同编号',
                    filedName: 'con_number',
                    filedType: '文本',
                    nodeType: 'text',
                    placeholder: '',
                    disabled: true,
                    required: false,
                    message: '',
                    value: '',
                    unShow: true
                },
                {
                    filedLabel: '合同总金额(元)',
                    filedName: 'con_money',
                    filedType: '文本',
                    nodeType: 'text',
                    placeholder: '',
                    disabled: true,
                    required: false,
                    message: '',
                    value: '',
                    unShow: true
                },
                {
                    filedLabel: '项目名称',
                    filedName: 'pro_name',
                    filedType: '选择',
                    placeholder: '请选择',
                    disabled: true,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '项目编号',
                    filedName: 'pro_number',
                    filedType: '文本',
                    nodeType: 'text',
                    placeholder: '',
                    disabled: true,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '项目经理',
                    filedName: 'pro_manager',
                    filedType: '文本',
                    nodeType: 'text',
                    placeholder: '',
                    disabled: true,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '供货单位',
                    filedName: 'sup_name',
                    filedType: '文本',
                    nodeType: 'text',
                    placeholder: '',
                    disabled: true,
                    required: false,
                    message: '',
                    value: '',
                }, {
                    filedLabel: '前期进度计量金额(元)',
                    filedName: 'month_over_output',
                    filedType: '文本',
                    nodeType: 'text',
                    placeholder: '',
                    disabled: true,
                    required: false,
                    message: '',
                    value: '',
                }, {
                    filedLabel: '合同结算金额(元)',
                    filedName: 'now_month_output',
                    filedType: '文本',
                    nodeType: 'text',
                    placeholder: '',
                    disabled: true,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '备注',
                    filedName: 'info',
                    filedType: '文本域',
                    placeholder: '',
                    disabled: true,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '附件',
                    filedName: 'enclosure',
                    filedType: '附件',
                    placeholder: '',
                    disabled: true,
                    required: false,
                    message: '',
                    value: [],
                },
                ]
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_SettlementApprovalDetail(tableId)
        }
    }

    get_SettlementApprovalDetail = (tableId) => {
        const { Info } = this.state;
        let data = {
            table_id: tableId
        }
        SettlementApprovalDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 2 || res.data.Detail.status === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '附件') {
                                if (!res.data.Detail.enclosure || !res.data.Detail.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUserName
                    Info.apply.deName = res.data.Detail.applyUserDeName
                    Info.apply.apply_date = res.data.Detail.apply_date
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    if (res.data.Detail.is_seal === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '印章名称') {
                                item.unShow = false
                                item.value = res.data.Detail.sealNames
                            }
                        })
                    }

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '进度结算类别') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.class]
                        } else if (item.filedLabel === '是否内部') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.is_inside]
                        } else if (item.filedLabel === '是否盖章') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.is_seal]
                        } else if (item.filedLabel === '合同名称') {
                            item.value = res.data.Detail.con_name
                        } else if (item.filedLabel === '合同编号') {
                            item.value = res.data.Detail.con_number
                        } else if (item.filedLabel === '合同总金额(元)') {
                            item.value = res.data.Detail.con_money
                        } else if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.pro_name
                        } else if (item.filedLabel === '项目编号') {
                            item.value = res.data.Detail.pro_number
                        } else if (item.filedLabel === '项目经理') {
                            item.value = res.data.Detail.proManagerName
                        } else if (item.filedLabel === '供货单位') {
                            item.value = res.data.Detail.sup_name
                        } else if (item.filedLabel === '前期进度计量金额(元)') {
                            item.value = res.data.Detail.acc_pay
                        } else if (item.filedLabel === '合同结算金额(元)') {
                            item.value = res.data.Detail.con_money
                        } else if (item.filedLabel === '备注') {
                            item.value = res.data.Detail.info
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.Detail.enclosure || []
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='结算审批' />

                <AddConfig key={FormKey} Info={Info} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
