import React, { Component } from 'react'
import { HashRouter, Switch, Route } from "react-router-dom";
import { Toast } from 'antd-mobile'
import { createHtmlAuth, weComLogin, userInfo } from './utils/api'
import storage from './utils/localStorageTime'

import Home from './views/home/Home'
import Prompt from './views/prompt/Prompt'
import Process from './views/home/Process'

import WeeklyReportList from './views/work/gongzuozhoubao/List'
import WeeklyReportNewItem from './views/work/gongzuozhoubao/NewItem'

import ReissueCardList from './views/work/bukashenqing/List'
import ReissueCardNewItem from './views/work/bukashenqing/NewItem'

import AttendanceList from './views/work/chuqinqiandao/List'
import AttendanceNewItem from './views/work/chuqinqiandao/NewItem'

import AskForLeaveList from './views/work/qingjiashenqing/List'
import AskForLeaveNewItem from './views/work/qingjiashenqing/NewItem'

import EvectionList from './views/work/chuchaishenqing/List'
import EvectionNewItem from './views/work/chuchaishenqing/NewItem'

import SendList from './views/work/paiqianshenqingbiao/List'
import SendNewItem from './views/work/paiqianshenqingbiao/NewItem'

import ReimbursementList from './views/work/feiyongbaoxiaodan/List'
import ReimbursementNewItem from './views/work/feiyongbaoxiaodan/NewItem'

import ComprehensiveList from './views/work/zonghekeyanfeiyongfukuan/List'
import ComprehensiveNewItem from './views/work/zonghekeyanfeiyongfukuan/NewItem'

import EngineeringList from './views/work/gongchengkuanzhifushenqing/List'
import EngineeringNewItem from './views/work/gongchengkuanzhifushenqing/NewItem'

import BorrowingList from './views/work/yuangongjiekuan/List'
import BorrowingNewItem from './views/work/yuangongjiekuan/NewItem'

import SealList from './views/work/yongzhangshenqing/List'
import SealNewItem from './views/work/yongzhangshenqing/NewItem'

import BusinessList from './views/work/yewuzhaodaishenqing/List'
import BusinessNewItem from './views/work/yewuzhaodaishenqing/NewItem'
import ContractsNewItem from './views/work/gongsihetongshenpi/NewItem'
import OnboardingNewItem from './views/work/ruzhibanli/NewItem'
import PayrollNewItem from './views/work/linshiyonggonggongzibiao/NewItem'
import SealengravingNewItem from './views/work/yinzhangkezhishenqing/NewItem'
import SealcancellationNewItem from './views/work/yinzhangzhuxiao/NewItem'
import AdministrativeequipmentNewItem from './views/work/xingzhengshebeigouzhishenqing/NewItem'
import OfficesuppliesrequestNewItem from './views/work/bangongyongpinshenqing/NewItem'
import NoticeannouncementNewItem from './views/work/fawenguanli/NewItem'
import EmploymentapplicationNewItem from './views/work/yonggongshenqing/NewItem'
import TemporaryworkerNewItem from './views/work/linshiyonggongshenqing/NewItem'
import DischargeprocedureNewItem from './views/work/lizhibanli/NewItem'
import VehicledispatchNewItem from './views/work/cheliangpaiqian/NewItem'
import shigongzuzhishejiNewItem from './views/work/shigongzuzhishejishenpi/NewItem'
import PatentRenewalNewItem from './views/work/zhuanlixufeifukuanshenpi/NewItem'

import TenderNewItem from './views/public/toubiaojueceshenpibiao/NewItem'
import WinthebidNewItem from './views/public/xiangmuzhongbiaolixiang/NewItem'
import ContractNewItem from './views/public/shouruhetongshenpi/NewItem'
import SecuritydepositNewItem from './views/public/toubiaobaozhengjinzhifushenqing/NewItem'
import AcceptanceofpriceNewItem from './views/public/gongyingshangrenjiashenpi/NewItem'
import DesigntaskNewItem from './views/public/shejixiangmurenwushenqing/NewItem'
import BudgetitemNewItem from './views/public/gaisuanxiangmurenwushenqing/NewItem'
import QualitysupplierNewItem from './views/public/youzhigongyingshangruku/NewItem'
import DocumenttransferNewItem from './views/public/shejiwenjianyijiaojijinduhuibao/NewItem'
import priceAdjustmentNewItem from './views/public/cailiaotiaojiashenpi/NewItem'
import allotNewItem from './views/public/diaoboshenqing/NewItem'

import ManmachinecontractNewItem from './views/project/rencaijihetongshenpi/NewItem'
import ManmachinepaymentNewItem from './views/project/rencaijifukuanshenpi/NewItem'
import ManmachinefeedsheetNewItem from './views/project/rencaijixialiaodan/NewItem'
import ProgresssettlementNewItem from './views/project/jindujiliangshenpi/NewItem'
import SettlementapprovalNewItem from './views/project/jiesuanshenpi/NewItem'
import ReceivegoodsNewItem from './views/project/shouhuoguanli/NewItem'
import ApprovalbymailNewItem from './views/project/hanjianshenpi/NewItem'
import MakeoutaninvoiceNewItem from './views/project/yezhukaipiaoshenqing/NewItem'
import NotificationfileNewItem from './views/project/tongzhiwenjianshenpi/NewItem'
import ProjectresponsibilityletterNewItem from './views/project/huoquxiangmuzerenshuxinxi/NewItem'
import IncomingmessagesNewItem from './views/project/shouwenguanli/NewItem'
import ProjectresponsibilityreviewNewItem from './views/project/xiangmuzerenshupingsheng/NewItem'
import ProjectauxiliarymaterialsNewItem from './views/project/xiangmufuzhuyongcaijiqitashenq/NewItem'
import CooperativeprojectpaymentNewItem from './views/project/hezuoxiangmufukuanshenqing/NewItem'
import DatatransferNewItem from './views/project/ziliaoyijiaodan/NewItem'

import ConstructionlogList from './views/project/shigongrizhi/List'
import ConstructionlogNewItem from './views/project/shigongrizhi/NewItem'

import MachineryList from './views/project/jixietaibanjilubiao/List'
import MachineryNewItem from './views/project/jixietaibanjilubiao/NewItem'

import LaborList from './views/project/lingxingyonggong/List'
import LaborNewItem from './views/project/lingxingyonggong/NewItem'


import DataUploadList from './views/project/gongchengguochengziliao/List'
import DataUploadNewItem from './views/project/gongchengguochengziliao/NewItem'


import SciencetechnologyprojectNewItemItem from './views/public/keyanxiangmushenbao/NewItem'
import ResearchdevelopmentprojectNewItem from './views/public/yanfaxiangmu/NewItem'
import InteriminspectionNewItem from './views/public/zhongqijianchabaogao/NewItem'
import FinalacceptanceNewItem from './views/public/jietiyanshoushenqingbiao/NewItem'
import DeferredconclusionNewItem from './views/public/yanqijietishenqing/NewItem'
import ProjectinitiationNewItem from './views/public/chengguozhuanhuaxiangmulixiang/NewItem'
import TemporaryWorkerNewItem from './views/public/linshiyonggongfukuanshenqing/NewItem'
import NurserysettlementNewItem from './views/public/chengguozhuanhuajiesuanshenpi/NewItem'
import ProgresssettlementsNewItem from './views/public/chengguozhuanhuajindujiesuan/NewItem'
import ProcontractapprovalNewItem from './views/public/chengguozhuanhuaxiangmuhetongshenpi/NewItem'
import ContractapprovalNewItem from './views/public/chengguozhuanhuahetongshenpi/NewItem'
import PaymentapprovalNewItem from './views/public/chengguozhuanhuafukuanshenpi/NewItem'
import IdentityNewItem from './views/public/renzhihejiashenpi/NewItem'
import DeliveryStorageNewItem from './views/public/chukuguanli/NewItem'
import ProgressPaymentNewItem from './views/public/gongchengjindukuanshenpi/NewItem'
import TemporaryPipeNewItem from './views/public/gongzuotongzhizanguan/NewItem'
import TurnoverHandoverNewItem from './views/public/lizhijiaojie/NewItem'



var location = window.location

export default class App extends Component {

  componentDidMount() {
    if (process.env.NODE_ENV === 'development') {
      // let userInfo = {
      //   name: '杨昆',
      //   deName: '风景园林BIM研究中心',
      //   userId: '022459e891ad11ed851e000c2972848c'
      // }
      // console.log(userInfo)
      // storage.setItem({
      //   name: "userInfo",
      //   value: userInfo
      // })
      // let storageUserInfo = storage.getItem('userInfo')
      // console.log(storageUserInfo)

      // storage.setItem({
      //   name: "permission",
      //   value: [
      //     // 'agreeQuitTime',
      //     'eng_personnel'
      //   ]
      // })
      // storage.setItem({
      //   name: "Token",
      //   value: '111e5620f8e8fe605f98d115a22ff3e9'
      // })
    }
    if (!this.getlocalStorageInfo()) {
      if (!this.getURLString('code') && !this.getURLString('item')) {
        this.get_createHtmlAuth()
      } else {
        this.get_weComLogin()
      }
    }
  }

  get_createHtmlAuth = () => {
    createHtmlAuth().then(
      (res) => {
        if (!res.code) {
          location.href = res.data.url
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getURLString(arg) { //获取code
    var reg = new RegExp("(^|&)" + arg + "=([^&]*)(&|$)", "i");
    var code = window.location.search.substr(1).match(reg);
    if (code != null)
      return unescape(code[2]);
    return null;
  }

  getlocalStorageInfo() { //判断用户登录信息
    let Token = storage.getItem('Token');
    let userInfo = storage.getItem('userInfo');
    if (Token && userInfo) {
      return true;
    } else {
      return false;
    }
  }

  get_weComLogin = () => {
    let data = {
      code: this.getURLString('code')
    }
    weComLogin(data).then(
      (res) => {
        if (!res.code) {
          console.log(res.data)
          storage.setItem({
            name: "Token",
            value: res.data.Token
          })
          storage.setItem({
            name: "permission",
            value: res.data.userInfo.permission
          })
          let userInfo = {
            userId: res.data.userInfo.table_id,
            name: res.data.userInfo.name,
            deName: res.data.userInfo.deName,
          }
          storage.setItem({
            name: "userInfo",
            value: userInfo
          })
          location.href = '/'
          // userInfo().then(
          //   (response) => {
          //     if (!response.code) {
          //       let userInfo = {
          //         name: response.data.name,
          //         deName: response.data.deName,
          //         userId: response.data.table_id,
          //       }
          //       storage.setItem({
          //         name: "userInfo",
          //         value: userInfo
          //       })
          //       location.href = '/'
          //     }
          //   },
          //   (error) => {
          //     console.log("get response failed!");
          //   }
          // );
        } else {
          Toast.show({
            icon: 'fail',
            content: res.data[0] || '登录失败',
          })
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact={true} path="/" component={Home}></Route>
          <Route path="/prompt" component={Prompt}></Route>
          <Route path="/process" component={Process}></Route>
          <Route path="/public/human/weekly/List" component={WeeklyReportList}></Route>
          <Route path="/public/human/weekly/NewItem" component={WeeklyReportNewItem}></Route>
          <Route path="/public/human/reissueCard/List" component={ReissueCardList}></Route>
          <Route path="/public/human/reissueCard/NewItem" component={ReissueCardNewItem}></Route>
          <Route path="/public/human/attendance/List" component={AttendanceList}></Route>
          <Route path="/public/human/attendance/NewItem" component={AttendanceNewItem}></Route>
          <Route path="/public/human/leave/List" component={AskForLeaveList}></Route>
          <Route path="/public/human/leave/NewItem" component={AskForLeaveNewItem}></Route>
          <Route path="/public/human/evection/List" component={EvectionList}></Route>
          <Route path="/public/human/evection/NewItem" component={EvectionNewItem}></Route>
          <Route path="/public/human/send/List" component={SendList}></Route>
          <Route path="/public/human/send/NewItem" component={SendNewItem}></Route>

          <Route path="/public/payment/reimbursement/List" component={ReimbursementList}></Route>
          <Route path="/public/payment/reimbursement/NewItem" component={ReimbursementNewItem}></Route>
          <Route path="/public/payment/comprehensive/List" component={ComprehensiveList}></Route>
          <Route path="/public/payment/comprehensive/NewItem" component={ComprehensiveNewItem}></Route>
          <Route path="/public/payment/engineering/List" component={EngineeringList}></Route>
          <Route path="/public/payment/engineering/NewItem" component={EngineeringNewItem}></Route>
          <Route path="/public/payment/borrowing/List" component={BorrowingList}></Route>
          <Route path="/public/payment/borrowing/NewItem" component={BorrowingNewItem}></Route>
          <Route path="/public/construction/NewItem" component={shigongzuzhishejiNewItem}></Route>
          <Route path="/public/payment/patentRenewal/NewItem" component={PatentRenewalNewItem}></Route>
          

          <Route path="/public/office/chapter/List" component={SealList}></Route>
          <Route path="/public/office/chapter/NewItem" component={SealNewItem}></Route>
          <Route path="/public/office/business/List" component={BusinessList}></Route>
          <Route path="/public/office/business/NewItem" component={BusinessNewItem}></Route>
          <Route path="/public/contract/NewItem" component={ContractsNewItem}></Route>
          <Route path="/administrative/human/induction/NewItem" component={OnboardingNewItem}></Route>
          <Route path="/administrative/human/payroll/NewItem" component={PayrollNewItem}></Route>
          <Route path="/public/office/seal/NewItem" component={SealengravingNewItem}></Route>
          <Route path="/administrative/seal/cancellation/NewItem" component={SealcancellationNewItem}></Route>
          <Route path="/public/office/purchase/NewItem" component={AdministrativeequipmentNewItem}></Route>   
          <Route path="/public/office/stationery/NewItem" component={OfficesuppliesrequestNewItem}></Route>  
          <Route path="/administrative/notice/NewItem" component={NoticeannouncementNewItem}></Route>   
          <Route path="/public/human/labor/NewItem" component={EmploymentapplicationNewItem}></Route>  
          <Route path="/public/human/temporaryWork/NewItem" component={TemporaryworkerNewItem}></Route>  
          <Route path="/public/human/departure/NewItem" component={DischargeprocedureNewItem}></Route>  
          <Route path="/public/office/vehicle/NewItem" component={VehicledispatchNewItem}></Route>  
          

          <Route path="/market/application/NewItem" component={TenderNewItem}></Route>
          <Route path="/market/winning/NewItem" component={WinthebidNewItem}></Route>
          <Route path="/market/contract/NewItem" component={ContractNewItem}></Route>
          <Route path="/market/margin/NewItem" component={SecuritydepositNewItem}></Route>
          <Route path="/market/designScheme/NewItem" component={DesigntaskNewItem}></Route>
          <Route path="/market/approximateSum/NewItem" component={BudgetitemNewItem}></Route>
          <Route path="/purchase/supplier/audit/NewItem" component={AcceptanceofpriceNewItem}></Route>
          <Route path="/purchase/supplier/storage/NewItem" component={QualitysupplierNewItem}></Route>
          <Route path="/purchase/priceAdjustment/NewItem" component={priceAdjustmentNewItem}></Route>
          <Route path="/purchase/warehouse/fixedAssets/machine/allot/NewItem" component={allotNewItem}></Route>
          <Route path="/design/documentTransfer/NewItem" component={DocumenttransferNewItem}></Route>       
          
          <Route path="/project/contract/NewItem" component={ManmachinecontractNewItem}></Route>
          <Route path="/project/payment/NewItem" component={ManmachinepaymentNewItem}></Route>
          <Route path="/project/blanking/NewItem" component={ManmachinefeedsheetNewItem}></Route>
          <Route path="/project/settlement/NewItem" component={ProgresssettlementNewItem}></Route>
          <Route path="/project/settlements/NewItem" component={SettlementapprovalNewItem}></Route>
          <Route path="/project/goods/NewItem" component={ReceivegoodsNewItem}></Route>
          <Route path="/project/letter/NewItem" component={ApprovalbymailNewItem}></Route>
          <Route path="/project/invoice/NewItem" component={MakeoutaninvoiceNewItem}></Route>
          <Route path="/project/notice/NewItem" component={NotificationfileNewItem}></Route>
          <Route path="/project/responsibilityInformation/NewItem" component={ProjectresponsibilityletterNewItem}></Route>
          <Route path="/project/receive/NewItem" component={IncomingmessagesNewItem}></Route>
          <Route path="/project/responsibilityReview/NewItem" component={ProjectresponsibilityreviewNewItem}></Route>
          <Route path="/project/spMaterial/NewItem" component={ProjectauxiliarymaterialsNewItem}></Route>
          <Route path="/project/projectPayment/NewItem" component={CooperativeprojectpaymentNewItem}></Route>
          <Route path="/project/handoverOrder/NewItem" component={DatatransferNewItem}></Route>
          <Route path="/project/build/List" component={ConstructionlogList}></Route>
          <Route path="/project/build/NewItem" component={ConstructionlogNewItem}></Route>
          <Route path="/project/machinery/List" component={MachineryList}></Route>
          <Route path="/project/machinery/NewItem" component={MachineryNewItem}></Route>
          <Route path="/project/Labor/List" component={LaborList}></Route>
          <Route path="/project/Labor/NewItem" component={LaborNewItem}></Route>
          <Route path="/project/dataUpload/List" component={DataUploadList}></Route>
          <Route path="/project/dataUpload/NewItem" component={DataUploadNewItem}></Route>
          <Route path="/scientific_research/project_application/NewItem" component={SciencetechnologyprojectNewItemItem}></Route>
          <Route path="/scientific_research/project/NewItem" component={ResearchdevelopmentprojectNewItem}></Route>
          <Route path="/scientific_research/project/InspectNewItem" component={InteriminspectionNewItem}></Route>
          <Route path="/scientific_research/project/ConcludeNewItem" component={FinalacceptanceNewItem}></Route>
          <Route path="/scientific_research/project/PostponeNewItem" component={DeferredconclusionNewItem}></Route>
          <Route path="/scientific_research/achievement_transformation/projectInitiation/NewItem" component={ProjectinitiationNewItem}></Route>
          <Route path="/scientific_research/achievement_transformation/temporaryWorker/NewItem" component={TemporaryWorkerNewItem}></Route>
          <Route path="/scientific_research/achievement_transformation/nurserySettlement/NewItem" component={NurserysettlementNewItem}></Route>
          <Route path="/scientific_research/achievement_transformation/progressSettlement/NewItem" component={ProgresssettlementsNewItem}></Route>         
          <Route path="/scientific_research/achievement_transformation/proContractApproval/NewItem" component={ProcontractapprovalNewItem}></Route>
          <Route path="/scientific_research/achievement_transformation/contractApproval/NewItem" component={ContractapprovalNewItem}></Route>
          <Route path="/scientific_research/achievement_transformation/paymentApproval/NewItem" component={PaymentapprovalNewItem}></Route> 
          <Route path="/scientific_research/achievement_transformation/identity/NewItem" component={IdentityNewItem}></Route>     
          <Route path="/scientific_research/achievement_transformation/deliveryfromstorage/NewItem" component={DeliveryStorageNewItem}></Route>
          <Route path="/cost/progressPayment/NewItem" component={ProgressPaymentNewItem}></Route>
          <Route path="/public/temporaryPipe/NewItem" component={TemporaryPipeNewItem}></Route>
          <Route path="/administrative/human/turnoverHandover/NewItem" component={TurnoverHandoverNewItem}></Route>
             

        </Switch>
      </HashRouter >
    )
  }
}
