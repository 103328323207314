import Icon_01 from "../../accets/img/icon_14.png"
import Icon_02 from "../../accets/img/icon_15.png"
import Icon_03 from "../../accets/img/icon_18.png"
import Icon_04 from "../../accets/img/icon_19.png"
import Icon_05 from "../../accets/img/icon_16.png"
import Icon_06 from "../../accets/img/icon_20.png"
import Icon_07 from "../../accets/img/icon_17.png"
import Icon_08 from "../../accets/img/icon_58.png"

const work = [
    {
        key: 1,
        title: '施工日志',
        isOften: true,    //是否为常用
        icon: Icon_06,
        path: '/project/build/List'
    },
    //{
    //     key: 2,
    //     title: '人材机下料单',
    //     isOften: false,    //是否为常用
    //     icon: Icon_02
    // }, {
    //     key: 3,
    //     title: '供应商认价审批',
    //     isOften: false,    //是否为常用
    //     icon: Icon_03
    // },
    {
        key: 4,
        title: '机械台班记录表',
        isOften: false,    //是否为常用
        icon: Icon_04,
        path: '/project/machinery/List'
    },
    // {
    //     key: 5,
    //     title: '收货管理',
    //     isOften: false,    //是否为常用
    //     icon: Icon_05
    // }, 
    {
        key: 6,
        title: '零星用工',
        isOften: false,    //是否为常用
        icon: Icon_01,
        path: '/project/labor/List'
    },
    // {
    //     key: 7,
    //     title: '工程机械上下机登记',
    //     isOften: false,    //是否为常用
    //     icon: Icon_07
    // },
    {
        key: 8,
        title: '工程过程资料上传',
        isOften: false,    //是否为常用
        icon: Icon_08,
        path: '/project/dataUpload/List'
    },
]

export default work