import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { getSealAuxiliary, lookApplyContent } from "../../../utils/oa"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_38.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                apply: {
                    title: '印章刻制申请',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '类型',
                        filedName: 'class',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: [],
                        radioList: []
                    },
                    {
                        filedLabel: '印章名称',
                        filedName: 'name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '保管人',
                        filedName: 'save_user',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '负责人',
                        filedName: 'person_user',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '印鉴',
                        filedName: 'stamp',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                    {
                        filedLabel: '启用日期',
                        filedName: 'start_day',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '刻制原因',
                        filedName: 'reason',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '回执',
                        filedName: 'receipt',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                ]
            },
        }
    }

    componentDidMount() {
        this.get_SealAuxiliary()
    }

    get_SealAuxiliary = () => {
        let { Info } = this.state
        getSealAuxiliary().then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '类型') {
                            for (let key in res.data.class) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.class[key]
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        }
                    })
                    this.setState(preState => ({
                        Info
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_lookApplyContent(tableId)
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_lookApplyContent = (tableId) => {
        const { Info, Form } = this.state;
        let data = {
            table_id: tableId
        }
        lookApplyContent(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.sealApply.status === 2 || res.data.sealApply.status === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '印鉴') {
                                if (!res.data.sealApply.stamp.length) {
                                    item.unShow = true
                                }
                            } else if (item.filedLabel === '回执') {
                                if (!res.data.sealApply.receipt.length) {
                                    item.unShow = true
                                }
                            } else if (item.filedLabel === '附件') {
                                if (!res.data.sealApply.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.sealApply.status
                    Info.apply.userName = res.data.sealApply.applyUserName
                    Info.apply.deName = res.data.sealApply.applyUserDeName
                    Info.apply.apply_date = res.data.sealApply.created_at
                    Info.apply.process = res.data.sealApply.reviewAudit
                    Info.apply.process.editPermission = res.data.sealApply.editPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '类型') {
                            item.value = []
                            item.value = [...item.value, res.data.sealApply.class]
                        } else if (item.filedLabel === '印章名称') {
                            item.value = res.data.sealApply.name
                        } else if (item.filedLabel === '保管人') {
                            item.value = res.data.sealApply.save_username
                        } else if (item.filedLabel === '负责人') {
                            item.value = res.data.sealApply.person_username
                        } else if (item.filedLabel === '启用日期') {
                            item.value = res.data.sealApply.start_day ? new Date(res.data.sealApply.start_day) : ''
                        } else if (item.filedLabel === '刻制原因') {
                            item.value = res.data.sealApply.reason
                        } else if (item.filedLabel === '印鉴') {
                            item.value = res.data.sealApply.stamp || []
                        } else if (item.filedLabel === '回执') {
                            item.value = res.data.sealApply.receipt || []
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.sealApply.enclosure || []
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        Form,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='印章刻制申请' />

                <AddConfig key={FormKey} Info={Info} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
