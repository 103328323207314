import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { MaterialPaymentDetail } from "../../../utils/project"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_32.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '人材机付款审批',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '合同名称',
                        filedName: 'con_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '合同编号',
                        filedName: 'con_number',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '合同类别',
                        filedName: 'macType',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目编号',
                        filedName: 'pro_number',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目经理',
                        filedName: 'pro_manager',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '合同总金额(元)',
                        filedName: 'con_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '已累计付款(元)',
                        filedName: 'total_pay_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '剩余未付款(元)',
                        filedName: 'acc_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '本次付款(元)',
                        filedName: 'pay_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '付款说明',
                        filedName: 'pay_info',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '本次开票金额(元)',
                        filedName: 'invo_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '前期已开票金额(元)',
                        filedName: 'acc_invoice',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '分类',
                        filedName: 'class',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                label: '合作',
                                value: 1,
                            }, {
                                label: '自营',
                                value: 2,
                            }
                        ]
                    },
                    {
                        filedLabel: '是否欠票',
                        filedName: 'has_owing',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                label: '是',
                                value: 1,
                            }, {
                                label: '否',
                                value: 2,
                            }
                        ]
                    },
                    {
                        filedLabel: '欠票单位',
                        filedName: 'unit_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '欠票类型',
                        filedName: 'invo_type',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '欠票金额(元)',
                        filedName: 'money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '欠票原因',
                        filedName: 'cause',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '补票时间',
                        filedName: 'replenish_invo',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '付款类别',
                        filedName: 'pay_class',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            { value: 1, label: "预付款" },
                            { value: 2, label: "进度计量" },
                            { value: 3, label: "结算审批" },
                        ]
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'advance',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        unShow: true
                    },
                    {
                        filedLabel: '累计进度计量金额(元)',
                        filedName: 'pro_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '结算金额(元)',
                        filedName: 'set_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '收款账号',
                        filedName: 'bank_number',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '收款户名',
                        filedName: 'accout_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '收款银行',
                        filedName: 'bank_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '联系人',
                        filedName: 'contact',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '联系电话',
                        filedName: 'phone',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '是否内部',
                        filedName: 'is_inside',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            { value: 1, label: "是" },
                            { value: 2, label: "否" },
                        ]
                    },
                    {
                        filedLabel: '外部意见',
                        filedName: 'review_comments',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        unShow: true
                    },
                ]
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_MaterialPaymentDetail(tableId)
        }
    }

    get_MaterialPaymentDetail = (tableId) => {
        const { Info } = this.state;
        let data = {
            table_id: tableId
        }
        MaterialPaymentDetail(data).then(
            (res) => {
                if (!res.code) {
                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUsername
                    Info.apply.deName = res.data.Detail.applyUserDename
                    Info.apply.apply_date = res.data.Detail.apply_date
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    if (res.data.Detail.pay_class === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '附件') {
                                if (res.data.Detail.advance && res.data.Detail.advance.length) {
                                    item.unShow = false
                                    item.value = res.data.Detail.advance || []
                                }
                            }
                        })
                    } else if (res.data.Detail.pay_class === 2) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '累计进度计量金额(元)') {
                                item.unShow = false
                                item.value = res.data.Detail.pro_money
                            }
                        })
                    } else if (res.data.Detail.pay_class === 3) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '结算金额(元)') {
                                item.unShow = false
                                item.value = res.data.Detail.set_money
                            }
                        })
                    }

                    if (res.data.Detail.has_owing === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '欠票单位') {
                                item.value = res.data.Detail.unit_name
                                item.unShow = false
                            } else if (item.filedLabel === '欠票类型') {
                                item.value = res.data.Detail.invo_type
                                item.unShow = false
                            } else if (item.filedLabel === '欠票金额(元)') {
                                item.value = res.data.Detail.money
                                item.unShow = false
                            } else if (item.filedLabel === '欠票原因') {
                                item.value = res.data.Detail.cause
                                item.unShow = false
                            } else if (item.filedLabel === '补票时间') {
                                item.value = res.data.Detail.replenish_invo ? new Date(res.data.Detail.replenish_invo) : ''
                                item.unShow = false
                            }
                        })
                    }

                    if (res.data.Detail.is_inside === 2) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '外部意见') {
                                if (res.data.Detail.review_comments && res.data.Detail.review_comments.length) {
                                    item.unShow = false
                                    item.value = res.data.Detail.review_comments || []
                                }
                            }
                        })
                    }

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '合同名称') {
                            item.value = res.data.Detail.con_name
                        } else if (item.filedLabel === '合同编号') {
                            item.value = res.data.Detail.con_number
                        } else if (item.filedLabel === '合同类别') {
                            item.value = res.data.Detail.mac_type
                        } else if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.proName
                        } else if (item.filedLabel === '项目编号') {
                            item.value = res.data.Detail.pro_number
                        } else if (item.filedLabel === '项目经理') {
                            item.value = res.data.Detail.managerUsername
                        } else if (item.filedLabel === '合同总金额(元)') {
                            item.value = res.data.Detail.con_money
                        } else if (item.filedLabel === '已累计付款(元)') {
                            item.value = res.data.Detail.total_pay_money
                        } else if (item.filedLabel === '剩余未付款(元)') {
                            item.value = res.data.Detail.acc_money
                        } else if (item.filedLabel === '本次付款(元)') {
                            item.value = res.data.Detail.pay_money
                        } else if (item.filedLabel === '付款说明') {
                            item.value = res.data.Detail.pay_info
                        } else if (item.filedLabel === '本次开票金额(元)') {
                            item.value = res.data.Detail.invo_money
                        } else if (item.filedLabel === '前期已开票金额(元)') {
                            item.value = res.data.Detail.acc_invoice
                        } else if (item.filedLabel === '分类') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.class]
                        } else if (item.filedLabel === '是否欠票') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.has_owing]
                        } else if (item.filedLabel === '付款类别') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.pay_class]
                        } else if (item.filedLabel === '收款账号') {
                            item.value = res.data.Detail.bank_number
                        } else if (item.filedLabel === '收款户名') {
                            item.value = res.data.Detail.accout_name
                        } else if (item.filedLabel === '收款银行') {
                            item.value = res.data.Detail.bank_name
                        } else if (item.filedLabel === '联系人') {
                            item.value = res.data.Detail.contact
                        } else if (item.filedLabel === '联系电话') {
                            item.value = res.data.Detail.phone
                        } else if (item.filedLabel === '是否内部') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.is_inside]
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='人材机付款审批' />

                <AddConfig key={FormKey} Info={Info} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
