import http from '../utils/http';

//周报列表
function weeklyList(data) {
    return http("get", '/api/weeklyList', data);
}

//添加工作周报
function createWeekly(data) {
    return http("post", '/api/createWeekly', data);
}

//周报详情
function weeklyDetail(data) {
    return http("get", '/api/weeklyDetail', data);
}

//补卡申请列表
function cardReplacementList(data) {
    return http("get", '/api/cardReplacementList', data);
}

//添加编辑补卡申请
function createCardReplacement(data) {
    return http("post", '/api/createCardReplacement', data);
}

//查看补卡申请详情
function lookCardRellacDetail(data) {
    return http("get", '/api/lookCardRellacDetail', data);
}

//请假申请列表
function userLeaveList(data) {
    return http("get", '/api/userLeaveList', data);
}

//添加编辑请假申请
function createUserLeave(data) {
    return http("post", '/api/createUserLeave', data);
}

//查看请假申请详情
function lookLeaveDetail(data) {
    return http("get", '/api/lookLeaveDetail', data);
}

//出差申请列表
function awayApplyList(data) {
    return http("get", '/api/awayApplyList', data);
}

//添加编辑出差申请
function createAwayApply(data) {
    return http("post", '/api/createAwayApply', data);
}

//查看出差申请详情
function AwayDetail(data) {
    return http("get", '/api/AwayDetail', data);
}

//派遣申请列表
function userDispatchList(data) {
    return http("get", '/api/userDispatchList', data);
}

//添加编辑派遣申请
function createDispatchApply(data) {
    return http("post", '/api/createDispatchApply', data);
}

//查看派遣申请详情
function dispatchDetail(data) {
    return http("get", '/api/dispatchDetail', data);
}

//用章申请列表
function useSealApplyList(data) {
    return http("get", '/api/useSealApplyList', data);
}

//用章申请辅助数据
function useSealApplyAuxiliaryData(data) {
    return http("get", '/api/useSealApplyAuxiliaryData', data);
}

//添加编辑用章申请
function createUseApply(data) {
    return http("post", '/api/createUseApply', data);
}

//查看用章申请详情
function useSealApplyDetail(data) {
    return http("get", '/api/useSealApplyDetail', data);
}

//添加编辑业务招待申请
function createWorkEat(data) {
    return http("post", '/api/createWorkEat', data);
}

//业务招待申请列表
function workEatList(data) {
    return http("get", '/api/workEatList', data);
}

//业务招待申请详情
function workEatDetail(data) {
    return http("get", '/api/workEatDetail', data);
}

//报销审批列表
function userReimbursementList(data) {
    return http("get", '/api/userReimbursementList', data);
}

//报销审批详情
function userReimbursementDetail(data) {
    return http("get", '/api/userReimbursementDetail', data);
}

//添加编辑费用报销审批
function createUserReimbursement(data) {
    return http("post", '/api/createUserReimbursement', data);
}

//综合/科研付款审批列表
function comResearchList(data) {
    return http("get", '/api/comResearchList', data);
}

//综合/科研付款审批详情
function comResearchDetail(data) {
    return http("get", '/api/comResearchDetail', data);
}

//工程款支付审批列表
function projectPaymentList(data) {
    return http("get", '/api/projectPaymentList', data);
}

//工程款支付审批详情
function projectPaymentDetail(data) {
    return http("get", '/api/projectPaymentDetail', data);
}

//添加编辑工程款支付审批
function createProjectPayment(data) {
    return http("post", '/api/createProjectPayment', data);
}


//添加编辑综合/科研付款审批
function createComResearch(data) {
    return http("post", '/api/createComResearch', data);
}

//员工借款列表
function userLoanList(data) {
    return http("get", '/api/userLoanList', data);
}

//借款审批详情
function userLoanDetail(data) {
    return http("get", '/api/userLoanDetail', data);
}

//添加编辑员工借款
function createUserLoan(data) {
    return http("post", '/api/createUserLoan', data);
}

//入职办理详情
function userInformation(data) {
    return http("get", '/api/userInformation', data);
}


//临时用工工资表详情
function partTimeWagesDetails(data) {
    return http("get", '/api/partTimeWagesDetails', data);
}

//印章类型
function getSealAuxiliary(data) {
    return http("get", '/api/getSealAuxiliary', data);
}

//印章刻制申请详情
function lookApplyContent(data) {
    return http("get", '/api/lookApplyContent', data);
}

//印章注销详情
function canSealDetail(data) {
    return http("get", '/api/canSealDetail', data);
}

//行政设备购置申请详情
function equipmentDetail(data) {
    return http("get", '/api/equipmentDetail', data);
}

//办公用品申请详情
function officeSuppliesDetail(data) {
    return http("get", '/api/officeSuppliesDetail', data);
}

//通知公告详情
function noticeDetail(data) {
    return http("get", '/api/noticeDetail', data);
}

//用工申请详情
function employmentApplicationDetail(data) {
    return http("get", '/api/employmentApplicationDetail', data);
}

//临时用工申请详情
function temporaryEmploymentDetail(data) {
    return http("get", '/api/temporaryEmploymentDetail', data);
}

//离职办理详情
function userQuitDetail(data) {
    return http("get", '/api/userQuitDetail', data);
}

//车辆派遣详情
function vehicleDispatchDetail(data) {
    return http("get", '/api/vehicleDispatchDetail', data);
}

//部门
function getCompanyDeName(data) {
    return http("get", '/api/getCompanyDeName', data);
}

//施工组织设计审批详情
function ConstructionOrganizationDetail(data) {
    return http("get", '/api/ConstructionOrganizationDetail', data);
}

//外出签到列表
function userSignList(data) {
    return http("get", '/api/userSignList', data);
}

//管理外出签到列表
function allUserSignList(data) {
    return http("get", '/api/allUserSignList', data);
}


//外出签到
function createUserSign(data) {
    return http("post", '/api/createUserSign', data);
}

export {
    weeklyList,
    createWeekly,
    weeklyDetail,
    cardReplacementList,
    createCardReplacement,
    lookCardRellacDetail,
    userLeaveList,
    createUserLeave,
    lookLeaveDetail,
    awayApplyList,
    createAwayApply,
    AwayDetail,
    userDispatchList,
    createDispatchApply,
    useSealApplyList,
    dispatchDetail,
    useSealApplyAuxiliaryData,
    createUseApply,
    useSealApplyDetail,
    createWorkEat,
    workEatList,
    workEatDetail,
    userReimbursementList,
    userReimbursementDetail,
    createUserReimbursement,
    comResearchList,
    comResearchDetail,
    projectPaymentList,
    projectPaymentDetail,
    createProjectPayment,
    createComResearch,
    userLoanList,
    userLoanDetail,
    createUserLoan,
    userInformation,
    partTimeWagesDetails,
    getSealAuxiliary,
    lookApplyContent,
    canSealDetail,
    equipmentDetail,
    officeSuppliesDetail,
    noticeDetail,
    employmentApplicationDetail,
    temporaryEmploymentDetail,
    userQuitDetail,
    vehicleDispatchDetail,
    getCompanyDeName,
    ConstructionOrganizationDetail,
    userSignList,
    allUserSignList,
    createUserSign
}