import React, { Component } from 'react'
import { TextArea } from 'antd-mobile'

export default class UserTextArea extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        let { placeholder, disabled, value, onChange } = this.props
        return (
            <TextArea
                autoSize={{ minRows: 5, maxRows: 10 }}
                placeholder={placeholder}
                disabled={disabled}
                value={value}
                onChange={(e) => {
                    onChange(e)
                }}
            />
        )
    }
}
