import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import NavBar from "../../components/NavRar"
import Content from "../../components/Content"
import { comResearchList } from "../../../utils/oa"

class List extends Component {
    constructor(props) {
        super(props)
        this.state = {
            status: 2,
            pageNum: 1,
            dataSource: [],
            Loading: false
        }
    }
    componentDidMount() {
        this.get_comResearchList()
    }

    handleTab = (status) => {
        this.setState(preState => ({
            status,
            pageNum: 1,
            dataSource: [],
            Loading: false
        }), () => {
            this.get_comResearchList()
        })
    }

    handleLimit = () => {
        this.get_comResearchList()
    }

    get_comResearchList = () => {
        let { dataSource, pageNum, status, Loading } = this.state
        let data = {
            queryCriteria: 'personal',
            status: status,
            page: pageNum,
            limit: 15
        }
        comResearchList(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.research.length) {
                        res.data.research.forEach((item, index) => {
                            let value = {
                                key: index + 1,
                                table_id: item.table_id,
                                username: item.applyUserName,
                                deName: item.deName,
                                content: [
                                    {
                                        label: '合同名称',
                                        value: item.con_name,
                                    },
                                    {
                                        label: '合同类别',
                                        value: item.con_type,
                                    },
                                    {
                                        label: '合同金额(元)',
                                        value: item.con_money,
                                    },
                                    {
                                        label: '已累计付款(元)',
                                        value: item.paid_money,
                                    },
                                    {
                                        label: '剩余未付款(元)',
                                        value: item.not_money,
                                    },
                                    {
                                        label: '费用类别',
                                        value: item.class === 1 ? '综合费用' : '科研费用',
                                    },
                                    {
                                        label: '备注',
                                        value: item.info,
                                    },
                                ],
                                apply_date: item.apply_date,
                            }

                            dataSource = [...dataSource, value]
                        })

                        if (pageNum < Math.ceil(res.data.pages.total / 15)) {
                            Loading = true
                        }

                        pageNum = pageNum + 1

                        this.setState(preState => ({
                            dataSource,
                            pageNum,
                            Loading
                        })
                        )
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { status, dataSource, Loading } = this.state
        return (
            <div>
                <NavBar menuName='综合/科研费用付款审批' />

                <Content menuName='新增综合/科研费用' pathname='/public/payment/comprehensive/NewItem' status={status} dataSource={dataSource} Loading={Loading} TabEvent={this.handleTab} LimitEvent={this.handleLimit} />
            </div>
        )
    }
}

export default withRouter(List)
