import React, { Component } from 'react'
import { DatePicker, Space } from 'antd-mobile'
import dayjs from 'dayjs'
import Time from "../accets/img/time.png"

export default class UserDatePicker extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }



    render() {
        let { precision, visible, title, name, value, onChange } = this.props

        return (
            <DatePicker
                title='时间选择'
                visible={visible}
                value={value || null}
                precision={precision}
                onClose={() => this.props.pickerEvent(title, name)}
                onConfirm={(e) => onChange(e)}
                renderLabel={(type, data) => {
                    switch (type) {
                        case 'year':
                            return data + '年'
                        case 'month':
                            return data + '月'
                        case 'day':
                            return data + '日'
                        case 'hour':
                            return data + '时'
                        case 'minute':
                            return data + '分'
                        case 'second':
                            return data + '秒'
                        default:
                            return data
                    }
                }
                }
                style={{
                    borderTopLeftRadius: '15px',
                    borderTopRightRadius: '15px',
                    minHeight: '40vh',
                    backgroundColor: '#FFFFFF'
                }}
            >
                {(date) => {
                    return (
                        <Space align='center'>
                            {!date ? '请选择' : precision === 'day' ? dayjs(date).format('YYYY-MM-DD') : dayjs(date).format('YYYY-MM-DD HH:mm:ss')}
                            <img src={Time} alt=""></img>
                        </Space>
                    )
                }}
            </DatePicker>
        )
    }
}
