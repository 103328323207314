import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import dayjs from 'dayjs'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { BlankingSelectProject, createReceiptManagement, receiptManagementDetail } from "../../../utils/project"
import { reviewApplyResult } from "../../../utils/api"
import UserPopup from "../../../components/UserPopup"
import Icon from "../../../accets/img/icon_19.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '机械台班',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '操作人',
                        filedName: 'operation_user',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入操作人',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '记录人',
                        filedName: 'log_user',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入记录人',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                ],
                ListInfo: [
                    {
                        plateName: '台班信息',
                        unWind: false,
                        dataSource: [
                            {
                                key: 1,
                                list: [
                                    {
                                        filedLabel: '机械性质',
                                        filedName: 'mec_pro_1',
                                        filedType: '单选',
                                        visible: false,
                                        placeholder: '',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: [],
                                        radioList: [
                                            {
                                                value: 1,
                                                label: '租赁'
                                            },
                                            {
                                                value: 2,
                                                label: '自有'
                                            }
                                        ]
                                    },
                                    {
                                        filedLabel: '机械名称',
                                        filedName: 'name_1',
                                        filedType: '文本',
                                        placeholder: '请输入机械名称',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '规格型号',
                                        filedName: 'model_1',
                                        filedType: '文本',
                                        placeholder: '请输入规格型号',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '单位',
                                        filedName: 'unit_1',
                                        filedType: '文本',
                                        placeholder: '请输入单位',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '工作内容',
                                        filedName: 'result_1',
                                        filedType: '文本',
                                        placeholder: '工作内容',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '上午开始时间',
                                        filedName: 'morning_start_time_1',
                                        filedType: '日期',
                                        visible: false,
                                        nodeType: 'minute',
                                        placeholder: '',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '上午结束时间',
                                        filedName: 'morning_end_time_1',
                                        filedType: '日期',
                                        visible: false,
                                        nodeType: 'minute',
                                        placeholder: '',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '下午开始时间',
                                        filedName: 'afternoon_start_time_1',
                                        filedType: '日期',
                                        visible: false,
                                        nodeType: 'minute',
                                        placeholder: '',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '下午结束时间',
                                        filedName: 'afternoon_end_time_1',
                                        filedType: '日期',
                                        visible: false,
                                        nodeType: 'minute',
                                        placeholder: '',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '晚上开始时间',
                                        filedName: 'evening_start_time_1',
                                        filedType: '日期',
                                        visible: false,
                                        nodeType: 'minute',
                                        placeholder: '',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '晚上结束时间',
                                        filedName: 'evening_end_time_1',
                                        filedType: '日期',
                                        visible: false,
                                        nodeType: 'minute',
                                        placeholder: '',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '合计工作时间',
                                        filedName: 'total_time_1',
                                        filedType: '文本',
                                        placeholder: '请输入合计工作时间',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '油耗',
                                        filedName: 'oil_con_1',
                                        filedType: '文本',
                                        placeholder: '请输入油耗',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '备注',
                                        filedName: 'info_1',
                                        filedType: '文本',
                                        placeholder: '请输入备注',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                ]
                            }
                        ]
                    },
                ]
            },
            Form: {},
            pro_name: '',
            popup: {
                isShow: false,
                title: '选择项目',
                placeholder: '',
                multiple: false,
                disabled: false,
                dataSource: []
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_receiptManagementDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let { Info } = this.state;
        let userInfo = storage.getItem('userInfo');

        Info.apply.userName = userInfo.name
        Info.apply.deName = userInfo.deName
        Info.apply.apply_date = ''

        this.setState(preState => ({
            Info
        })
        )
    }

    get_receiptManagementDetail = (tableId) => {
        const { Info, Form } = this.state;
        let data = {
            table_id: tableId
        }
        receiptManagementDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        Form.table_id = tableId
                        Form.project_id = res.data.Detail.project_id
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true
                        })

                        Info.ListInfo.forEach((item, index) => {
                            if (item.plateName) {
                                item.dataSource.forEach((em, ex) => {
                                    em.list.forEach((m, x) => {
                                        m.disabled = true
                                    })
                                })
                            }
                        })

                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUsername
                    Info.apply.deName = res.data.Detail.deName
                    Info.apply.apply_date = res.data.Detail.delivery_date
                    if (res.data.Detail.reviewAudit) {
                        res.data.Detail.reviewAudit.ccReading.forEach((item, index) => {
                            item.username = item.userName
                        })
                    }
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.pro_name
                        } else if (item.filedLabel === '操作人') {
                            item.value = res.data.Detail.operation_user
                        } else if (item.filedLabel === '记录人') {
                            item.value = res.data.Detail.log_user
                        }
                    })

                    if (res.data.Detail.content.length) {
                        let dataSource = res.data.Detail.content
                        Info.ListInfo[0].dataSource = []
                        dataSource.forEach((item, index) => {
                            let key = index + 1
                            item.give_number = JSON.parse(item.give_number)
                            item.pua_number = JSON.parse(item.pua_number)
                            item.keep_alive = JSON.parse(item.keep_alive)
                            let morning_start_time = item.give_number[0] ? new Date(item.give_number[0]) : ''
                            let morning_end_time = item.give_number[1] ? new Date(item.give_number[1]) : ''
                            let afternoon_start_time = item.pua_number[0] ? new Date(item.pua_number[0]) : ''
                            let afternoon_end_time = item.pua_number[1] ? new Date(item.pua_number[1]) : ''
                            let evening_start_time = item.keep_alive[0] ? new Date(item.keep_alive[0]) : ''
                            let evening_end_time = item.keep_alive[1] ? new Date(item.keep_alive[1]) : ''
                            let value = {
                                key: key,
                                list: [
                                    {
                                        filedLabel: '机械性质',
                                        filedName: 'mec_pro_' + key,
                                        filedType: '单选',
                                        visible: false,
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: [item.mec_pro],
                                        radioList: [
                                            {
                                                value: 1,
                                                label: '租赁'
                                            },
                                            {
                                                value: 2,
                                                label: '自有'
                                            }
                                        ]
                                    },
                                    {
                                        filedLabel: '机械名称',
                                        filedName: 'name_' + key,
                                        filedType: '文本',
                                        placeholder: '请输入机械名称',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.name,
                                    },
                                    {
                                        filedLabel: '规格型号',
                                        filedName: 'model_' + key,
                                        filedType: '文本',
                                        placeholder: '请输入规格型号',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.model,
                                    },
                                    {
                                        filedLabel: '单位',
                                        filedName: 'unit_' + key,
                                        filedType: '文本',
                                        placeholder: '请输入单位',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.unit,
                                    },
                                    {
                                        filedLabel: '工作内容',
                                        filedName: 'result_' + key,
                                        filedType: '文本',
                                        placeholder: '工作内容',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.result,
                                    },
                                    {
                                        filedLabel: '上午开始时间',
                                        filedName: 'morning_start_time_' + key,
                                        filedType: '日期',
                                        visible: false,
                                        nodeType: 'minute',
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: morning_start_time,
                                    },
                                    {
                                        filedLabel: '上午结束时间',
                                        filedName: 'morning_end_time_' + key,
                                        filedType: '日期',
                                        visible: false,
                                        nodeType: 'minute',
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: morning_end_time,
                                    },
                                    {
                                        filedLabel: '下午开始时间',
                                        filedName: 'afternoon_start_time_' + key,
                                        filedType: '日期',
                                        visible: false,
                                        nodeType: 'minute',
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: afternoon_start_time,
                                    },
                                    {
                                        filedLabel: '下午结束时间',
                                        filedName: 'afternoon_end_time_' + key,
                                        filedType: '日期',
                                        visible: false,
                                        nodeType: 'minute',
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: afternoon_end_time,
                                    },
                                    {
                                        filedLabel: '晚上开始时间',
                                        filedName: 'evening_start_time_' + key,
                                        filedType: '日期',
                                        visible: false,
                                        nodeType: 'minute',
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: evening_start_time,
                                    },
                                    {
                                        filedLabel: '晚上结束时间',
                                        filedName: 'evening_end_time_' + key,
                                        filedType: '日期',
                                        visible: false,
                                        nodeType: 'minute',
                                        placeholder: '',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: evening_end_time,
                                    },
                                    {
                                        filedLabel: '合计工作时间',
                                        filedName: 'total_time_' + key,
                                        filedType: '文本',
                                        placeholder: '请输入合计工作时间',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.total_time,
                                    },
                                    {
                                        filedLabel: '油耗',
                                        filedName: 'oil_con_' + key,
                                        filedType: '文本',
                                        placeholder: '请输入油耗',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.oil_con,
                                    },
                                    {
                                        filedLabel: '备注',
                                        filedName: 'info_' + key,
                                        filedType: '文本',
                                        placeholder: '请输入备注',
                                        disabled: true,
                                        required: false,
                                        message: '',
                                        value: item.info,
                                    },
                                ]
                            }
                            Info.ListInfo[0].dataSource = [...Info.ListInfo[0].dataSource, value]
                        })
                    }

                    this.setState(preState => ({
                        Info,
                        Form,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_BlankingSelectProject = () => {
        let { popup, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: 1,
            limit: 500
        }
        BlankingSelectProject(data).then(
            (res) => {
                if (!res.code) {
                    popup.dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let pro = {
                                value: item.table_id,
                                label: item.pro_name,
                            }
                            popup.dataSource = [...popup.dataSource, pro]
                        })

                        popup.title = '选择项目'
                        popup.placeholder = '输入项目名称查询'
                        popup.isShow = true
                    }
                    this.setState(preState => ({
                        popup,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = () => {
        this.get_BlankingSelectProject()
    }

    handleVisible = (title, name) => {
        let { Info } = this.state
        Info.ListInfo[0].dataSource.forEach((item, index) => {
            item.list.forEach((em, ex) => {
                if (em.filedName === name) {
                    em.visible = true
                }
            })
        })

        this.setState(preState => ({
            Info
        })
        )
    }

    handleDate = (title, name) => {
        let { Info } = this.state
        Info.ListInfo[0].dataSource.forEach((item, index) => {
            item.list.forEach((em, ex) => {
                if (em.filedName === name) {
                    em.visible = false
                }
            })
        })

        this.setState(preState => ({
            Info
        })
        )
    }

    handleWind = (name) => {
        let { Info } = this.state
        Info.ListInfo.forEach((item, index) => {
            if (item.plateName === name) {
                item.unWind = !item.unWind
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleNew = () => {
        let { Info } = this.state
        let key = Info.ListInfo[0].dataSource.length + 1
        let value = {
            key: key,
            list: [
                {
                    filedLabel: '机械性质',
                    filedName: 'mec_pro_' + key,
                    filedType: '单选',
                    visible: false,
                    placeholder: '',
                    disabled: false,
                    required: false,
                    message: '',
                    value: [],
                    radioList: [
                        {
                            value: 1,
                            label: '租赁'
                        },
                        {
                            value: 2,
                            label: '自有'
                        }
                    ]
                },
                {
                    filedLabel: '机械名称',
                    filedName: 'name_' + key,
                    filedType: '文本',
                    placeholder: '请输入机械名称',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '规格型号',
                    filedName: 'model_' + key,
                    filedType: '文本',
                    placeholder: '请输入规格型号',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '单位',
                    filedName: 'unit_' + key,
                    filedType: '文本',
                    placeholder: '请输入单位',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '工作内容',
                    filedName: 'result_' + key,
                    filedType: '文本',
                    placeholder: '工作内容',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '上午开始时间',
                    filedName: 'morning_start_time_' + key,
                    filedType: '日期',
                    visible: false,
                    nodeType: 'minute',
                    placeholder: '',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '上午结束时间',
                    filedName: 'morning_end_time_' + key,
                    filedType: '日期',
                    visible: false,
                    nodeType: 'minute',
                    placeholder: '',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '下午开始时间',
                    filedName: 'afternoon_start_time_' + key,
                    filedType: '日期',
                    visible: false,
                    nodeType: 'minute',
                    placeholder: '',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '下午结束时间',
                    filedName: 'afternoon_end_time_' + key,
                    filedType: '日期',
                    visible: false,
                    nodeType: 'minute',
                    placeholder: '',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '晚上开始时间',
                    filedName: 'evening_start_time_' + key,
                    filedType: '日期',
                    visible: false,
                    nodeType: 'minute',
                    placeholder: '',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '晚上结束时间',
                    filedName: 'evening_end_time_' + key,
                    filedType: '日期',
                    visible: false,
                    nodeType: 'minute',
                    placeholder: '',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '合计工作时间',
                    filedName: 'total_time_' + key,
                    filedType: '文本',
                    placeholder: '请输入合计工作时间',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '油耗',
                    filedName: 'oil_con_' + key,
                    filedType: '文本',
                    placeholder: '请输入油耗',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '备注',
                    filedName: 'info_' + key,
                    filedType: '文本',
                    placeholder: '请输入备注',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
            ]
        }
        Info.ListInfo[0].dataSource = [...Info.ListInfo[0].dataSource, value]
        this.setState(preState => ({
            Info
        })
        )
    }

    handleQuery = (value) => {
        this.setState(preState => ({
            pro_name: value,
        }), () => {
            this.get_BlankingSelectProject()
        })
    }

    handleClose = () => {
        this.setState(preState => ({
            userName: '',
            pro_name: '',
            popup: {
                isShow: false,
                title: '选择项目',
                placeholder: '',
                multiple: false,
                disabled: false,
                dataSource: []
            }
        }))
    }

    handleSelect = (value) => {
        let { popup, Info, Form } = this.state

        if (value.length) {
            value.forEach((item, index) => {
                popup.dataSource.forEach((em, ex) => {
                    if (em.value === item) {
                        Info.TableInfo.forEach((m, x) => {
                            if (m.filedLabel === '项目名称') {
                                m.value = em.label
                                Form.project_id = em.value
                            }
                        })
                    }
                })
            })
        }
        this.setState(preState => ({
            Info,
            Form,
            pro_name: '',
            popup: {
                isShow: false,
                title: '选择项目',
                placeholder: '',
                multiple: false,
                disabled: false,
                dataSource: []
            }
        }))
    }

    handlePresent = (data) => {
        const { Form, Info } = this.state
        let date = new Date()
        Form.status = 2
        Form.delivery_date = dayjs(date).format('YYYY-MM-DD')
        Form.class = 3
        Form.operation_user = data.operation_user
        Form.log_user = data.log_user

        Form.content = []
        Info.ListInfo[0].dataSource.forEach((item, index) => {
            let value = {
                key: item.key,
                mec_pro: '',
                name: '',
                model: '',
                unit: '',
                result: '',
                give_number: [],
                pua_number: [],
                keep_alive: [],
                total_time: '',
                oil_con: '',
                info: ''
            }
            item.list.forEach((em, ex) => {
                value.mec_pro = data['mec_pro_' + item.key][0]
                value.name = data['name_' + item.key]
                value.model = data['model_' + item.key]
                value.unit = data['unit_' + item.key]
                value.result = data['info_' + item.key]

                console.log(data['morning_start_time_' + item.key], data['morning_end_time_' + item.key])

                let morning_start_time = dayjs(data['morning_start_time_' + item.key]).format('YYYY-MM-DD HH:mm:ss')
                value.give_number[0] = morning_start_time
                let morning_end_time = dayjs(data['morning_end_time_' + item.key]).format('YYYY-MM-DD HH:mm:ss')
                value.give_number[1] = morning_end_time

                let afternoon_start_time = dayjs(data['afternoon_start_time_' + item.key]).format('YYYY-MM-DD HH:mm:ss')
                value.pua_number[0] = afternoon_start_time
                let afternoon_end_time = dayjs(data['afternoon_end_time_' + item.key]).format('YYYY-MM-DD HH:mm:ss')
                value.pua_number[1] = afternoon_end_time

                let evening_start_time = dayjs(data['evening_start_time_' + item.key]).format('YYYY-MM-DD HH:mm:ss')
                value.keep_alive[0] = evening_start_time
                let evening_end_time = dayjs(data['evening_end_time_' + item.key]).format('YYYY-MM-DD HH:mm:ss')
                value.keep_alive[1] = evening_end_time

                value.total_time = data['total_time_' + item.key]
                value.oil_con = data['oil_con_' + item.key]
                value.info = data['info_' + item.key]
            })

            value.give_number = JSON.stringify(value.give_number)
            value.pua_number = JSON.stringify(value.pua_number)
            value.keep_alive = JSON.stringify(value.keep_alive)
            Form.content = [...Form.content, value]
        })

        Form.content = JSON.stringify(Form.content)
        createReceiptManagement(Form).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '提交成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '提交失败,请检查表单!',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info, popup } = this.state
        return (
            <div>
                <NavBar menuName='新建机械台班' />

                <AddConfig key={FormKey} Info={Info} buttonEvent={this.handleButton} visibleEvent={this.handleVisible} dateEvent={this.handleDate} windEvent={this.handleWind} newEvent={this.handleNew} presentEvent={this.handlePresent} auditEvent={this.handleAudit}/>

                <UserPopup popup={popup} closeEvent={this.handleClose} queryEvent={this.handleQuery} selectEvent={this.handleSelect} />
            </div>
        )
    }
}

export default withRouter(NewItem)
