import http from '../utils/http';

//查询完成立项项目
function BlankingSelectProject(data) {
    return http("get", '/api/BlankingSelectProject', data);
}

//施工日志列表
function ConstructionLogList(data) {
    return http("get", '/api/ConstructionLogList', data);
}

//添加编辑施工日志
function createConstructionLog(data) {
    return http("post", '/api/createConstructionLog', data);
}

//施工日志详情
function ConstructionLogDetail(data) {
    return http("get", '/api/ConstructionLogDetail', data);
}

//添加编辑零星用工
function createSporadic(data) {
    return http("post", '/api/createSporadic', data);
}

//零星用工列表
function sporadicList(data) {
    return http("get", '/api/sporadicList', data);
}

//零星用工详情
function sporadicDetail(data) {
    return http("get", '/api/sporadicDetail', data);
}

//添加编辑收货管理
function createReceiptManagement(data) {
    return http("post", '/api/createReceiptManagement', data);
}

//收货管理列表
function receiptManagementList(data) {
    return http("get", '/api/receiptManagementList', data);
}

//收货管理详情
function receiptManagementDetail(data) {
    return http("get", '/api/receiptManagementDetail', data);
}

//人材机合同审批详情
function machineryDetail(data) {
    return http("get", '/api/machineryDetail', data);
}

//人材机付款审批详情
function MaterialPaymentDetail(data) {
    return http("get", '/api/MaterialPaymentDetail', data);
}

//下料单详情
function materialDetail(data) {
    return http("get", '/api/materialDetail', data);
}

//进度款计算审批详情
function progressSettlementDetail(data) {
    return http("get", '/api/progressSettlementDetail', data);
}

//结算审批详情
function SettlementApprovalDetail(data) {
    return http("get", '/api/SettlementApprovalDetail', data);
}

//函件审批详情
function ApprovalCorrespondenceDetail(data) {
    return http("get", '/api/ApprovalCorrespondenceDetail', data);
}

//申请开票详情
function InvoiceApplicationDetail(data) {
    return http("get", '/api/InvoiceApplicationDetail', data);
}

//通知文件审批详情
function NotificationDocumentDetail(data) {
    return http("get", '/api/NotificationDocumentDetail', data);
}

//获取项目责任书信息详情
function ProjectResponsibilitiesDetail(data) {
    return http("get", '/api/ProjectResponsibilitiesDetail', data);
}

//收文管理详情
function ReceiveFileDetail(data) {
    return http("get", '/api/ReceiveFileDetail', data);
}

//项目责任书评审
function responsibilityReviewDetail(data) {
    return http("get", '/api/responsibilityReviewDetail', data);
}

//项目辅助用材及其他申请
function SporadicMaterialDetail(data) {
    return http("get", '/api/SporadicMaterialDetail', data);
}

//合作项目支付申请详情
function JointPaymentDetail(data) {
    return http("get", '/api/JointPaymentDetail', data);
}

//资料移交单详情
function DataTransferDetail(data) {
    return http("get", '/api/DataTransferDetail', data);
}

//过程资料文件列表
function processDocumentList(data) {
    return http("get", '/api/processDocumentList', data);
}

//获取过程资料详情
function processDocumentDetail(data) {
    return http("get", '/api/processDocumentDetail', data);
}

//过程资料上传
function processDocumentUpload(data) {
    return http("post", '/api/processDocumentUpload', data);
}

//过程资料上传撤回操作
function processRecallOperation(data) {
    return http("post", '/api/processRecallOperation', data);
}

export {
    BlankingSelectProject,
    ConstructionLogList,
    createConstructionLog,
    ConstructionLogDetail,
    createSporadic,
    sporadicList,
    sporadicDetail,
    createReceiptManagement,
    receiptManagementList,
    receiptManagementDetail,
    machineryDetail,
    MaterialPaymentDetail,
    materialDetail,
    progressSettlementDetail,
    SettlementApprovalDetail,
    ApprovalCorrespondenceDetail,
    InvoiceApplicationDetail,
    NotificationDocumentDetail,
    ProjectResponsibilitiesDetail,
    ReceiveFileDetail,
    responsibilityReviewDetail,
    SporadicMaterialDetail,
    JointPaymentDetail,
    DataTransferDetail,
    processDocumentList,
    processDocumentDetail,
    processDocumentUpload,
    processRecallOperation,
}