import http from '../utils/http';

//公司合同审批辅助数据
function contractAuxiliaryData(data) {
    return http("get", '/api/contractAuxiliaryData', data);
}

//公司合同审批详情
function contractApprovalDetail(data) {
    return http("get", '/api/contractApprovalDetail', data);
}

//获取投标决策辅助选项
function auxiliary_options(data) {
    return http("get", '/api/auxiliary_options', data);
}

//获取投标决策详情
function getDecisionDetail(data) {
    return http("get", '/api/getDecisionDetail', data);
}

//获取项目立项辅助选项
function projectAuxiliary(data) {
    return http("get", '/api/projectAuxiliary', data);
}

//获取立项项目详情
function getProjectDetail(data) {
    return http("get", '/api/getProjectDetail', data);
}

//获取收入合同审批辅助项
function getRevenueAuxiliary(data) {
    return http("get", '/api/getRevenueAuxiliary', data);
}

//查看收入合同审批详情
function RevenueDetail(data) {
    return http("get", '/api/RevenueDetail', data);
}

//供应商认价审批详情
function SupConfirmPriceDetail(data) {
    return http("get", '/api/SupConfirmPriceDetail', data);
}

//获取保证金支付辅助项
function getMarginAuxiliary(data) {
    return http("get", '/api/getMarginAuxiliary', data);
}

//投标保证金支付申请详情
function biddingMarginDetail(data) {
    return http("get", '/api/biddingMarginDetail', data);
}

//市场部 设计项目申请详情
function DesignMarkProjectDetail(data) {
    return http("get", '/api/DesignMarkProjectDetail', data);
}

//市场部 概算项目任务申请
function estimatesMarkProjectDetail(data) {
    return http("get", '/api/estimatesMarkProjectDetail', data);
}

//优质供应商入库详情
function excellentSupplierDetail(data) {
    return http("get", '/api/excellentSupplierDetail', data);
}

//设计文件移交及进度汇报
function DesignHandoverDetail(data) {
    return http("get", '/api/DesignHandoverDetail', data);
}

//科技项目申报详情
function TechnologyApplicationDetail(data) {
    return http("get", '/api/TechnologyApplicationDetail', data);
}

//研发项目
function DevelopmentApplicationDetail(data) {
    return http("get", '/api/DevelopmentApplicationDetail', data);
}

//中期检查报告
function DevelopmentInspectDetail(data) {
    return http("get", '/api/DevelopmentInspectDetail', data);
}

//结题验收申请表
function DevelopmentAcceptanceDetail(data) {
    return http("get", '/api/DevelopmentAcceptanceDetail', data);
}

//项目延期结题申请
function DevelopmentExtensionDetail(data) {
    return http("get", '/api/DevelopmentExtensionDetail', data);
}

//358项目立项辅助数据
function researchProjectAuxiliary(data) {
    return http("get", '/api/researchProjectAuxiliary', data);
}

//358项目立项详情
function researchProjectDetail(data) {
    return http("get", '/api/researchProjectDetail', data);
}

//358临时用工付款详情
function ResTemporaryDetail(data) {
    return http("get", '/api/ResTemporaryDetail', data);
}

//358结算审批详情
function nurserySettlementDetail(data) {
    return http("get", '/api/nurserySettlementDetail', data);
}

//358进度计量审批详情
function ResProgressSettlementDetail(data) {
    return http("get", '/api/ResProgressSettlementDetail', data);
}

//成果转化项目合同审批详情
function researchContractDetail(data) {
    return http("get", '/api/researchContractDetail', data);
}

//358合同审批详情
function researchConDetail(data) {
    return http("get", '/api/researchConDetail', data);
}

//成果转化项目合同审批辅助数据
function researchContractAuxiliary(data) {
    return http("get", '/api/researchContractAuxiliary', data);
}

//材料调价审批详情
function updateMacPriceDetail(data) {
    return http("get", '/api/updateMacPriceDetail', data);
}

//358付款审批详情
function SettlementPaymentDetail(data) {
    return http("get", '/api/SettlementPaymentDetail', data);
}

//认质核价审批详情
function RecognitionPriceDetail(data) {
    return http("get", '/api/RecognitionPriceDetail', data);
}

//调拨申请详情
function CenRestructureDetail(data) {
    return http("get", '/api/CenRestructureDetail', data);
}

//出库管理详情
function SeedlingInventoryDetail(data) {
    return http("get", '/api/SeedlingInventoryDetail', data);
}

//工程进度款审批
function CostProgressDetail(data) {
    return http("get", '/api/CostProgressDetail', data);
}

//工作暂管通知详情
function WorkNoticeDetail(data) {
    return http("get", '/api/WorkNoticeDetail', data);
}

//离职交接详情
function HandoverWorkDetail(data) {
    return http("get", '/api/HandoverWorkDetail', data);
}

//专利续费详情
function PatentRenewalDetail(data) {
    return http("get", '/api/PatentRenewalDetail', data);
}



export {
    contractAuxiliaryData,
    contractApprovalDetail,
    auxiliary_options,
    getDecisionDetail,
    projectAuxiliary,
    getProjectDetail,
    getRevenueAuxiliary,
    RevenueDetail,
    SupConfirmPriceDetail,
    biddingMarginDetail,
    getMarginAuxiliary,
    DesignMarkProjectDetail,
    excellentSupplierDetail,
    DesignHandoverDetail,
    estimatesMarkProjectDetail,
    TechnologyApplicationDetail,
    DevelopmentApplicationDetail,
    DevelopmentInspectDetail,
    DevelopmentAcceptanceDetail,
    DevelopmentExtensionDetail,
    researchProjectAuxiliary,
    researchProjectDetail,
    ResTemporaryDetail,
    nurserySettlementDetail,
    ResProgressSettlementDetail,
    researchContractDetail,
    researchConDetail,
    researchContractAuxiliary,
    updateMacPriceDetail,
    SettlementPaymentDetail,
    RecognitionPriceDetail,
    CenRestructureDetail,
    SeedlingInventoryDetail,
    CostProgressDetail,
    WorkNoticeDetail,
    HandoverWorkDetail,
    PatentRenewalDetail
}