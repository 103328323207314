import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { getRevenueAuxiliary, RevenueDetail } from "../../../utils/public"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_22.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '收入合同审批',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '是否盖章',
                        filedName: 'is_seal',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                label: '是',
                                value: 1,
                            }, {
                                label: '否',
                                value: 0,
                            }
                        ]
                    },
                    {
                        filedLabel: '印章名称',
                        filedName: 'seal_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '是否内部',
                        filedName: 'is_inside',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                label: '是',
                                value: 1,
                            }, {
                                label: '否',
                                value: 2,
                            }
                        ]
                    },
                    {
                        filedLabel: '合同编号',
                        filedName: 'number',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '合同结算方式',
                        filedName: 'set_method',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: []
                    },
                    {
                        filedLabel: '合同类别',
                        filedName: 'set_type',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: []
                    },
                    {
                        filedLabel: '合同性质',
                        filedName: 'set_nature',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: []
                    },
                    {
                        filedLabel: '主合同编号',
                        filedName: 'parent_id',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '主合同名称',
                        filedName: 'parent_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '业主合同编号',
                        filedName: 'owner_set_no',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '合同名称',
                        filedName: 'owner_set_pro_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '合同总金额(元)',
                        filedName: 'set_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '工期(天)',
                        filedName: 'duration',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '预计开工时间',
                        filedName: 'plan_time',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '完工时间',
                        filedName: 'completion_time',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '工程预付款(元)',
                        filedName: 'advance_charge',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '质保期',
                        filedName: 'warranty_period',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '养护期',
                        filedName: 'curing_period',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '履约担保',
                        filedName: 'per_bond',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '付款条款',
                        filedName: 'term_payment',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '逾期违约责任',
                        filedName: 'overdue_liability',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '其他重要的违约责任',
                        filedName: 'other_liability',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '材料加差调整条款',
                        filedName: 'material_clause',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '业主单位名称',
                        filedName: 'owner_unit',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '工程项目地点/面积',
                        filedName: 'pro_address_area',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '联系人',
                        filedName: 'contacts',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '联系电话',
                        filedName: 'contact_phone',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '相关说明',
                        filedName: 'about_description',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                ]
            },
        }
    }

    componentDidMount() {
        this.get_RevenueAuxiliary()
    }

    get_RevenueAuxiliary = () => {
        let { Info } = this.state
        getRevenueAuxiliary().then(
            (res) => {
                if (!res.code) {
                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '合同结算方式') {
                            for (let key in res.data.set_method) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.set_method[key]
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        } else if (item.filedLabel === '合同类别') {
                            for (let key in res.data.set_type) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.set_type[key]
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        } else if (item.filedLabel === '合同性质') {
                            for (let key in res.data.set_nature) {
                                let value = {
                                    value: Number(key),
                                    label: res.data.set_nature[key]
                                }
                                item.radioList = [...item.radioList, value]
                            }
                        }
                    })

                    this.setState(preState => ({
                        Info
                    }), () => {
                        if (this.props.location.query) {
                            let tableId = this.props.location.query.tableId
                            this.get_DecisionDetail(tableId)
                        }
                    })
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_DecisionDetail = (tableId) => {
        const { Info } = this.state;
        let data = {
            table_id: tableId
        }
        RevenueDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.revenue.status === 2 || res.data.revenue.status === 4){
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '附件') {
                                if (!res.data.revenue.enclosure || !res.data.revenue.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.revenue.status
                    Info.apply.userName = res.data.revenue.applyUserName
                    Info.apply.deName = res.data.revenue.applyUserDeName
                    Info.apply.apply_date = res.data.revenue.date_day
                    Info.apply.process = res.data.revenue.reviewAudit
                    Info.apply.process.editPermission = res.data.revenue.editPermission

                    if (res.data.revenue.is_seal === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '印章名称') {
                                item.unShow = false
                                item.value = res.data.revenue.sealNames
                            }
                        })
                    }

                    if (res.data.revenue.set_nature === 2) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '主合同编号') {
                                item.value = res.data.revenue.code
                                item.unShow = false
                            } else if (item.filedLabel === '主合同名称') {
                                item.value = res.data.revenue.parent_name
                                item.unShow = false
                            }
                        })
                    }

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '是否盖章') {
                            item.value = []
                            item.value = [...item.value, res.data.revenue.is_seal]
                        } else if (item.filedLabel === '是否内部') {
                            item.value = []
                            item.value = [...item.value, res.data.revenue.is_inside]
                        } else if (item.filedLabel === '合同编号') {
                            item.value = res.data.revenue.number
                        } else if (item.filedLabel === '合同结算方式') {
                            item.value = []
                            item.value = [...item.value, res.data.revenue.set_method]
                        } else if (item.filedLabel === '合同类别') {
                            item.value = []
                            item.value = [...item.value, res.data.revenue.set_type]
                        } else if (item.filedLabel === '合同性质') {
                            item.value = []
                            item.value = [...item.value, res.data.revenue.set_nature]
                        } else if (item.filedLabel === '业主合同编号') {
                            item.value = res.data.revenue.owner_set_no
                        } else if (item.filedLabel === '合同名称') {
                            item.value = res.data.revenue.owner_set_pro_name
                        } else if (item.filedLabel === '项目名称') {
                            item.value = res.data.revenue.project.pro_name
                        } else if (item.filedLabel === '合同总金额(元)') {
                            item.value = res.data.revenue.set_money
                        } else if (item.filedLabel === '工期(天)') {
                            item.value = res.data.revenue.duration
                        } else if (item.filedLabel === '预计开工时间') {
                            item.value = res.data.revenue.plan_time ? new Date(res.data.revenue.plan_time) : ''
                        } else if (item.filedLabel === '完工时间') {
                            item.value = res.data.revenue.completion_time ? new Date(res.data.revenue.completion_time) : ''
                        } else if (item.filedLabel === '工程预付款(元)') {
                            item.value = res.data.revenue.advance_charge
                        } else if (item.filedLabel === '质保期') {
                            item.value = res.data.revenue.warranty_period
                        } else if (item.filedLabel === '养护期') {
                            item.value = res.data.revenue.curing_period
                        } else if (item.filedLabel === '履约担保') {
                            item.value = res.data.revenue.per_bond
                        } else if (item.filedLabel === '付款条款') {
                            item.value = res.data.revenue.term_payment
                        } else if (item.filedLabel === '逾期违约责任') {
                            item.value = res.data.revenue.overdue_liability
                        } else if (item.filedLabel === '其他重要的违约责任') {
                            item.value = res.data.revenue.other_liability
                        } else if (item.filedLabel === '材料加差调整条款') {
                            item.value = res.data.revenue.material_clause
                        } else if (item.filedLabel === '业主单位名称') {
                            item.value = res.data.revenue.owner_unit
                        } else if (item.filedLabel === '工程项目地点/面积') {
                            item.value = res.data.revenue.pro_address_area
                        } else if (item.filedLabel === '联系人') {
                            item.value = res.data.revenue.contacts
                        } else if (item.filedLabel === '联系电话') {
                            item.value = res.data.revenue.contact_phone
                        } else if (item.filedLabel === '相关说明') {
                            item.value = res.data.revenue.about_description
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.revenue.enclosure || []
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='收入合同审批' />

                <AddConfig key={FormKey} Info={Info} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
