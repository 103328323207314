import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { nurserySettlementDetail } from "../../../utils/public"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_53.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '成果转化项目结算审批',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '合同名称',
                        filedName: 'con_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '合同编号',
                        filedName: 'con_num',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '合同金额(元)',
                        filedName: 'con_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目编号',
                        filedName: 'pro_number',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '项目经理',
                        filedName: 'pro_manager',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '供货单位',
                        filedName: 'con_party',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '结算金额(元)',
                        filedName: 'con_set_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },{
                        filedLabel: '前期进度计量金额(元)',
                        filedName: 'progress_payment',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    }, {
                        filedLabel: '备注',
                        filedName: 'info',
                        filedType: '文本域',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                ],
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_nurserySettlementDetail(tableId)
        }
    }

    get_nurserySettlementDetail = (tableId) => {
        const { Info, provinces } = this.state;
        let data = {
            table_id: tableId
        }
        nurserySettlementDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.status === 2 || res.data.status === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '附件') {
                                if (!res.data.enclosure || !res.data.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.status
                    Info.apply.userName = res.data.applyUsername
                    Info.apply.deName = res.data.applyDeName
                    Info.apply.apply_date = res.data.created_at
                    Info.apply.process = res.data.reviewAudit
                    Info.apply.process.editPermission = res.data.editPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '合同名称') {
                            item.value = res.data.con_name
                        } else if (item.filedLabel === '合同编号') {
                            item.value = res.data.con_num
                        } else if (item.filedLabel === '合同金额(元)') {
                            item.value = res.data.con_money
                        } else if (item.filedLabel === '项目名称') {
                            item.value = res.data.pro_name
                        } else if (item.filedLabel === '项目编号') {
                            item.value = res.data.pro_number
                        } else if (item.filedLabel === '项目经理') {
                            item.value = res.data.proManager
                        } else if (item.filedLabel === '供货单位') {
                            item.value = res.data.sup_name
                        } else if (item.filedLabel === '结算金额(元)') {
                            item.value = res.data.con_set_money
                        }else if (item.filedLabel === '前期进度计量金额(元)') {
                            item.value = res.data.progress_payment || 0
                        } else if (item.filedLabel === '备注') {
                            item.value = res.data.info
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.enclosure || []
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='成果转化项目立项申请(358工程)' />

                <AddConfig key={FormKey} Info={Info} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
