import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { TechnologyApplicationDetail } from "../../../utils/public"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_47.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '科技项目申报',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目类别',
                        filedName: 'pro_class',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                value: 1,
                                label: '课题项目'
                            },
                            {
                                value: 2,
                                label: '企业资质'
                            },
                            {
                                value: 3,
                                label: '情况汇报'
                            },
                            {
                                value: 4,
                                label: '荣誉奖项'
                            },
                            {
                                value: 5,
                                label: '奖励补贴'
                            }
                        ]
                    },
                    {
                        filedLabel: '申报开始时间',
                        filedName: 'apply_date',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '申报结束时间',
                        filedName: 'end_date',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '申报方式',
                        filedName: 'apply_type',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                value: 1,
                                label: '自主申报'
                            },
                            {
                                value: 2,
                                label: '中介参与'
                            }
                        ]
                    },
                    {
                        filedLabel: '有效期限',
                        filedName: 'valid_period',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '申报方向',
                        filedName: 'dec_direction',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '申报资金额度(元)',
                        filedName: 'dec_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '补贴形式',
                        filedName: 'subsidy_form',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '主管机构',
                        filedName: 'authority',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '网址',
                        filedName: 'int_url',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '联系人',
                        filedName: 'contacts',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '职务',
                        filedName: 'job',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '固定电话',
                        filedName: 'tel_phone',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '电子邮箱',
                        filedName: 'email',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '传真',
                        filedName: 'fax',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '手机',
                        filedName: 'mobile_phone',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '申报要求',
                        filedName: 'apply_ask',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },                    
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                    {
                        filedLabel: '项目负责人',
                        filedName: 'pro_apply_user',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '申报单位',
                        filedName: 'apply_unit',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '代理单位',
                        filedName: 'agent_unit',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '代理费用(元)',
                        filedName: 'agent_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '付款方式',
                        filedName: 'pay_model',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                ],
                ListInfo: []
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_TechnologyApplicationDetail(tableId)
        }
    }

    get_TechnologyApplicationDetail = (tableId) => {
        const { Info } = this.state;
        let data = {
            table_id: tableId
        }
        TechnologyApplicationDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 2 || res.data.Detail.status === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '附件') {
                                if (!res.data.Detail.enclosure || !res.data.Detail.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUserName
                    Info.apply.deName = res.data.Detail.applyUserDeName
                    Info.apply.apply_date = res.data.Detail.created_at
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.pro_name
                        }else if (item.filedLabel === '项目类别') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.pro_class]
                        }else if (item.filedLabel === '申报开始时间') {
                            item.value = res.data.Detail.apply_date ? new Date(res.data.Detail.apply_date) : ''
                        }else if (item.filedLabel === '申报结束时间') {
                            item.value = res.data.Detail.end_date ? new Date(res.data.Detail.end_date) : ''
                        } else if (item.filedLabel === '申报方式') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.apply_type]
                        }else if (item.filedLabel === '有效期限') {
                            item.value = res.data.Detail.valid_period
                        } else if (item.filedLabel === '申报方向') {
                            item.value = res.data.Detail.dec_direction
                        } else if (item.filedLabel === '申报资金额度(元)') {
                            item.value = res.data.Detail.dec_money
                        } else if (item.filedLabel === '补贴形式') {
                            item.value = res.data.Detail.subsidy_form
                        }else if (item.filedLabel === '主管机构') {
                            item.value = res.data.Detail.authority
                        }else if (item.filedLabel === '网址') {
                            item.value = res.data.Detail.int_url
                        }else if (item.filedLabel === '联系人') {
                            item.value = res.data.Detail.contacts
                        }else if (item.filedLabel === '职务') {
                            item.value = res.data.Detail.job
                        }else if (item.filedLabel === '固定电话') {
                            item.value = res.data.Detail.tel_phone
                        }else if (item.filedLabel === '电子邮箱') {
                            item.value = res.data.Detail.email
                        }else if (item.filedLabel === '传真') {
                            item.value = res.data.Detail.fax
                        }else if (item.filedLabel === '手机') {
                            item.value = res.data.Detail.mobile_phone
                        }else if (item.filedLabel === '申报要求') {
                            item.value = res.data.Detail.apply_ask
                        }else if (item.filedLabel === '项目负责人') {
                            item.value = res.data.Detail.pro_apply_user
                        }else if (item.filedLabel === '申报单位') {
                            item.value = res.data.Detail.apply_unit
                        }else if (item.filedLabel === '代理单位') {
                            item.value = res.data.Detail.agent_unit
                        }else if (item.filedLabel === '代理费用(元)') {
                            item.value = res.data.Detail.agent_money
                        }else if (item.filedLabel === '付款方式') {
                            item.value = res.data.Detail.pay_model
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.Detail.enclosure || []
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='科技项目申报' />

                <AddConfig key={FormKey} Info={Info} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
