import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import dayjs from 'dayjs'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { BlankingSelectProject, createSporadic, sporadicDetail } from "../../../utils/project"
import { reviewApplyResult } from "../../../utils/api"
import UserPopup from "../../../components/UserPopup"
import Icon from "../../../accets/img/icon_14.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '零星用工',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '统计月份',
                        filedName: 'month',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: false,
                        required: true,
                        message: '',
                        value: [],
                        radioList: [
                            { value: 1, label: "1月" },
                            { value: 2, label: "2月" },
                            { value: 3, label: "3月" },
                            { value: 4, label: "4月" },
                            { value: 5, label: "5月" },
                            { value: 6, label: "6月" },
                            { value: 7, label: "7月" },
                            { value: 8, label: "8月" },
                            { value: 9, label: "9月" },
                            { value: 10, label: "10月" },
                            { value: 11, label: "11月" },
                            { value: 12, label: "12月" }
                        ]
                    },
                    {
                        filedLabel: '班组名称',
                        filedName: 'team',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入班组名称',
                        disabled: false,
                        required: true,
                        message: '请输入班组名称',
                        value: '',
                    },
                    {
                        filedLabel: '工作开始时间',
                        filedName: 'start_time',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'minute',
                        placeholder: '',
                        disabled: false,
                        required: true,
                        message: '请选择工作开始时间',
                        value: '',
                    },
                    {
                        filedLabel: '工作结束时间',
                        filedName: 'end_time',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'minute',
                        placeholder: '',
                        disabled: false,
                        required: true,
                        message: '请选择工作结束时间',
                        value: '',
                    },
                    {
                        filedLabel: '工作内容',
                        filedName: 'content',
                        filedType: '文本域',
                        placeholder: '请输入工作内容',
                        disabled: false,
                        required: true,
                        message: '请输入工作内容',
                        value: '',
                    },
                    {
                        filedLabel: '普工(工日)',
                        filedName: 'tome',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入普工(工日)',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '技工(工日)',
                        filedName: 'skill',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入技工(工日)',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '班组长',
                        filedName: 'group_leader',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入班组长',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '工长',
                        filedName: 'foreman',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入工长',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '备注',
                        filedName: 'info',
                        filedType: '文本域',
                        placeholder: '请输入工作内容',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '计日工记录表',
                        filedName: 'record_sheet',
                        filedType: '附件',
                        placeholder: '当日施工进展',
                        disabled: false,
                        required: false,
                        message: '',
                        value: [],
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: [],
                    },
                ],
            },
            Form: {},
            userName: '',
            pro_name: '',
            popupTitle: '',
            popup: {
                isShow: false,
                title: '选择项目',
                placeholder: '输入项目名称查询',
                multiple: false,
                disabled: false,
                dataSource: []
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_sporadicDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let { Info } = this.state;
        let userInfo = storage.getItem('userInfo');

        Info.apply.userName = userInfo.name
        Info.apply.deName = userInfo.deName
        Info.apply.apply_date = ''

        this.setState(preState => ({
            Info
        })
        )
    }

    get_sporadicDetail = (tableId) => {
        const { Info, Form } = this.state;
        let data = {
            table_id: tableId
        }
        sporadicDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        Form.table_id = tableId

                        Form.project_id = res.data.Detail.project_id
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.filedLabel === '计日工记录表') {
                                if (!res.data.Detail.record_sheet.length) {
                                    item.unShow = true
                                }
                            } else if (item.filedLabel === '附件') {
                                if (!res.data.Detail.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUsername
                    Info.apply.deName = res.data.Detail.deName
                    Info.apply.apply_date = res.data.Detail.created_at
                    if (res.data.Detail.reviewAudit) {
                        res.data.Detail.reviewAudit.ccReading.forEach((item, index) => {
                            item.username = item.userName
                        })
                    }
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    let content = JSON.parse(res.data.Detail.content)

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.pro_name
                        } else if (item.filedLabel === '统计月份') {
                            item.value = []
                            item.value = [...item.value, Number(res.data.Detail.month)]
                        } else if (item.filedLabel === '班组名称') {
                            item.value = res.data.Detail.team
                        } else if (item.filedLabel === '工作开始时间') {
                            item.value = new Date(content[0].start_time)
                        } else if (item.filedLabel === '工作结束时间') {
                            item.value = new Date(content[0].end_time)
                        } else if (item.filedLabel === '工作内容') {
                            item.value = content[0].content
                        } else if (item.filedLabel === '普工(工日)') {
                            item.value = content[0].tome
                        } else if (item.filedLabel === '技工(工日)') {
                            item.value = content[0].skill
                        } else if (item.filedLabel === '班组长') {
                            item.value = content[0].group_leader
                        } else if (item.filedLabel === '工长') {
                            item.value = content[0].foreman
                        } else if (item.filedLabel === '备注') {
                            item.value = content[0].info
                        } else if (item.filedLabel === '计日工记录表') {
                            item.value = res.data.Detail.record_sheet
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.Detail.enclosure
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        Form,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_BlankingSelectProject = () => {
        let { popup, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: 1,
            limit: 500
        }
        BlankingSelectProject(data).then(
            (res) => {
                if (!res.code) {
                    popup.dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let pro = {
                                value: item.table_id,
                                label: item.pro_name,
                            }
                            popup.dataSource = [...popup.dataSource, pro]
                        })

                        popup.isShow = true
                    }
                    this.setState(preState => ({
                        popup,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = () => {
        this.get_BlankingSelectProject()
    }

    handleVisible = (title, name) => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.filedLabel === title) {
                item.visible = true
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleDate = (title, name) => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.filedLabel === title) {
                item.visible = false
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleQuery = (value) => {

        this.setState(preState => ({
            pro_name: value,
        }), () => {
            this.get_BlankingSelectProject()
        })

    }

    handleClose = () => {
        this.setState(preState => ({
            pro_name: '',
            popup: {
                isShow: false,
                title: '选择项目',
                placeholder: '输入项目名称查询',
                multiple: false,
                disabled: false,
                dataSource: []
            },
        }))
    }

    handleSelect = (value) => {
        let { popup, Info, Form } = this.state
        if (value.length) {
            value.forEach((item, index) => {
                popup.dataSource.forEach((em, ex) => {
                    if (em.value === item) {
                        Info.TableInfo.forEach((m, x) => {
                            if (m.filedLabel === '项目名称') {
                                m.value = em.label
                                Form.project_id = em.value
                            }
                        })
                    }
                })
            })
        }

        this.setState(preState => ({
            Info,
            Form,
            userName: '',
            pro_name: '',
            popup: {
                isShow: false,
                title: '选择项目',
                placeholder: '输入项目名称查询',
                multiple: false,
                disabled: false,
                dataSource: []
            },
        }))
    }

    handleFile = (fileList, fileName) => {
        let { Form, Info } = this.state
        let storeList = []
        if (fileList.length) {
            fileList.forEach((item, index) => {
                storeList.push(item.table_id)
            })
        }
        Form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Form,
            Info
        })
        )
    }

    handlePresent = (data) => {
        const { Form, Info } = this.state
        Form.status = 2
        Form.month = data.month[0]
        Form.team = data.team
        Form.content = []
        let value = {
            start_time: dayjs(data.start_time).format('YYYY-MM-DD HH:mm:ss'),
            end_time: dayjs(data.end_time).format('YYYY-MM-DD HH:mm:ss'),
            content: data.content,
            tome: data.tome,
            skill: data.skill,
            group_leader: data.group_leader,
            foreman: data.foreman,
            info: data.info
        }
        Form.content.push(value)
        Form.content = JSON.stringify(Form.content)

        createSporadic(Form).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '提交成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '提交失败,请检查表单!',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info, popup } = this.state
        return (
            <div>
                <NavBar menuName='新建零星用工' />

                <AddConfig key={FormKey} Info={Info} buttonEvent={this.handleButton} visibleEvent={this.handleVisible} dateEvent={this.handleDate} fileEvent={this.handleFile} presentEvent={this.handlePresent} auditEvent={this.handleAudit}/>

                <UserPopup popup={popup} closeEvent={this.handleClose} queryEvent={this.handleQuery} selectEvent={this.handleSelect} />
            </div>
        )
    }
}

export default withRouter(NewItem)
