import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { DevelopmentInspectDetail } from "../../../utils/public"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_48.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '中期检查报告',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目编号',
                        filedName: 'pro_code',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '部门名称',
                        filedName: 'de_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },                
                    {
                        filedLabel: '项目经费(元)',
                        filedName: 'pro_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目负责人',
                        filedName: 'pro_manager',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '合作单位',
                        filedName: 'coo_unit',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '联系电话',
                        filedName: 'phone',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '开始时间',
                        filedName: 'start_time',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '结束时间',
                        filedName: 'end_time',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '阶段性成果',
                        filedName: 'closing_goal',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                ],
                ListInfo: [
                    {
                        plateName: '成果信息',
                        unWind: false,
                        dataSource: []
                    },
                    {
                        plateName: '',
                        list: [
                            {
                                filedLabel: '下一步研究计划',
                                filedName: 'last_plan',
                                filedType: '文本域',
                                placeholder: '',
                                disabled: true,
                                required: false,
                                message: '',
                                value: '',
                            },
                            {
                                filedLabel: '经费使用情况和下阶段经费安排计划',
                                filedName: 'use_money',
                                filedType: '文本域',
                                placeholder: '',
                                disabled: true,
                                required: false,
                                message: '',
                                value: '',
                            },
                            {
                                filedLabel: '存在问题、建议及需要说明的情况',
                                filedName: 'matter',
                                filedType: '文本域',
                                placeholder: '',
                                disabled: true,
                                required: false,
                                message: '',
                                value: '',
                            },
                            {
                                filedLabel: '附件(研究工作进展)',
                                filedName: 'enclosure',
                                filedType: '附件',
                                placeholder: '',
                                disabled: true,
                                required: false,
                                message: '',
                                value: [],
                            },
                        ]
                    },
                ]
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_DevelopmentInspectDetail(tableId)
        }
    }

    get_DevelopmentInspectDetail = (tableId) => {
        const { Info } = this.state;
        let data = {
            table_id: tableId
        }
        DevelopmentInspectDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 2 || res.data.Detail.status === 4) {
                        Info.ListInfo[1].list.forEach((item, index) => {
                            if (item.filedLabel === '附件(研究工作进展)') {
                                if (!res.data.Detail.enclosure || !res.data.Detail.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUserName
                    Info.apply.deName = res.data.Detail.applyUserDeName
                    Info.apply.apply_date = res.data.Detail.created_at
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.ListInfo[0].dataSource = []
                    let information = JSON.parse(res.data.Detail.information)
                    information.forEach((item, index) => {
                        let key = index + 1
                        let dataSource = {
                            key: key,
                            list: [
                                {
                                    filedLabel: '成果名称',
                                    filedName: 'achievement_' + key,
                                    filedType: '文本',
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.achievement,
                                },
                                {
                                    filedLabel: '形式',
                                    filedName: 'shape_' + key,
                                    filedType: '文本',
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.shape,
                                },
                            ]
                        }
                        Info.ListInfo[0].dataSource = [...Info.ListInfo[0].dataSource, dataSource]
                    })

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.devel.pro_name
                        } else if (item.filedLabel === '项目编号') {
                            item.value = res.data.Detail.devel.pro_code
                        }  else if (item.filedLabel === '部门名称') {
                            item.value = res.data.Detail.devel.deName
                        } else if (item.filedLabel === '项目经费(元)') {
                            item.value = res.data.Detail.devel.pro_money
                        } else if (item.filedLabel === '项目负责人') {
                            item.value = res.data.Detail.devel.pro_manager
                        } else if (item.filedLabel === '合作单位') {
                            item.value = res.data.Detail.devel.coo_unit
                        } else if (item.filedLabel === '联系电话') {
                            item.value = res.data.Detail.devel.phone
                        } else if (item.filedLabel === '开始时间') {
                            item.value = res.data.Detail.devel.start_time ? new Date(res.data.Detail.devel.start_time) : ''
                        } else if (item.filedLabel === '结束时间') {
                            item.value = res.data.Detail.devel.end_time ? new Date(res.data.Detail.devel.end_time) : ''
                        }else if (item.filedLabel === '阶段性成果') {
                            item.value = res.data.Detail.stage_result
                        }
                    })

                    Info.ListInfo[1].list.forEach((item, index) => {
                        if (item.filedLabel === '下一步研究计划') {
                            item.value = res.data.Detail.last_plan
                        } else if (item.filedLabel === '经费使用情况和下阶段经费安排计划') {
                            item.value = res.data.Detail.use_money
                        } else if (item.filedLabel === '存在问题、建议及需要说明的情况') {
                            item.value = res.data.Detail.matter
                        } else if (item.filedLabel === '附件(研究工作进展)') {
                            item.value = res.data.Detail.enclosure || []
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleWind = (name) => {
        let { Info } = this.state
        Info.ListInfo.forEach((item, index) => {
            if (item.plateName === name) {
                item.unWind = !item.unWind
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='中期检查报告' />

                <AddConfig key={FormKey} Info={Info} windEvent={this.handleWind} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
