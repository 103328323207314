import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { equipmentDetail } from "../../../utils/oa"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_11.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '设备购置申请',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '设备金额',
                        filedName: 've_type',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: [],
                        unShow:true,
                        radioList: [
                            { value: 1, label: "小于400" },
                            { value: 2, label: "大于400" },
                        ]
                    },
                    {
                        filedLabel: '职位',
                        filedName: 've_type',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: [],
                        unShow:true,
                        radioList: [
                            { value: 1, label: "职员" },
                            { value: 2, label: "经理及以上" },
                        ]
                    },
                ],
                ListInfo: [
                    {
                        plateName: '购置设备信息',
                        unWind: false,
                        dataSource: []
                    },
                ]
            },
            departmentList: []
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_equipmentDetail(tableId)
        }
    }

    get_equipmentDetail = (tableId) => {
        const { Info } = this.state;
        let data = {
            table_id: tableId
        }
        equipmentDetail(data).then(
            (res) => {
                if (!res.code) {

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.username
                    Info.apply.deName = res.data.Detail.deName
                    Info.apply.apply_date = res.data.Detail.created_at
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.ListInfo[0].dataSource = []

                    res.data.Detail.details.forEach((item, index) => {
                        let key = index + 1
                        let dataSource = {
                            key: key,
                            list: [
                                {
                                    filedLabel: '设备名称',
                                    filedName: 'pur_name_' + key,
                                    filedType: '文本',
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.pur_name,
                                },
                                {
                                    filedLabel: '金额(元)',
                                    filedName: 'amount_' + key,
                                    filedType: '文本',
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.amount,
                                },
                                {
                                    filedLabel: '数量',
                                    filedName: 'number_' + key,
                                    filedType: '文本',
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.number,
                                },
                            ]
                        }
                        Info.ListInfo[0].dataSource = [...Info.ListInfo[0].dataSource, dataSource]
                    })

                    if (res.data.Detail.type) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '设备金额') {
                                item.unShow = false
                                item.value = []
                                item.value = [...item.value, res.data.Detail.type]
                            }
                        })
                    }

                    if (res.data.Detail.job_type) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '职位') {
                                item.unShow = false
                                item.value = []
                                item.value = [...item.value, res.data.Detail.job_type]
                            }
                        })
                    }

                    this.setState(preState => ({
                        Info,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleWind = (name) => {
        let { Info } = this.state
        Info.ListInfo.forEach((item, index) => {
            if (item.plateName === name) {
                item.unWind = !item.unWind
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='设备购置申请' />

                <AddConfig key={FormKey} Info={Info} windEvent={this.handleWind} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
