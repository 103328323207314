import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { DevelopmentAcceptanceDetail } from "../../../utils/public"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_48.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '结题验收申请表',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目编号',
                        filedName: 'pro_code',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '部门名称',
                        filedName: 'de_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目经费(元)',
                        filedName: 'pro_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '项目负责人',
                        filedName: 'pro_manager',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '合作单位',
                        filedName: 'coo_unit',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '联系电话',
                        filedName: 'phone',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '开始时间',
                        filedName: 'start_time',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '结束时间',
                        filedName: 'end_time',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                ],
                ListInfo: [
                    {
                        plateName: '项目成果形式及数量(需提供附件)',
                        unWind: false,
                        dataSource: []
                    },
                    {
                        plateName: '',
                        list: [
                            {
                                filedLabel: '预期目标',
                                filedName: 'last_plan',
                                filedType: '文本域',
                                placeholder: '',
                                disabled: true,
                                required: false,
                                message: '',
                                value: '',
                            },
                            {
                                filedLabel: '计划经费(万元)',
                                filedName: 'phone',
                                filedType: '文本',
                                nodeType: 'text',
                                placeholder: '',
                                disabled: true,
                                required: false,
                                message: '',
                                value: '',
                            }, {
                                filedLabel: '共使用经费(万元)',
                                filedName: 'phone',
                                filedType: '文本',
                                nodeType: 'text',
                                placeholder: '',
                                disabled: true,
                                required: false,
                                message: '',
                                value: '',
                            },
                            {
                                filedLabel: '附件',
                                filedName: 'enclosure',
                                filedType: '附件',
                                placeholder: '',
                                disabled: true,
                                required: false,
                                message: '',
                                value: [],
                            },
                        ]
                    },
                ]
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_DevelopmentAcceptanceDetail(tableId)
        }
    }

    get_DevelopmentAcceptanceDetail = (tableId) => {
        const { Info } = this.state;
        let data = {
            table_id: tableId
        }
        DevelopmentAcceptanceDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 2 || res.data.Detail.status === 4) {
                        Info.ListInfo[1].list.forEach((item, index) => {
                            if (item.filedLabel === '附件') {
                                if (!res.data.Detail.enclosure || !res.data.Detail.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUserName
                    Info.apply.deName = res.data.Detail.applyUserDeName
                    Info.apply.apply_date = res.data.Detail.created_at
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.ListInfo[0].dataSource = []
                    let result = JSON.parse(res.data.Detail.result)
                    result.forEach((item, index) => {
                        let key = index + 1
                        let dataSource = {
                            key: key,
                            list: [
                                {
                                    filedLabel: '项目成果形式',
                                    filedName: 'shape_' + key,
                                    filedType: '单选',
                                    visible: false,
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: [item.shape],
                                    radioList: [
                                        { value: 1, label: '文献资料综述' },
                                        { value: 2, label: '调查报告' },
                                        { value: 3, label: '研究论文' },
                                        { value: 4, label: '授权专利' },
                                        { value: 5, label: '软件' },
                                        { value: 6, label: '设计' },
                                        { value: 7, label: '硬件研制' },
                                        { value: 8, label: '学术交流报告' },
                                        { value: 9, label: '其他' },
                                    ]
                                },
                                {
                                    filedLabel: '数量',
                                    filedName: 'quantity_' + key,
                                    filedType: '文本',
                                    placeholder: '',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.quantity,
                                },
                            ]
                        }
                        Info.ListInfo[0].dataSource = [...Info.ListInfo[0].dataSource, dataSource]
                    })

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.devel.pro_name
                        } else if (item.filedLabel === '项目编号') {
                            item.value = res.data.Detail.devel.pro_code
                        } else if (item.filedLabel === '部门名称') {
                            item.value = res.data.Detail.devel.deName
                        } else if (item.filedLabel === '项目经费(元)') {
                            item.value = res.data.Detail.devel.pro_money
                        } else if (item.filedLabel === '项目负责人') {
                            item.value = res.data.Detail.devel.pro_manager
                        } else if (item.filedLabel === '合作单位') {
                            item.value = res.data.Detail.devel.coo_unit
                        } else if (item.filedLabel === '联系电话') {
                            item.value = res.data.Detail.devel.phone
                        } else if (item.filedLabel === '开始时间') {
                            item.value = res.data.Detail.devel.start_time ? new Date(res.data.Detail.devel.start_time) : ''
                        } else if (item.filedLabel === '结束时间') {
                            item.value = res.data.Detail.devel.end_time ? new Date(res.data.Detail.devel.end_time) : ''
                        }
                    })

                    Info.ListInfo[1].list.forEach((item, index) => {
                        if (item.filedLabel === '预期目标') {
                            item.value = res.data.Detail.target
                        } else if (item.filedLabel === '计划经费(万元)') {
                            item.value = res.data.Detail.use_money
                        } else if (item.filedLabel === '共使用经费(万元)') {
                            item.value = res.data.Detail.matter
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.Detail.enclosure || []
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleWind = (name) => {
        let { Info } = this.state
        Info.ListInfo.forEach((item, index) => {
            if (item.plateName === name) {
                item.unWind = !item.unWind
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='结题验收申请表' />

                <AddConfig key={FormKey} Info={Info} windEvent={this.handleWind} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
