import http from '../utils/http';

//企业微信登陆授权
function createHtmlAuth(data) {
    return http("post", '/api/createHtmlAuth', data);
}

//微信企业登录
function weComLogin(data) {
    return http("post", '/api/weComLogin', data);
}

//获取用户信息
function userInfo(data) {
    return http("get", '/api/user_info', data);
}

//首页数据
function indexData(data) {
    return http("get", '/api/indexData', data);
}

//流程待办列表
function flowStayList(data) {
    return http("get", '/api/flowStayList', data);
}

//流程审核阅读
function flowReviewRead(data) {
    return http("post", '/api/flowReviewRead', data);
}

//流程待阅列表
function flowSeeLook(data) {
    return http("get", '/api/flowSeeLook', data);
}

//流程待阅阅读
function flowCcRead(data) {
    return http("post", '/api/flowCcRead', data);
}

//周报阅读列表
function userWeeklyLookList(data) {
    return http("get", '/api/userWeeklyLookList', data);
}

//阅读周报
function userReadWeekly(data) {
    return http("post", '/api/userReadWeekly', data);
}

//施工日志列表
function stayReadConstructionLog(data) {
    return http("get", '/api/stayReadConstructionLog', data);
}

//阅读施工日志
function readConstructionLog(data) {
    return http("post", '/api/readConstructionLog', data);
}

//已提交的审批流程列表
function approvalProcess(data) {
    return http("get", '/api/approvalProcess', data);
}

//附件上传
function fileUpload(data) {
    return http("post", '/api/fileUpload', data);
}

//文件下载
function fileDownload(data) {
    return http("post", '/api/fileDownload', data);
}

//文件预览
function filePreview(data) {
    return http("get", '/api/filePreview', data);
}

//附件删除
function deleteFile(data) {
    return http("post", '/api/deleteFile', data);
}

//员工列表
function selectUserName(data) {
    return http("get", '/api/selectUserName', data);
}

//印章列表
function selectAvailableSeal(data) {
    return http("get", '/api/selectAvailableSeal', data);
}

//项目列表
function selectProjectName(data) {
    return http("get", '/api/selectProjectName', data);
}

//流程进行审核
function reviewApplyResult(data) {
    return http("post", '/api/reviewApplyResult', data);
}

//获取部门列表
function getDepartmentJob(data) {
    return http("get", '/api/getDepartmentJob', data);
}

export {
    createHtmlAuth,
    weComLogin,
    userInfo,
    indexData,
    flowStayList,
    flowReviewRead,
    flowSeeLook,
    flowCcRead,
    userWeeklyLookList,
    userReadWeekly,
    stayReadConstructionLog,
    readConstructionLog,
    approvalProcess,
    fileUpload,
    fileDownload,
    filePreview,
    deleteFile,
    selectUserName,
    selectAvailableSeal,
    selectProjectName,
    reviewApplyResult,
    getDepartmentJob
}