import Icon_01 from "../../accets/img/icon_01.png"
import Icon_02 from "../../accets/img/icon_02.png"
import Icon_03 from "../../accets/img/icon_03.png"
import Icon_04 from "../../accets/img/icon_04.png"
import Icon_05 from "../../accets/img/icon_05.png"
import Icon_06 from "../../accets/img/icon_06.png"
import Icon_07 from "../../accets/img/icon_07.png"
import Icon_08 from "../../accets/img/icon_08.png"
import Icon_09 from "../../accets/img/icon_09.png"
import Icon_10 from "../../accets/img/icon_10.png"
import Icon_11 from "../../accets/img/icon_11.png"
import Icon_12 from "../../accets/img/icon_12.png"
import Icon_13 from "../../accets/img/icon_13.png"
import Icon_57 from "../../accets/img/icon_57.png"

const work = [
    {
        key: 1,
        title: '新建周报',
        isOften: true,    //是否为常用
        icon: Icon_01,
        path: '/public/human/weekly/NewItem'
    }, {
        key: 2,
        title: '费用报销单',
        isOften: false,    //是否为常用
        icon: Icon_02,
        path:'/public/payment/reimbursement/List'
    }, {
        key: 3,
        title: '综合/科研费用付款审批',
        isOften: false,    //是否为常用
        icon: Icon_03,
        path:'/public/payment/comprehensive/List'
    }, {
        key: 4,
        title: '员工借款',
        isOften: false,    //是否为常用
        icon: Icon_04,
        path:'/public/payment/borrowing/List'
    }, {
        key: 5,
        title: '补卡申请',
        isOften: false,    //是否为常用
        icon: Icon_05,
        path: '/public/human/reissueCard/List'
    }, {
        key: 6,
        title: '请假申请',
        isOften: false,    //是否为常用
        icon: Icon_06,
        path: '/public/human/leave/List'
    }, {
        key: 7,
        title: '出差申请',
        isOften: false,    //是否为常用
        icon: Icon_07,
        path: '/public/human/evection/List'
    }, {
        key: 8,
        title: '用章申请',
        isOften: false,    //是否为常用
        icon: Icon_08,
        path: '/public/office/chapter/List'
    }, {
        key: 9,
        title: '业务招待申请',
        isOften: false,    //是否为常用
        icon: Icon_09,
        path: '/public/office/business/List'
    }, 
    {
        key: 10,
        title: '工程款支付申请',
        isOften: false,    //是否为常用
        icon: Icon_10,
        path:'/public/payment/engineering/List'
    }, 
    //{
    //     key: 11,
    //     title: '行政设备购置申请',
    //     isOften: false,    //是否为常用
    //     icon: Icon_11
    // }, {
    //     key: 12,
    //     title: '设备购置申请',
    //     isOften: false,    //是否为常用
    //     icon: Icon_12
    // }, 
    {
        key: 13,
        title: '派遣申请表',
        isOften: false,    //是否为常用
        icon: Icon_13,
        path: '/public/human/send/List'
    },
    {
        key: 14,
        title: '出勤签到',
        isOften: false,    //是否为常用
        icon: Icon_57,
        path: '/public/human/attendance/List'
    }
]

export default work