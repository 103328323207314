import React, { Component } from 'react'
import { EyeOutline } from 'antd-mobile-icons'
import { fileDownload, filePreview } from "../utils/api"

export default class FileList extends Component {
    constructor(props) {
        super(props)
        this.state = {};
    }

    get_filePreview = (file) => {
        let data = {
            file_id: file.table_id
        }
        filePreview(data).then(
            (res) => {
                if (!res.code) {
                    let link = document.createElement("a")
                    link.download = file.filename
                    link.href = res.data.path
                    link.target = "_blank"
                    link.click()
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        const { fileList } = this.props
        return (
            <div className='uploadList'>
                {
                    fileList.map((item, index) => {
                        return (
                            <div key={index}>
                                <div>{item.filename}</div>
                                <div>
                                    <div>{item.username}于{item.created_at}上传</div>
                                    {/* <div onClick={() => {
                                            this.get_filePreview(item)
                                        }}>
                                        <EyeOutline color="#3396FB" fontSize={18}  />
                                    </div> */}
                                </div>
                            </div>)
                    })
                }
            </div>
        )
    }
}
