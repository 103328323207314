import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import dayjs from 'dayjs'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import UserPopup from "../../../components/UserPopup"
import { createAwayApply, AwayDetail } from "../../../utils/oa"
import { selectUserName, reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_07.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                apply: {
                    title: '出差申请',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '预计出发时间',
                        filedName: 'start_date',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'minute',
                        placeholder: '',
                        disabled: false,
                        required: true,
                        message: '请选择出发时间',
                        value: '',
                    },
                    {
                        filedLabel: '预计返回时间',
                        filedName: 'end_date',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'minute',
                        placeholder: '',
                        disabled: false,
                        required: true,
                        message: '请选择返回时间',
                        value: '',
                    },
                    {
                        filedLabel: '出差天数',
                        filedName: 'date_num',
                        filedType: '文本',
                        nodeType: 'number',
                        placeholder: '请输入出差天数',
                        disabled: false,
                        required: true,
                        message: '请输入出差天数',
                        value: '',
                    },
                    {
                        filedLabel: '交通工具',
                        filedName: 'tool',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: false,
                        required: true,
                        message: '请选择交通工具',
                        value: [],
                        radioList: [
                            {
                                value: 1,
                                label: "火车"
                            },
                            {
                                value: 2,
                                label: "飞机"
                            },
                            {
                                value: 3,
                                label: "飞机+火车"
                            },
                            {
                                value: 4,
                                label: "高铁(动车)"
                            },
                            {
                                value: 5,
                                label: "飞机+高铁(动车)"
                            },
                            {
                                value: 6,
                                label: "其他"
                            },
                        ]
                    },
                    {
                        filedLabel: '申请人职位',
                        filedName: 'apply_job',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: false,
                        required: true,
                        message: '请选择申请人职位',
                        value: [],
                        radioList: [
                            {
                                value: 1,
                                label: "普通职员"
                            },
                            {
                                value: 2,
                                label: "部门经理"
                            },
                            {
                                value: 3,
                                label: "副总经理"
                            },
                        ]
                    },
                    {
                        filedLabel: '同行人员',
                        filedName: 'peer',
                        filedType: '抄送',
                        disabled: false,
                        required: true,
                        message: '',
                        value: [],
                    },
                    {
                        filedLabel: '出差地点',
                        filedName: 'address',
                        filedType: '文本域',
                        placeholder: '请输入出差地点',
                        disabled: false,
                        required: true,
                        message: '请输入出差地点',
                        value: '',
                    },
                    {
                        filedLabel: '出差事由',
                        filedName: 'matter',
                        filedType: '文本域',
                        placeholder: '请输入出差事由',
                        disabled: false,
                        required: true,
                        message: '请输入出差事由',
                        value: '',
                    },
                    {
                        filedLabel: '票务订购',
                        filedName: 'is_booking',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                label: '是',
                                value: 1,
                            }, {
                                label: '否',
                                value: 2,
                            },
                        ]
                    },
                ],
                ListInfo: [
                    {
                        plateName: '票务订购明细',
                        unWind: false,
                        unShow: true,
                        dataSource: [
                            {
                                key: 1,
                                user_id: '',
                                list: [
                                    {
                                        filedLabel: '姓名',
                                        filedName: 'name_1',
                                        filedType: '抄送',
                                        disabled: false,
                                        required: true,
                                        message: '',
                                        value: [],
                                    },
                                    {
                                        filedLabel: '部门',
                                        filedName: 'deName_1',
                                        filedType: '默认',
                                        placeholder: '',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '事由',
                                        filedName: 'matter_1',
                                        filedType: '文本',
                                        placeholder: '请输入事由',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '票务类型',
                                        filedName: 'tool_1',
                                        filedType: '文本',
                                        placeholder: '请输入票务类型',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '出发地',
                                        filedName: 'start_address_1',
                                        filedType: '文本',
                                        placeholder: '请输入出发地',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '目的地',
                                        filedName: 'end_address_1',
                                        filedType: '文本',
                                        placeholder: '请输入目的地',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: '',
                                    },
                                    {
                                        filedLabel: '出发时间',
                                        filedName: 'date_detail_1',
                                        filedType: '日期',
                                        visible: false,
                                        nodeType: 'second',
                                        placeholder: '',
                                        disabled: false,
                                        required: true,
                                        message: '请选择出发时间',
                                        value: '',
                                    },
                                ]
                            }
                        ]
                    },
                ]
            },
            Form: {},
            userName: '',
            popupName: '',
            popupTitle: '',
            popup: {
                isShow: false,
                title: '选择人员',
                placeholder: '输入姓名查询人员',
                multiple: false,
                disabled: false,
                dataSource: []
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_AwayDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let { Info } = this.state;
        let userInfo = storage.getItem('userInfo');

        Info.apply.userName = userInfo.name
        Info.apply.deName = userInfo.deName
        Info.apply.apply_date = ''

        this.setState(preState => ({
            Info
        })
        )
    }

    get_AwayDetail = (tableId) => {
        const { Info, Form } = this.state;
        let data = {
            table_id: tableId
        }
        AwayDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        Form.table_id = tableId
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUserName
                    Info.apply.deName = res.data.Detail.deName
                    Info.apply.apply_date = res.data.Detail.apply_date
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '预计出发时间') {
                            item.value = new Date(res.data.Detail.start_date)
                        } else if (item.filedLabel === '预计返回时间') {
                            item.value = new Date(res.data.Detail.end_date)
                        } else if (item.filedLabel === '出差天数') {
                            item.value = res.data.Detail.date_num
                        } else if (item.filedLabel === '交通工具') {
                            item.value = []
                            item.value = [...item.value, Number(res.data.Detail.tool)]
                        } else if (item.filedLabel === '申请人职位') {
                            item.value = []
                            item.value = [...item.value, Number(res.data.Detail.apply_job)]
                        } else if (item.filedLabel === '同行人员') {
                            item.value = []
                            if (res.data.Detail.peerUserName) {
                                res.data.Detail.peerUserName.forEach((em, ex) => {
                                    let value = {
                                        label: em.userName,
                                        value: em.table_id
                                    }
                                    item.value = [...item.value, value]
                                })
                            }
                        } else if (item.filedLabel === '出差地点') {
                            item.value = res.data.Detail.address
                        } else if (item.filedLabel === '出差事由') {
                            item.value = res.data.Detail.matter
                        } else if (item.filedLabel === '票务订购') {
                            item.value = []
                            item.value = [...item.value, Number(res.data.Detail.is_booking)]
                        }
                    })

                    if (Number(res.data.Detail.is_booking) === 1) {
                        Info.ListInfo[0].unShow = false
                    }

                    if (res.data.Detail.bookings) {
                        let dataSource = res.data.Detail.bookings
                        Info.ListInfo[0].dataSource = []
                        dataSource.forEach((item, index) => {
                            let key = index + 1
                            let value = {
                                key: key,
                                user_id: item.user_id,
                                list: [
                                    {
                                        filedLabel: '姓名',
                                        filedName: 'name_' + key,
                                        filedType: '抄送',
                                        disabled: true,
                                        required: true,
                                        message: '',
                                        value: [
                                            {
                                                label: item.applyUserName,
                                                value: item.user_id
                                            }
                                        ],
                                    },
                                    {
                                        filedLabel: '部门',
                                        filedName: 'deName_' + key,
                                        filedType: '默认',
                                        placeholder: '',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: item.deName,
                                    },
                                    {
                                        filedLabel: '事由',
                                        filedName: 'matter_' + key,
                                        filedType: '文本',
                                        placeholder: '请输入事由',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: item.matter,
                                    },
                                    {
                                        filedLabel: '票务类型',
                                        filedName: 'tool_' + key,
                                        filedType: '文本',
                                        placeholder: '请输入票务类型',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: item.tool,
                                    },
                                    {
                                        filedLabel: '出发地',
                                        filedName: 'start_address_' + key,
                                        filedType: '文本',
                                        placeholder: '请输入出发地',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: item.start_address,
                                    },
                                    {
                                        filedLabel: '目的地',
                                        filedName: 'end_address_' + key,
                                        filedType: '文本',
                                        placeholder: '请输入目的地',
                                        disabled: false,
                                        required: false,
                                        message: '',
                                        value: item.end_address,
                                    },
                                    {
                                        filedLabel: '出发时间',
                                        filedName: 'date_detail_' + key,
                                        filedType: '日期',
                                        visible: false,
                                        nodeType: 'second',
                                        placeholder: '',
                                        disabled: false,
                                        required: true,
                                        message: '请选择出发时间',
                                        value: new Date(item.date_detail)
                                    },
                                ]
                            }
                            Info.ListInfo[0].dataSource = [...Info.ListInfo[0].dataSource, value]
                        })
                    }


                    this.setState(preState => ({
                        Info,
                        Form,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleVisible = (title, name) => {
        let { Info } = this.state
        if (title === '预计出发时间' || title === '预计返回时间' || title === '交通工具' || title === '申请人职位' || title === '票务订购') {
            Info.TableInfo.forEach((item, index) => {
                if (item.filedLabel === title) {
                    item.visible = true
                }
            })
        } else if (title === '出发时间') {
            Info.ListInfo[0].dataSource.forEach((item, index) => {
                item.list.forEach((em, ex) => {
                    if (em.filedName === name) {
                        em.visible = true
                    }
                })
            })
        }
        this.setState(preState => ({
            Info
        })
        )
    }

    handleDate = (title, name) => {
        let { Info } = this.state
        if (title === '预计出发时间' || title === '预计返回时间' || title === '交通工具' || title === '申请人职位' || title === '票务订购') {
            Info.TableInfo.forEach((item, index) => {
                if (item.filedLabel === title) {
                    item.visible = false
                }
            })
        } else if (title === '出发时间') {
            Info.ListInfo[0].dataSource.forEach((item, index) => {
                item.list.forEach((em, ex) => {
                    if (em.filedName === name) {
                        em.visible = false
                    }
                })
            })
        }
        this.setState(preState => ({
            Info
        })
        )
    }

    handleRadio = (title, value) => {
        let { Info } = this.state
        if (value[0] === 1) {
            Info.ListInfo[0].unShow = false
        } else {
            Info.ListInfo[0].unShow = true
        }
        this.setState(preState => ({
            Info
        })
        )
    }

    handleButton = (title, name) => {
        if (title === '同行人员') {
            this.get_InitialValue()
        } else if (title === '姓名') {
            this.get_InitialValue()
        }
        console.log(name)
        this.setState(preState => ({
            popupTitle: title,
            popupName: name || ''
        }))
    }

    handleClose = () => {
        this.setState(preState => ({
            userName: '',
            popupTitle: '',
            popupName: '',
            popup: {
                isShow: false,
                placeholder: '输入姓名查询人员',
                multiple: false,
                disabled: false,
                dataSource: []
            }
        }))
    }

    handleQuery = (value) => {
        this.setState(preState => ({
            userName: value,
        }), () => {
            this.get_InitialValue()
        })
    }

    handleSelect = (value) => {
        let { popup, Info, popupTitle, popupName } = this.state
        let selectList = []

        if (value.length) {
            value.forEach((item, index) => {
                popup.dataSource.forEach((em, ex) => {
                    if (em.value === item) {
                        selectList = [...selectList, em]
                    }
                })
            })
        }

        if (popupTitle === '同行人员') {
            Info.TableInfo.forEach((item, index) => {
                if (item.filedLabel === '同行人员') {
                    item.value = [...item.value, ...selectList]
                }
            })
        } else {
            console.log(selectList)
            Info.ListInfo[0].dataSource.forEach((item, index) => {
                item.list.forEach((em, ex) => {
                    if (em.filedName === popupName) {
                        item.user_id = selectList[0].value
                        if (em.filedLabel === '姓名') {
                            em.value = []
                            em.value = [...em.value, ...selectList]
                        }


                        Info.ListInfo[0].dataSource[index].list.forEach((e, x) => {
                            if (e.filedLabel === '部门') {
                                e.value = selectList[0].deName
                            }
                        })
                    }
                })
            })
        }


        this.setState(preState => ({
            Info,
            userName: '',
            popupTitle: '',
            popupName: '',
            popup: {
                isShow: false,
                placeholder: '输入姓名查询人员',
                multiple: false,
                disabled: false,
                dataSource: []
            }
        }))
    }

    handleRemove = (value) => {
        let { Info, popupTitle } = this.state

        if (popupTitle === '同行人员') {
            Info.TableInfo.forEach((item, index) => {
                if (item.filedLabel === '同行人员') {
                    item.value.forEach((em, ex) => {
                        if (em.value === value) {
                            item.value.splice(ex, 1)
                        }
                    })
                }
            })
        } else {

        }


        this.setState(preState => ({
            Info
        }))
    }

    get_InitialValue = () => {
        let { popup, userName } = this.state
        let data = {
            username: userName,
            page: 1,
            limit: 500
        }
        selectUserName(data).then(
            (res) => {
                if (!res.code) {
                    popup.dataSource = []
                    if (res.data.users.length) {
                        res.data.users.forEach((item, index) => {
                            let user = {
                                value: item.table_id,
                                label: item.username,
                                deName: item.deName,
                            }
                            popup.dataSource = [...popup.dataSource, user]
                        })
                        popup.isShow = true
                    }
                    this.setState(preState => ({
                        popup,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleWind = (name) => {
        let { Info } = this.state
        Info.ListInfo.forEach((item, index) => {
            if (item.plateName === name) {
                item.unWind = !item.unWind
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleNew = (name) => {
        let { Info } = this.state
        let key = Info.ListInfo[0].dataSource.length + 1
        let value = {
            key: key,
            user_id: '',
            list: [
                {
                    filedLabel: '姓名',
                    filedName: 'name_' + key,
                    filedType: '抄送',
                    disabled: false,
                    required: true,
                    message: '',
                    value: [],
                },
                {
                    filedLabel: '部门',
                    filedName: 'deName_' + key,
                    filedType: '默认',
                    placeholder: '',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '事由',
                    filedName: 'matter_' + key,
                    filedType: '文本',
                    placeholder: '请输入事由',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '票务类型',
                    filedName: 'tool_' + key,
                    filedType: '文本',
                    placeholder: '请输入票务类型',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '出发地',
                    filedName: 'start_address_' + key,
                    filedType: '文本',
                    placeholder: '请输入出发地',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '目的地',
                    filedName: 'end_address_' + key,
                    filedType: '文本',
                    placeholder: '请输入目的地',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '出发时间',
                    filedName: 'date_detail_' + key,
                    filedType: '日期',
                    visible: false,
                    nodeType: 'second',
                    placeholder: '',
                    disabled: false,
                    required: true,
                    message: '请选择触发时间',
                    value: '',
                },
            ]
        }
        Info.ListInfo[0].dataSource = [...Info.ListInfo[0].dataSource, value]
        this.setState(preState => ({
            Info
        })
        )
    }

    handlePresent = (data) => {
        const { Form, Info } = this.state
        let date = new Date()
        Form.status = 2
        Form.apply_date = dayjs(date).format('YYYY-MM-DD')
        Form.start_date = dayjs(data.start_date).format('YYYY-MM-DD HH:mm:ss')
        Form.end_date = dayjs(data.end_date).format('YYYY-MM-DD HH:mm:ss')
        Form.date_num = data.date_num
        Form.tool = data.tool[0]
        Form.is_booking = data.is_booking[0]
        Form.apply_job = data.apply_job[0]
        Form.address = data.address
        Form.matter = data.matter

        Info.TableInfo.forEach((item, index) => {
            if (item.filedLabel === '同行人员') {
                if (item.value.length) {
                    let peer_user = []
                    item.value.forEach((em, ex) => {
                        peer_user.push(em.value)
                    })
                    Form.peer_user = JSON.stringify(peer_user)
                }
            }
        })

        Form.bookings = []
        Info.ListInfo[0].dataSource.forEach((item, index) => {
            let value = {
                key: item.key,
                user_id: item.user_id,
                matter: '',
                tool: '',
                start_address: '',
                end_address: '',
                date_detail: ''
            }
            item.list.forEach((em, ex) => {
                value.matter = data['matter_' + item.key]
                value.tool = data['tool_' + item.key]
                value.start_address = data['start_address_' + item.key]
                value.end_address = data['end_address_' + item.key]
                value.date_detail = dayjs(data['date_detail_' + item.key]).format('YYYY-MM-DD HH:mm:ss')
            })
            Form.bookings = [...Form.bookings, value]
        })

        Form.bookings = JSON.stringify(Form.bookings)

        createAwayApply(Form).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '提交成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '提交失败,请检查表单!',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info, popup } = this.state
        return (
            <div>
                <NavBar menuName='新增出差申请' />

                <AddConfig key={FormKey} Info={Info} buttonEvent={this.handleButton} removeEvent={this.handleRemove} visibleEvent={this.handleVisible} dateEvent={this.handleDate} windEvent={this.handleWind} newEvent={this.handleNew} radioEvent={this.handleRadio} presentEvent={this.handlePresent} auditEvent={this.handleAudit} />

                <UserPopup popup={popup} closeEvent={this.handleClose} queryEvent={this.handleQuery} selectEvent={this.handleSelect} />
            </div>
        )
    }
}

export default withRouter(NewItem)
