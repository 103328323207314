import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { userQuitDetail } from "../../../utils/oa"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_43.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                apply: {
                    title: '离职办理',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '离职人姓名',
                        filedName: 'username',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '部门名称',
                        filedName: 'deName',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '职位名称',
                        filedName: 'jobName',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '离职时间',
                        filedName: 'quit_time',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '离职类型',
                        filedName: 'quit_class',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: [],
                        radioList: [
                            { value: 1, label: "辞职" },
                            { value: 2, label: "辞退" },
                            { value: 3, label: "开除" },
                            { value: 4, label: "退休" },
                            { value: 5, label: "合同到期" },
                            { value: 6, label: "其他" },
                        ]
                    },
                    {
                        filedLabel: '离职原因',
                        filedName: 'quit_reason',
                        filedType: '多选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: true,
                        message: '',
                        value: [],
                        selectList: [
                            { value: '1', label: "家庭因素" },
                            { value: '2', label: "合同到期" },
                            { value: '3', label: "晋升机会" },
                            { value: '4', label: "工作时间长" },
                            { value: '5', label: "上学进修" },
                            { value: '6', label: "福利不佳" },
                            { value: '7', label: "健康因素" },
                            { value: '8', label: "人际关系" },
                            { value: '9', label: "交通不便" },
                            { value: '10', label: "无法适应倒班" },
                            { value: '11', label: "无法调转人事关系" },
                            { value: '12', label: "工作环境" },
                            { value: '13', label: "其他" },
                        ]
                    },
                    {
                        filedLabel: '员工离职附件',
                        filedName: 'user_enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                    {
                        filedLabel: '行政面谈记录',
                        filedName: 'admin_log',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '行政面谈记录附件',
                        filedName: 'admin_enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                    {
                        filedLabel: '主管面谈记录',
                        filedName: 'head_log',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '主管面谈记录附件',
                        filedName: 'head_enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                ]
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_userQuitDetail(tableId)
        }
    }

    get_userQuitDetail = (tableId) => {
        const { Info, Form } = this.state;
        let data = {
            table_id: tableId
        }
        userQuitDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 2 || res.data.Detail.status === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '员工离职附件') {
                                if (!res.data.Detail.user_enclosure.length) {
                                    item.unShow = true
                                }
                            } else if (item.filedLabel === '行政面谈记录附件') {
                                if (!res.data.Detail.admin_enclosure.length) {
                                    item.unShow = true
                                }
                            } else if (item.filedLabel === '主管面谈记录附件') {
                                if (!res.data.Detail.head_enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUserName
                    Info.apply.deName = res.data.Detail.applyUserDeName
                    Info.apply.apply_date = res.data.Detail.apply_date
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '离职人姓名') {
                            item.value = res.data.Detail.quitName
                        } else if (item.filedLabel === '部门名称') {
                            item.value = res.data.Detail.deName
                        } else if (item.filedLabel === '职位名称') {
                            item.value = res.data.Detail.jobName
                        } else if (item.filedLabel === '离职时间') {
                            item.value = res.data.Detail.quit_time ? new Date(res.data.Detail.quit_time) : ''
                        } else if (item.filedLabel === '离职类型') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.quit_class]
                        } else if (item.filedLabel === '离职原因') {
                            item.value = []
                            item.value = [...item.value, JSON.parse(res.data.Detail.quit_reason)[0]]
                        } else if (item.filedLabel === '行政面谈记录') {
                            item.value = res.data.Detail.admin_log
                        } else if (item.filedLabel === '主管面谈记录') {
                            item.value = res.data.Detail.head_log
                        } else if (item.filedLabel === '员工离职附件') {
                            item.value = res.data.Detail.user_enclosure
                        } else if (item.filedLabel === '行政面谈记录附件') {
                            item.value = res.data.Detail.admin_enclosure
                        } else if (item.filedLabel === '主管面谈记录附件') {
                            item.value = res.data.Detail.head_enclosure
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        Form,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='离职办理' />

                <AddConfig key={FormKey} Info={Info} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
