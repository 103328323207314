import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import dayjs from 'dayjs'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { createUserLoan, userLoanDetail } from "../../../utils/oa"
import { selectProjectName, reviewApplyResult } from "../../../utils/api"
import UserPopup from "../../../components/UserPopup"
import Icon from "../../../accets/img/icon_04.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                apply: {
                    title: '借款申请',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '借款用途',
                        filedName: 'use',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入借款用途',
                        disabled: false,
                        required: true,
                        message: '请输入借款用途',
                        value: '',
                    },
                    {
                        filedLabel: '借款类型',
                        filedName: 'class',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入借款类型',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '本次借款金额(元)',
                        filedName: 'money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入本次借款金额(元)',
                        disabled: false,
                        required: true,
                        message: '请输入本次借款金额(元)',
                        value: '',
                    },
                    {
                        filedLabel: '预计还款时间',
                        filedName: 'repayment_date',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: false,
                        required: true,
                        message: '请选择',
                        value: '',
                    },
                ]
            },
            Form: {},
            popup: {
                isShow: false,
                title: '选择项目',
                placeholder: '输入项目名称查询',
                multiple: false,
                disabled: false,
                dataSource: []
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_userLoanDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let { Info, Form } = this.state;
        let userInfo = storage.getItem('userInfo');

        Info.apply.userName = userInfo.name
        Info.apply.deName = userInfo.deName
        Info.apply.apply_date = ''
        Form.bus_de = userInfo.deName

        this.setState(preState => ({
            Info
        })
        )
    }

    get_userLoanDetail = (tableId) => {
        const { Info, Form } = this.state;
        let data = {
            table_id: tableId
        }
        userLoanDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        Form.table_id = tableId
                        Form.project_id = res.data.Detail.project_id
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (item.filedLabel === '附件') {
                                if (!res.data.Detail.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.username
                    Info.apply.deName = res.data.Detail.bus_de
                    Info.apply.apply_date = res.data.Detail.apply_date
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.projectName
                        } else if (item.filedLabel === '借款用途') {
                            item.value = res.data.Detail.use
                        } else if (item.filedLabel === '借款类型') {
                            item.value = res.data.Detail.class
                        } else if (item.filedLabel === '本次借款金额(元)') {
                            item.value = res.data.Detail.money
                        } else if (item.filedLabel === '预计还款时间') {
                            item.value = new Date(res.data.Detail.repayment_date)
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        Form,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_selectProjectName = () => {
        let { popup, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: 1,
            limit: 500
        }
        selectProjectName(data).then(
            (res) => {
                if (!res.code) {
                    popup.dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let pro = {
                                value: item.table_id,
                                label: item.pro_name,
                            }
                            popup.dataSource = [...popup.dataSource, pro]
                        })

                        popup.isShow = true
                    }
                    this.setState(preState => ({
                        popup,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = () => {
        this.get_selectProjectName()
    }

    handleVisible = (title) => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.filedLabel === title) {
                item.visible = true
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleQuery = (value) => {
        this.setState(preState => ({
            pro_name: value,
        }), () => {
            this.get_selectProjectName()
        })
    }

    handleClose = () => {
        this.setState(preState => ({
            userName: '',
            pro_name: '',
            popup: {
                isShow: false,
                title: '选择项目',
                placeholder: '输入项目名称查询',
                multiple: false,
                disabled: false,
                dataSource: []
            }
        }))
    }

    handleSelect = (value) => {
        let { popup, Info, Form } = this.state
        if (value.length) {
            value.forEach((item, index) => {
                popup.dataSource.forEach((em, ex) => {
                    if (em.value === item) {
                        Info.TableInfo.forEach((m, x) => {
                            if (m.filedLabel === '项目名称') {
                                m.value = em.label
                                Form.project_id = em.value
                            }
                        })
                    }
                })
            })
        }
        this.setState(preState => ({
            Info,
            Form,
            pro_name: '',
            popup: {
                isShow: false,
                title: '选择项目',
                placeholder: '输入项目名称查询',
                multiple: false,
                disabled: false,
                dataSource: []
            }
        }))
    }

    handleDate = (title) => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.filedLabel === title) {
                item.visible = false
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handlePresent = (data) => {
        const { Info, Form } = this.state
        let date = new Date()
        Form.status = 2
        Form.apply_date = dayjs(date).format('YYYY-MM-DD')
        Form.class = data.class
        Form.money_class = 1
        Form.use = data.use
        Form.money = data.money
        Form.repayment_date = dayjs(data.repayment_date).format('YYYY-MM-DD')

        createUserLoan(Form).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '提交成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '提交失败,请检查表单!',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info, popup } = this.state
        return (
            <div>
                <NavBar menuName='新增借款申请' />

                <AddConfig key={FormKey} Info={Info} buttonEvent={this.handleButton} visibleEvent={this.handleVisible} dateEvent={this.handleDate} presentEvent={this.handlePresent} auditEvent={this.handleAudit} />

                <UserPopup popup={popup} closeEvent={this.handleClose} queryEvent={this.handleQuery} selectEvent={this.handleSelect} />
            </div>
        )
    }
}

export default withRouter(NewItem)
