import React, { Component } from 'react'
import { Picker, Space } from 'antd-mobile'
import { RightOutline } from 'antd-mobile-icons'

export default class UserRadio extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        let { visible, radioList, title, name, value, onChange } = this.props
        return (
            <Picker
                visible={visible}
                columns={[radioList]}
                value={value}
                onClose={() => this.props.pickerEvent(title, name)}
                onConfirm={(e) => {
                    onChange(e)
                    if (title === '补卡原因' || title === '涉及工程资料' || title === '票务订购' || title === '费用类型') {
                        this.props.radioEvent(title, e)
                    }
                }}
                style={{
                    borderTopLeftRadius: '15px',
                    borderTopRightRadius: '15px',
                    minHeight: '40vh',
                    backgroundColor: '#FFFFFF'
                }}
            >
                {(items, { open }) => {
                    return (
                        <Space align='center'>
                            {
                                items.every(item => item === null) ? '请选择' : items.map(item => item?.label ?? '未选择')
                            }
                            <RightOutline color='#999999' fontSize={15} />
                        </Space>
                    )
                }}
            </Picker>
        )
    }
}
