import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { DataTransferDetail } from "../../../utils/project"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_34.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '资料移交单',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '资料类型',
                        filedName: 'data_class',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                label: '进度',
                                value: 1,
                            },
                            {
                                label: '计算',
                                value: 2,
                            },
                            {
                                label: '发票',
                                value: 3,
                            },
                            {
                                label: '合同',
                                value: 4,
                            }
                        ]
                    },
                    {
                        filedLabel: '移交部门',
                        filedName: 'tax_invo',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                label: '集采中心',
                                value: 1,
                            },
                            {
                                label: '资料中心',
                                value: 2,
                            },
                        ]
                    },
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '移交日期',
                        filedName: 'tran_day',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'day',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '文件类型',
                        filedName: 'invo_class',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                label: '材料',
                                value: 1,
                            },
                            {
                                label: '机械',
                                value: 2,
                            },
                            {
                                label: '劳务',
                                value: 3,
                            },
                        ]
                    },
                    {
                        filedLabel: '接收人',
                        filedName: 'receive_user',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '接收部门',
                        filedName: 'recipient_deName',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '供应商名称',
                        filedName: 'sup_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    }, {
                        filedLabel: '本月完成产值(元)',
                        filedName: 'adm_price',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '计量月份',
                        filedName: 'number',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            { value: 1, label: "1月" },
                            { value: 2, label: "2月" },
                            { value: 3, label: "3月" },
                            { value: 4, label: "4月" },
                            { value: 5, label: "5月" },
                            { value: 6, label: "6月" },
                            { value: 7, label: "7月" },
                            { value: 8, label: "8月" },
                            { value: 9, label: "9月" },
                            { value: 10, label: "10月" },
                            { value: 11, label: "11月" },
                            { value: 12, label: "12月" },
                        ],
                        unShow: true
                    }, {
                        filedLabel: '至本月累计完成产值(元)',
                        filedName: 'invoice_code',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '结算金额(元)',
                        filedName: 'adm_price',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '发票类型',
                        filedName: 'invo_class',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                value: 1,
                                label: '增值税专用发票'
                            },
                            {
                                value: 2,
                                label: '增值税普通发票'
                            },
                            {
                                value: 3,
                                label: '免税发票'
                            },
                        ],
                        unShow: true
                    }, {
                        filedLabel: '税率(认价)',
                        filedName: 'adm_price',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                value: 1,
                                label: '0%'
                            },
                            {
                                value: 2,
                                label: '1%'
                            },
                            {
                                value: 3,
                                label: '3%'
                            },
                            {
                                value: 4,
                                label: '6%'
                            },
                            {
                                value: 5,
                                label: '9%'
                            },
                            {
                                value: 6,
                                label: '13%'
                            },
                        ],
                        unShow: true
                    }, {
                        filedLabel: '税率(开票)',
                        filedName: 'tax_invo',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                value: 1,
                                label: '0%'
                            },
                            {
                                value: 2,
                                label: '1%'
                            },
                            {
                                value: 3,
                                label: '3%'
                            },
                            {
                                value: 4,
                                label: '6%'
                            },
                            {
                                value: 5,
                                label: '9%'
                            },
                            {
                                value: 6,
                                label: '13%'
                            },
                        ],
                        unShow: true
                    },
                    {
                        filedLabel: '发票编号',
                        filedName: 'invoice_code',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '发票份数',
                        filedName: 'number',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '本期金额(元)',
                        filedName: 'money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '有无OFD',
                        filedName: 'ofd',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                value: 1,
                                label: '有'
                            },
                            {
                                value: 2,
                                label: '无'
                            },
                        ],
                        unShow: true
                    },
                    {
                        filedLabel: '累计已开票金额(元)',
                        filedName: 'cumulative_billing',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '合同名称',
                        filedName: 'con_name',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '合同编号',
                        filedName: 'invoice_code',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '合同金额(元)',
                        filedName: 'money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '文件份数',
                        filedName: 'number',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '备注',
                        filedName: 'info',
                        filedType: '文本域',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: true,
                        required: false,
                        message: '',
                        value: [],
                    },
                ]
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_DataTransferDetail(tableId)
        }
    }

    get_DataTransferDetail = (tableId) => {
        const { Info } = this.state;
        let data = {
            table_id: tableId
        }
        DataTransferDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 2 || res.data.Detail.status === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '附件') {
                                if (!res.data.Detail.enclosure || !res.data.Detail.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUsername
                    Info.apply.deName = res.data.Detail.applyUserDename
                    Info.apply.apply_date = res.data.Detail.created_at
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    if (res.data.Detail.data_class === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '供应商名称') {
                                item.unShow = false
                                item.value = res.data.Detail.supplierName
                            } else if (item.filedLabel === '本月完成产值(元)') {
                                item.unShow = false
                                item.value = res.data.Detail.adm_price
                            } else if (item.filedLabel === '计量月份') {
                                item.unShow = false
                                item.value = []
                                item.value = [...item.value, res.data.Detail.number]
                            } else if (item.filedLabel === '至本月累计完成产值(元)') {
                                item.unShow = false
                                item.value = res.data.Detail.invoice_code
                            }
                        })
                    } else if (res.data.Detail.data_class === 2) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '供应商名称') {
                                item.unShow = false
                                item.value = res.data.Detail.supplierName
                            } else if (item.filedLabel === '结算金额(元)') {
                                item.unShow = false
                                item.value = res.data.Detail.adm_price
                            } else if (item.filedLabel === '至本月累计完成产值(元)') {
                                item.unShow = false
                                item.value = res.data.Detail.invoice_code
                            }
                        })
                    } else if (res.data.Detail.data_class === 3) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '供应商名称') {
                                item.unShow = false
                                item.value = res.data.Detail.supplierName
                            } else if (item.filedLabel === '发票类型') {
                                item.unShow = false
                                item.value = []
                                item.value = [...item.value, res.data.Detail.invo_class]
                            } else if (item.filedLabel === '税率(认价)') {
                                item.unShow = false
                                item.value = []
                                item.value = [...item.value, res.data.Detail.adm_price]
                            } else if (item.filedLabel === '税率(开票)') {
                                item.unShow = false
                                item.value = []
                                item.value = [...item.value, res.data.Detail.tax_invo]
                            } else if (item.filedLabel === '发票编号') {
                                item.unShow = false
                                item.value = res.data.Detail.invoice_code
                            } else if (item.filedLabel === '发票份数') {
                                item.unShow = false
                                item.value = res.data.Detail.number
                            } else if (item.filedLabel === '本期金额(元)') {
                                item.unShow = false
                                item.value = res.data.Detail.money
                            } else if (item.filedLabel === '有无OFD') {
                                item.unShow = false
                                item.value = res.data.Detail.ofd
                            } else if (item.filedLabel === '累计已开票金额(元)') {
                                item.unShow = false
                                item.value = res.data.Detail.cumulative_billing
                            }
                        })
                    } else if (res.data.Detail.data_class === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '合同名称') {
                                item.unShow = false
                                item.value = res.data.Detail.supplierName
                            } else if (item.filedLabel === '合同编号') {
                                item.unShow = false
                                item.value = res.data.Detail.invoice_code
                            } else if (item.filedLabel === '合同金额(元)') {
                                item.unShow = false
                                item.value = res.data.Detail.money
                            } else if (item.filedLabel === '文件份数') {
                                item.unShow = false
                                item.value = res.data.Detail.number
                            }
                        })
                    }


                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '资料类型') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.data_class]
                        } else if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.proName
                        }else if (item.filedLabel === '移交部门') {
                            item.value = []
                            item.value = [...item.value, Number(res.data.Detail.tax_invo)]
                        } else if (item.filedLabel === '移交日期') {
                            item.value=res.data.Detail.tran_day ? new Date(res.data.Detail.tran_day) : ''
                        } else if (item.filedLabel === '文件类型') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.invo_class]
                        } else if (item.filedLabel === '接收人') {
                            item.value = res.data.Detail.receiveUsername
                        } else if (item.filedLabel === '接收部门') {
                            item.value = res.data.Detail.receiveUserDename
                        } else if (item.filedLabel === '备注') {
                            item.value = res.data.Detail.info
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.Detail.enclosure || []
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='资料移交单' />

                <AddConfig key={FormKey} Info={Info} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
