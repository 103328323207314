import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import dayjs from 'dayjs'
import AMapLoader from '@amap/amap-jsapi-loader';
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { createCardReplacement, lookCardRellacDetail } from "../../../utils/oa"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_05.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Info: {
                apply: {
                    title: '补卡申请',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '补卡开始时间',
                        filedName: 'start',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'minute',
                        placeholder: '',
                        disabled: false,
                        required: true,
                        message: '请选择开始日期',
                        value: '',
                    },
                    {
                        filedLabel: '补卡结束时间',
                        filedName: 'end',
                        filedType: '日期',
                        visible: false,
                        nodeType: 'minute',
                        placeholder: '',
                        disabled: false,
                        required: true,
                        message: '请选择结束日期',
                        value: '',
                    },
                    {
                        filedLabel: '补卡原因',
                        filedName: 'reason',
                        filedType: '单选',
                        visible: false,
                        placeholder: '',
                        disabled: false,
                        required: true,
                        message: '请选择补卡原因',
                        value: [],
                        radioList: [
                            {
                                label: '外出公干',
                                value: 2,
                            }, {
                                label: '刷卡机故障',
                                value: 3,
                            },
                        ]
                    },
                    {
                        filedLabel: '位置',
                        filedName: 'address',
                        filedType: '默认',
                        // nodeType: 'text',
                        // placeholder: '请输入位置',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                        unShow: true
                    },
                    {
                        filedLabel: '备注',
                        filedName: 'info',
                        filedType: '文本域',
                        placeholder: '请输入备注',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: [],
                        unShow: true
                    },
                ]
            },
            Form: {},
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_lookCardRellacDetail(tableId)
        } else {
            this.get_userInfo()
            this.get_Geolocation()
        }
    }

    get_userInfo = () => {
        let { Info } = this.state;
        let userInfo = storage.getItem('userInfo');

        Info.apply.userName = userInfo.name
        Info.apply.deName = userInfo.deName
        Info.apply.apply_date = ''

        this.setState(preState => ({
            Info
        })
        )
    }

    get_Geolocation() {
        AMapLoader.load({
            key: "e091f26fd34d16a2e7d93f6779b4b838",                     // 申请好的Web端开发者Key，首次调用 load 时必填
            version: "2.0",              // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            plugins: [''],               // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        }).then((AMap) => {
            AMap.plugin(['AMap.Geolocation', 'AMap.Geocoder'], function () {
                var geolocation = new window.AMap.Geolocation({
                    enableHighAccuracy: true, // 是否使用高精度定位，默认：true
                    timeout: 10000, // 设置定位超时时间，默认：无穷大
                    offset: [10, 20],  // 定位按钮的停靠位置的偏移量
                    zoomToAccuracy: true,  //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                    position: 'RB' //  定位按钮的排放位置,  RB表示右下
                });

                var geocoder = new AMap.Geocoder({
                    city: '全国' // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                })

                geolocation.getCurrentPosition(function (status, result) {
                    if (status === 'complete') {
                        geocoder.getAddress(result.position, function (status, result) {
                            if (status === 'complete' && result.info === 'OK') {
                                storage.setItem({
                                    name: "Address",
                                    value: result.regeocode.formattedAddress
                                })
                            } else {
                                console.log(result)
                            }
                        })

                    } else {
                        if (result.info === 'TIMEOUT') {
                            alert('定位超时，请稍后再试');
                        } else {
                            alert('定位失败，请检查企业微信定位权限是否开启');
                        }
                    }
                });


            });
        }).catch(e => {
            console.log(e);
        })
    }

    get_lookCardRellacDetail = (tableId) => {
        const { Info, Form } = this.state;
        let data = {
            table_id: tableId
        }
        lookCardRellacDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        Form.table_id = tableId

                        if (res.data.Detail.reason === 2) {
                            Info.TableInfo.forEach((item, index) => {
                                if (item.filedLabel === '位置') {
                                    item.unShow = false
                                } else if (item.filedLabel === '附件') {
                                    item.unShow = false
                                }
                            })
                        }
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (res.data.Detail.reason === 2) {
                                if (item.filedLabel === '位置') {
                                    item.unShow = false
                                } else if (item.filedLabel === '附件') {
                                    if (res.data.Detail.enclosure.length) {
                                        item.unShow = false
                                    }
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUsername
                    Info.apply.deName = res.data.Detail.deName
                    Info.apply.apply_date = res.data.Detail.apply_time
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '补卡开始时间') {
                            item.value = new Date(res.data.Detail.start)
                        } else if (item.filedLabel === '补卡结束时间') {
                            item.value = new Date(res.data.Detail.end)
                        } else if (item.filedLabel === '补卡原因') {
                            if (res.data.Detail.reason === 1) {
                                item.radioList = [
                                    {
                                        label: '忘刷卡',
                                        value: 1,
                                    }, {
                                        label: '外出公干',
                                        value: 2,
                                    }, {
                                        label: '刷卡机故障',
                                        value: 3,
                                    },
                                ]
                            }
                            item.value = []
                            item.value = [...item.value, res.data.Detail.reason]
                        } else if (item.filedLabel === '备注') {
                            item.value = res.data.Detail.info
                        } else if (item.filedLabel === '位置') {
                            item.value = res.data.Detail.address
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.Detail.enclosure || []
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        Form,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = (title) => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.filedLabel === title) {
                item.visible = true
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleFile = (fileList, fileName) => {
        let { Form, Info } = this.state
        let storeList = []
        if (fileList.length) {
            fileList.forEach((item, index) => {
                storeList.push(item.table_id)
            })
        }
        Form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Form,
            Info
        })
        )
    }

    handleDate = (title) => {
        let { Info } = this.state
        Info.TableInfo.forEach((item, index) => {
            if (item.filedLabel === title) {
                item.visible = false
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleRadio = (title, value) => {
        let { Info, Form } = this.state
        // let currentPosition = localStorage.getItem('currentPosition');
        if (value[0] === 2) {
            Info.TableInfo.forEach((item, index) => {
                if (item.filedLabel === '位置') {
                    item.unShow = false
                    item.value = storage.getItem('Address')
                    Form.address = storage.getItem('Address')
                } else if (item.filedLabel === '附件') {
                    item.unShow = false
                }
            })
        } else {
            Info.TableInfo.forEach((item, index) => {
                if (item.filedLabel === '位置') {
                    item.unShow = true
                } else if (item.filedLabel === '附件') {
                    item.unShow = true
                }
            })
        }
        this.setState(preState => ({
            Info,
            Form
        })
        )
    }

    handlePresent = (data) => {
        const { Info, Form } = this.state
        let date = new Date()
        Form.status = 2
        Form.apply_time = dayjs(date).format('YYYY-MM-DD')
        Form.start = dayjs(data.start).format('YYYY-MM-DD HH:mm:ss')
        Form.end = dayjs(data.end).format('YYYY-MM-DD HH:mm:ss')
        Form.reason = data.reason[0]
        Form.info = data.info

        createCardReplacement(Form).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '提交成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '提交失败,请检查表单!',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='新增补卡申请' />

                <AddConfig key={FormKey} Info={Info} visibleEvent={this.handleButton} fileEvent={this.handleFile} dateEvent={this.handleDate} radioEvent={this.handleRadio} presentEvent={this.handlePresent} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
