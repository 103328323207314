import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import dayjs from 'dayjs'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { selectProjectName } from "../../../utils/api"
import { createUserReimbursement, userReimbursementDetail } from "../../../utils/oa"
import { reviewApplyResult } from "../../../utils/api"
import UserPopup from "../../../components/UserPopup"
import Icon from "../../../accets/img/icon_02.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '费用报销单',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [
                    {
                        filedLabel: '报销单位',
                        filedName: 're_unit',
                        filedType: '单选',
                        placeholder: '请选择',
                        disabled: false,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                value: 1,
                                label: "四川天艺生态园林集团股份有限公司"
                            },
                            {
                                value: 2,
                                label: "四川天艺优境环境科技有限公司"
                            },
                            {
                                value: 3,
                                label: "四川天艺致远物联网科技有限公司"
                            },
                            {
                                value: 4,
                                label: '四川天艺传奇生态茶业有限公司'
                            }
                        ]
                    }, {
                        filedLabel: '项目产生费用',
                        filedName: 'is_pro',
                        filedType: '单选',
                        placeholder: '请选择',
                        disabled: false,
                        required: false,
                        message: '',
                        value: [],
                        radioList: [
                            {
                                value: 1,
                                label: '是'
                            },
                            {
                                value: 0,
                                label: '否'
                            }
                        ]
                    },
                    {
                        filedLabel: '项目名称',
                        filedName: 'pro_name',
                        filedType: '选择',
                        placeholder: '请选择',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '本次核销借款(元)',
                        filedName: 'loan_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入本次核销借款',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '本次实付借款(元)',
                        filedName: 'amount_paid',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入本次实付借款',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '金额合计(元)',
                        filedName: 'total_money',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入金额合计',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '前期借支余额(元)',
                        filedName: 'debit_balance',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入前期借支余额',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '附件张数',
                        filedName: 'eng_num',
                        filedType: '文本',
                        nodeType: 'text',
                        placeholder: '请输入附件张数',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '备注',
                        filedName: 'info',
                        filedType: '文本域',
                        placeholder: '请输入备注',
                        disabled: false,
                        required: false,
                        message: '',
                        value: '',
                    },
                    {
                        filedLabel: '附件',
                        filedName: 'enclosure',
                        filedType: '附件',
                        placeholder: '',
                        disabled: false,
                        required: false,
                        message: '',
                        value: [],
                    },
                    {
                        filedLabel: '费用类型',
                        filedName: 'class',
                        filedType: '单选',
                        placeholder: '请选择',
                        disabled: false,
                        required: true,
                        message: '请选择费用类型',
                        value: [],
                        radioList: [
                            {
                                value: 1,
                                label: '综合费用'
                            },
                            {
                                value: 2,
                                label: '科研费用'
                            },
                            {
                                value: 3,
                                label: '项目费用'
                            }
                        ]
                    },
                ],
                ListInfo: [
                    {
                        plateName: '费用报销明细',
                        unWind: false,
                        dataSource: []
                    },
                ]
            },
            Form: {},
            pro_name: '',
            expense_01: [
                { value: 1, label: '办公用品' },
                { value: 2, label: '差旅费用' },
                { value: 3, label: '业务费用' },
                { value: 4, label: '交通费用' },
                { value: 5, label: '通讯费用' },
                { value: 6, label: '其他费用' }
            ],
            expense_02: [
                { value: 1, label: '人员人工费用' },
                { value: 2, label: '直接投入费用' },
                { value: 3, label: '委托研发费用' },
                { value: 4, label: '研发基地费用' },
                { value: 5, label: '新产品设计费' },
                { value: 6, label: '其他相关费用' }
            ],
            expense_03: [
                { value: 1, label: '办公用品' },
                { value: 2, label: '差旅费用' },
                { value: 3, label: '业务费用' },
                { value: 4, label: '交通费用' },
                { value: 5, label: '餐饮费用' },
                { value: 6, label: '零星材料采购费用' },
                { value: 7, label: '其他费用' }
            ],
            popup: {
                isShow: false,
                title: '选择项目',
                placeholder: '输入项目名称查询',
                multiple: false,
                disabled: false,
                dataSource: []
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_userReimbursementDetail(tableId)
        } else {
            this.get_userInfo()
        }
    }

    get_userInfo = () => {
        let { Info, Form } = this.state;
        let userInfo = storage.getItem('userInfo');

        Info.apply.userName = userInfo.name
        Info.apply.deName = userInfo.deName
        Info.apply.apply_date = ''
        Form.bus_de = userInfo.deName

        this.setState(preState => ({
            Info,
            Form
        })
        )
    }

    get_userReimbursementDetail = (tableId) => {
        const { Info, Form, expense_01, expense_02, expense_03 } = this.state;
        let data = {
            table_id: tableId
        }
        userReimbursementDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 1 || res.data.Detail.status === 3) {
                        Form.table_id = tableId
                        Form.class = res.data.Detail.class
                        Form.project_id = res.data.Detail.project_id || ''
                    } else {
                        Info.TableInfo.forEach((item, index) => {
                            item.disabled = true

                            if (!res.data.Detail.enclosure.length) {
                                if (item.filedLabel === '附件') {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.apply_userName
                    Info.apply.deName = res.data.Detail.bus_de
                    Info.apply.apply_date = res.data.Detail.apply_date
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    Info.ListInfo[0].dataSource = []
                    res.data.Detail.details = JSON.parse(res.data.Detail.details)
                    res.data.Detail.details.forEach((item, index) => {
                        let key = index + 1
                        let dataSource = {
                            key: key,
                            list: [
                                {
                                    filedLabel: '费用类别',
                                    filedName: 'expense_category_' + key,
                                    filedType: '单选',
                                    placeholder: '请选择',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: [item.expense_category],
                                    radioList: Form.class === 1 ? expense_01 : Form.class === 2 ? expense_02 : expense_03
                                },
                                {
                                    filedLabel: '报销金额',
                                    filedName: 'amount_' + key,
                                    filedType: '文本',
                                    placeholder: '请输入报销金额',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.amount,
                                },
                                {
                                    filedLabel: '备注',
                                    filedName: 'remark_' + key,
                                    filedType: '文本',
                                    placeholder: '请输入备注',
                                    disabled: true,
                                    required: false,
                                    message: '',
                                    value: item.remark,
                                },
                            ]
                        }
                        Info.ListInfo[0].dataSource = [...Info.ListInfo[0].dataSource, dataSource]
                    })


                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '报销单位') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.re_unit]
                        } else if (item.filedLabel === '项目产生费用') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.is_pro]
                        } else if (item.filedLabel === '费用类型') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.class]
                        } else if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.projectName || ''
                        } else if (item.filedLabel === '本次核销借款(元)') {
                            item.value = res.data.Detail.loan_money
                        } else if (item.filedLabel === '本次实付借款(元)') {
                            item.value = res.data.Detail.amount_paid
                        } else if (item.filedLabel === '金额合计(元)') {
                            item.value = res.data.Detail.total_money
                        } else if (item.filedLabel === '前期借支余额(元)') {
                            item.value = res.data.Detail.debit_balance
                        } else if (item.filedLabel === '附件张数') {
                            item.value = res.data.Detail.eng_num
                        } else if (item.filedLabel === '备注') {
                            item.value = res.data.Detail.info
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.Detail.enclosure
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        Form,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    get_selectProjectName = () => {
        let { popup, pro_name } = this.state
        let data = {
            pro_name: pro_name,
            page: 1,
            limit: 500
        }
        selectProjectName(data).then(
            (res) => {
                if (!res.code) {
                    popup.dataSource = []
                    if (res.data.projects.length) {
                        res.data.projects.forEach((item, index) => {
                            let pro = {
                                value: item.table_id,
                                label: item.pro_name,
                            }
                            popup.dataSource = [...popup.dataSource, pro]
                        })

                        popup.isShow = true
                    }
                    this.setState(preState => ({
                        popup,
                    }))
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleButton = () => {
        this.get_selectProjectName()
    }

    handleVisible = (title, name) => {
        let { Info } = this.state
        if (title === '报销单位' || title === '项目产生费用' || title === '费用类型') {
            Info.TableInfo.forEach((item, index) => {
                if (item.filedLabel === title) {
                    item.visible = true
                }
            })
        } else if (title === '费用类别') {
            Info.ListInfo[0].dataSource.forEach((item, index) => {
                item.list.forEach((em, ex) => {
                    if (em.filedName === name) {
                        em.visible = true
                    }
                })
            })
        }
        this.setState(preState => ({
            Info
        })
        )
    }

    handleQuery = (value) => {
        this.setState(preState => ({
            pro_name: value,
        }), () => {
            this.get_selectProjectName()
        })
    }

    handleClose = () => {
        this.setState(preState => ({
            userName: '',
            pro_name: '',
            popup: {
                isShow: false,
                title: '选择项目',
                placeholder: '输入项目名称查询',
                multiple: false,
                disabled: false,
                dataSource: []
            }
        }))
    }

    handleSelect = (value) => {
        let { popup, Info, Form } = this.state
        if (value.length) {
            value.forEach((item, index) => {
                popup.dataSource.forEach((em, ex) => {
                    if (em.value === item) {
                        Info.TableInfo.forEach((m, x) => {
                            if (m.filedLabel === '项目名称') {
                                m.value = em.label
                                Form.project_id = em.value
                            }
                        })
                    }
                })
            })
        }
        this.setState(preState => ({
            Info,
            Form,
            pro_name: '',
            popup: {
                isShow: false,
                title: '选择项目',
                placeholder: '输入项目名称查询',
                multiple: false,
                disabled: false,
                dataSource: []
            }
        }))
    }

    handleDate = (title, name) => {
        let { Info } = this.state
        if (title === '报销单位' || title === '项目产生费用' || title === '费用类型') {
            Info.TableInfo.forEach((item, index) => {
                if (item.filedLabel === title) {
                    item.visible = false
                }
            })
        } else if (title === '费用类别') {
            Info.ListInfo[0].dataSource.forEach((item, index) => {
                item.list.forEach((em, ex) => {
                    if (em.filedName === name) {
                        em.visible = false
                    }
                })
            })
        }
        this.setState(preState => ({
            Info
        })
        )
    }

    handleFile = (fileList, fileName) => {
        let { Form, Info } = this.state
        let storeList = []
        if (fileList.length) {
            fileList.forEach((item, index) => {
                storeList.push(item.table_id)
            })
        }
        Form[fileName] = JSON.stringify(storeList)
        this.setState(preState => ({
            Form,
            Info
        })
        )
    }

    handleWind = (name) => {
        let { Info } = this.state
        Info.ListInfo.forEach((item, index) => {
            if (item.plateName === name) {
                item.unWind = !item.unWind
            }
        })
        this.setState(preState => ({
            Info
        })
        )
    }

    handleRadio = (title, value) => {
        let { Form, Info, expense_01, expense_02, expense_03 } = this.state
        let dataSource = {
            key: 1,
            list: [
                {
                    filedLabel: '费用类别',
                    filedName: 'expense_category_1',
                    filedType: '单选',
                    placeholder: '请选择',
                    disabled: false,
                    required: false,
                    message: '',
                    value: [],
                    radioList: []
                },
                {
                    filedLabel: '报销金额',
                    filedName: 'amount_1',
                    filedType: '文本',
                    placeholder: '请输入报销金额',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '备注',
                    filedName: 'remark_1',
                    filedType: '文本',
                    placeholder: '请输入备注',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
            ]
        }
        Info.ListInfo[0].dataSource = []
        if (value[0] === 1) {
            dataSource.list[0].radioList = expense_01
        } else if (value[0] === 2) {
            dataSource.list[0].radioList = expense_02
        } else {
            dataSource.list[0].radioList = expense_03
        }
        Info.ListInfo[0].dataSource = [...Info.ListInfo[0].dataSource, dataSource]
        Form.class = value[0]
        this.setState(preState => ({
            Info,
            Form
        })
        )
    }

    handleNew = (name) => {
        let { Info, Form, expense_01, expense_02, expense_03 } = this.state
        if (!Form.class) {
            Toast.show({
                icon: 'fail',
                content: '请选择费用类型!',
            })
            return
        }
        if (Info.ListInfo[0].dataSource.length > 4) {        
            return
        }
        let key = Info.ListInfo[0].dataSource.length + 1
        let value = {
            key: key,
            list: [
                {
                    filedLabel: '费用类别',
                    filedName: 'expense_category_' + key,
                    filedType: '单选',
                    placeholder: '请选择',
                    disabled: false,
                    required: false,
                    message: '',
                    value: [],
                    radioList: Form.class === 1 ? expense_01 : Form.class === 2 ? expense_02 : expense_03
                },
                {
                    filedLabel: '报销金额',
                    filedName: 'amount_' + key,
                    filedType: '文本',
                    placeholder: '请输入报销金额',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '备注',
                    filedName: 'remark_' + key,
                    filedType: '文本',
                    placeholder: '请输入备注',
                    disabled: false,
                    required: false,
                    message: '',
                    value: '',
                },
            ]
        }
        Info.ListInfo[0].dataSource = [...Info.ListInfo[0].dataSource, value]
        this.setState(preState => ({
            Info
        })
        )
    }

    handlePresent = (data) => {
        const { Form, Info } = this.state
        let date = new Date()
        Form.status = 2
        Form.apply_date = dayjs(date).format('YYYY-MM-DD')
        Form.re_unit = data.re_unit[0]
        Form.is_pro = data.is_pro[0]
        Form.loan_money = data.loan_money
        Form.amount_paid = data.amount_paid
        Form.total_money = data.total_money
        Form.debit_balance = data.debit_balance
        Form.eng_num = data.eng_num
        Form.info = data.info
        Form.class = data.class[0]


        Form.details = []
        Info.ListInfo[0].dataSource.forEach((item, index) => {
            let value = {
                key: item.key,
                expense_category: '',
                amount: '',
            }
            item.list.forEach((em, ex) => {
                value.expense_category = data['expense_category_' + item.key].length ? data['expense_category_' + item.key][0] : ''
                value.amount = data['amount_' + item.key]
                value.remark = data['remark_' + item.key]
            })
            Form.details = [...Form.details, value]
        })

        Form.details = JSON.stringify(Form.details)

        createUserReimbursement(Form).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '提交成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '提交失败,请检查表单!',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info, popup } = this.state
        return (
            <div>
                <NavBar menuName='新增费用报销单' />

                <AddConfig key={FormKey} Info={Info} buttonEvent={this.handleButton} visibleEvent={this.handleVisible} fileEvent={this.handleFile} dateEvent={this.handleDate} windEvent={this.handleWind} newEvent={this.handleNew} radioEvent={this.handleRadio} presentEvent={this.handlePresent} auditEvent={this.handleAudit} />

                <UserPopup popup={popup} closeEvent={this.handleClose} queryEvent={this.handleQuery} selectEvent={this.handleSelect} />
            </div>
        )
    }
}

export default withRouter(NewItem)
