import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { PullToRefresh, InfiniteScroll } from 'antd-mobile'
import { LeftOutline } from 'antd-mobile-icons'
import storage from '../../utils/localStorageTime'
import List from './List'
import workList from "../home/Work"
import projectList from "../home/Project"
import { indexData, flowStayList, flowSeeLook, userWeeklyLookList, stayReadConstructionLog } from "../../utils/api"
import Icon_01 from "../../accets/img/icon_01.png"
import Icon_19 from "../../accets/img/icon_19.png"
import Icon_20 from "../../accets/img/icon_20.png"

class Prompt extends Component {
  constructor(props) {
    super(props)
    this.state = {
      TabName: '流程待办',
      promptInfo: {
        flowsCcIsRead: false,
        flowsCcNumber: 0,
        flowsIsRead: false,
        flowsNumber: 0,
        weeklyIsRead: false,
        weeklyNumber: 0,
        ConstructionIsRead: false,
        ConstructionNumber: 0
      },
      isBacklog: false,
      promptList: [],
      pageNum: 1,
      Loading: false,
    }
  }

  componentDidMount() {
    this.get_userInfo()
  }

  get_userInfo = () => {
    let { isBacklog } = this.state
    if (storage.getItem('permission')) {
      let permission = storage.getItem('permission')
      permission.forEach((item, index) => {
        if (item === 'eng_personnel') {
          isBacklog = true
        }
      })
    }
    this.setState(preState => ({
      isBacklog
    }), () => {
      this.get_indexData()
      if (this.props.location.state) {
        let TabName = this.props.location.state.name
        this.get_cutTab(TabName)
      } else {
        this.get_cutTab('流程待办')
      }
    })
  }

  get_indexData = () => {
    indexData().then(
      (res) => {
        if (!res.code) {
          this.setState(preState => ({
            promptInfo: res.data
          })
          )
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  get_cutTab = (name) => {
    this.setState(preState => ({
      TabName: name,
      pageNum: 1,
      Loading: true,
      promptList: [],
    }), () => {
      if (name === '流程待办') {
        this.get_flowStayList()
      } else if (name === '流程待阅') {
        this.get_flowSeeLook()
      } else if (name === '施工日志') {
        this.get_stayReadConstructionLog()
      } else if (name === '周报待阅') {
        this.get_userWeeklyLookList()
      }
    })
  }

  get_flowStayList = () => {
    let { pageNum, promptList, Loading } = this.state
    let data = {
      page: pageNum,
      limit: 15
    }
    flowStayList(data).then(
      (res) => {
        if (!res.code) {
          if (res.data.flows.length) {
            res.data.flows.forEach((item, index) => {
              let value = {
                key: index + 1,
                table_id: item.table_id,
                url: item.deteilUrl,
                apply_date: item.created_at,
                menus: item.functionName,
                title: item.applyUserName + '的' + item.functionName,
                is_read: item.is_read,
                status: item.review_status
              }
              promptList = [...promptList, value]
            })

            if (pageNum < Math.ceil(res.data.pages.total / 15)) {
              Loading = true
            }

            pageNum = pageNum + 1
          }

          this.setState(preState => ({
            promptList,
            pageNum,
            Loading
          }), () => {
            this.get_menuIcon()
          })
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  get_flowSeeLook = () => {
    let { pageNum, promptList, Loading } = this.state
    let data = {
      page: pageNum,
      limit: 15
    }
    flowSeeLook(data).then(
      (res) => {
        if (!res.code) {
          if (res.data.flows.length) {
            res.data.flows.forEach((item, index) => {
              let value = {
                key: index + 1,
                table_id: item.table_id,
                url: item.deteilUrl,
                apply_date: item.created_at,
                menus: item.functionName,
                title: item.applyUserName + '的' + item.functionName,
                is_read: item.is_read,
              }
              promptList = [...promptList, value]

              if (pageNum < Math.ceil(res.data.pages.total / 15)) {
                Loading = true
              }

              pageNum = pageNum + 1
            })

            this.setState(preState => ({
              promptList,
              pageNum,
              Loading
            }), () => {
              this.get_menuIcon()
            })
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  get_userWeeklyLookList = () => {
    let { pageNum, promptList, Loading } = this.state
    let data = {
      page: pageNum,
      limit: 15
    }
    userWeeklyLookList(data).then(
      (res) => {
        if (!res.code) {
          if (res.data.weekly.length) {
            res.data.weekly.forEach((item, index) => {
              let value = {
                key: index + 1,
                table_id: item.table_id,
                icon: Icon_01,
                url: '/public/human/weekly/NewItem',
                apply_date: item.created_at,
                title: item.applyUserName + '的工作周报',
                is_read: item.is_read,
              }
              promptList = [...promptList, value]

              if (pageNum < Math.ceil(res.data.pages.total / 15)) {
                Loading = true
              }

              pageNum = pageNum + 1
            })

            this.setState(preState => ({
              promptList,
              pageNum,
              Loading
            }))
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  get_stayReadConstructionLog = () => {
    let { pageNum, promptList, Loading } = this.state
    let data = {
      page: pageNum,
      limit: 15
    }
    stayReadConstructionLog(data).then(
      (res) => {
        if (!res.code) {
          if (res.data.cons.length) {
            res.data.cons.forEach((item, index) => {
              let value = {
                key: index + 1,
                table_id: item.table_id,
                url: '/project/build/NewItem',
                icon: Icon_20,
                apply_date: item.created_at,
                title: item.applyUsername + '的施工日志',
                is_read: item.is_read,
              }
              promptList = [...promptList, value]

              if (pageNum < Math.ceil(res.data.pages.total / 15)) {
                Loading = true
              }

              pageNum = pageNum + 1
            })

            this.setState(preState => ({
              promptList,
              pageNum,
              Loading
            }))
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  get_menuIcon = () => {
    let { promptList } = this.state
    let menuList = [...workList, ...projectList]
    promptList.forEach((item, index) => {
      item.icon = Icon_19
      menuList.forEach((em, ex) => {
        if (item.menus === em.title) {
          item.icon = em.icon
        }
      })
    })
    this.setState(preState => ({
      promptList
    }))
  }

  getNextData = () => {
    let { TabName } = this.state
    this.setState(preState => ({
      pageNum: 1,
      Loading: true,
      promptList: [],
    }), () => {
      if (TabName === '流程待办') {
        this.get_flowStayList()
      } else if (TabName === '流程待阅') {
        this.get_flowSeeLook()
      } else if (TabName === '施工日志') {
        this.get_stayReadConstructionLog()
      } else if (TabName === '周报待阅') {
        this.get_userWeeklyLookList()
      }
    })
  }

  loadMore = () => {
    let { TabName } = this.state
    this.setState(preState => ({
      Loading: false
    }), () => {
      if (TabName === '流程待办') {
        this.get_flowStayList()
      } else if (TabName === '流程待阅') {
        this.get_flowSeeLook()
      } else if (TabName === '施工日志') {
        this.get_stayReadConstructionLog()
      } else if (TabName === '周报待阅') {
        this.get_userWeeklyLookList()
      }
    })
  }

  get_Back = () => {
    window.history.back(-1)
  }

  render() {
    let { isBacklog, TabName, promptInfo, promptList, Loading } = this.state
    return (
      <div className='page'>
        <PullToRefresh onRefresh={async () => { this.getNextData() }}>
          <div className='prompt_tabs'>
            <LeftOutline fontSize={20} color='#3396FB' onClick={() => this.get_Back()} />
            <div className='prompt_tab'>
              <div onClick={() => this.get_cutTab('流程待办')}>
                <div>
                  <div>流程待办</div>
                  {
                    promptInfo.flowsIsRead ? <div>{promptInfo.flowsNumber}</div> : ''
                  }
                </div>
                {
                  TabName === '流程待办' ? <div></div> : ''
                }
              </div>

              {
                !isBacklog ? <div onClick={() => this.get_cutTab('流程待阅')}>
                  <div>
                    <div>流程待阅</div>
                    {
                      promptInfo.flowsCcIsRead ? <div>{promptInfo.flowsCcNumber}</div> : ''
                    }
                  </div>
                  {
                    TabName === '流程待阅' ? <div></div> : ''
                  }
                </div> : <div onClick={() => this.get_cutTab('施工日志')}>
                  <div>
                    <div>施工日志</div>
                    {
                      promptInfo.ConstructionIsRead ? <div>{promptInfo.ConstructionNumber}</div> : ''
                    }
                  </div>
                  {
                    TabName === '施工日志' ? <div></div> : ''
                  }
                </div>
              }

              <div onClick={() => this.get_cutTab('周报待阅')}>
                <div>
                  <div>周报待阅</div>
                  {
                    promptInfo.weeklyIsRead ? <div>{promptInfo.weeklyNumber}</div> : ''
                  }
                </div>
                {
                  TabName === '周报待阅' ? <div></div> : ''
                }
              </div>
            </div>
          </div>

          <List promptList={promptList} TabName={TabName} />

          <InfiniteScroll threshold={50} loadMore={() => { this.loadMore() }} hasMore={Loading} />
        </PullToRefresh>
      </div>
    )
  }
}


export default withRouter(Prompt)
