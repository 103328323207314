import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { Toast } from 'antd-mobile'
import storage from '../../../utils/localStorageTime'
import NavBar from "../../components/NavRar"
import AddConfig from "../../components/AddConfig"
import { InvoiceApplicationDetail } from "../../../utils/project"
import { reviewApplyResult } from "../../../utils/api"
import Icon from "../../../accets/img/icon_30.png"

class NewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            FormKey: 1,
            Info: {
                apply: {
                    title: '业主开票申请',
                    icon: Icon,
                    userName: '',
                    deName: '',
                    apply_date: '',
                    status: '',
                    process: {}
                },
                TableInfo: [{
                    filedLabel: '项目名称',
                    filedName: 'pro_name',
                    filedType: '选择',
                    placeholder: '请选择',
                    disabled: true,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '项目编号',
                    filedName: 'pro_number',
                    filedType: '文本',
                    nodeType: 'text',
                    placeholder: '',
                    disabled: true,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '项目经理',
                    filedName: 'pro_manager',
                    filedType: '文本',
                    nodeType: 'text',
                    placeholder: '',
                    disabled: true,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '开票金额(元)',
                    filedName: 'invo_money',
                    filedType: '文本',
                    nodeType: 'text',
                    placeholder: '',
                    disabled: true,
                    required: false,
                    message: '',
                    value: '',
                },
                {
                    filedLabel: '开票类型',
                    filedName: 'invo_type',
                    filedType: '单选',
                    visible: false,
                    placeholder: '',
                    disabled: true,
                    required: false,
                    message: '',
                    value: [],
                    radioList: [
                        {
                            label: '专用增值税发票',
                            value: 1
                        },
                        {
                            label: '普通增值税发票',
                            value: 2
                        }
                    ]
                },
                {
                    filedLabel: '取票方式',
                    filedName: 'get_fun',
                    filedType: '单选',
                    visible: false,
                    placeholder: '',
                    disabled: true,
                    required: false,
                    message: '',
                    value: [],
                    radioList: [
                        {
                            label: '邮寄',
                            value: 1
                        },
                        {
                            label: '自取',
                            value: 2
                        }
                    ]
                },
                {
                    filedLabel: '联系方式',
                    filedName: 'contact',
                    filedType: '文本域',
                    placeholder: '',
                    disabled: true,
                    required: false,
                    message: '',
                    value: '',
                    unShow: true
                },
                {
                    filedLabel: '附件',
                    filedName: 'enclosure',
                    filedType: '附件',
                    placeholder: '',
                    disabled: true,
                    required: false,
                    message: '',
                    value: [],
                },
                ],
                ListInfo: []
            },
        }
    }

    componentDidMount() {
        if (this.props.location.query) {
            let tableId = this.props.location.query.tableId
            this.get_InvoiceApplicationDetail(tableId)
        }
    }

    get_InvoiceApplicationDetail = (tableId) => {
        const { Info } = this.state;
        let data = {
            table_id: tableId
        }
        InvoiceApplicationDetail(data).then(
            (res) => {
                if (!res.code) {
                    if (res.data.Detail.status === 2 || res.data.Detail.status === 4) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '附件') {
                                if (!res.data.Detail.enclosure || !res.data.Detail.enclosure.length) {
                                    item.unShow = true
                                }
                            }
                        })
                    }

                    Info.apply.status = res.data.Detail.status
                    Info.apply.userName = res.data.Detail.applyUserName
                    Info.apply.deName = res.data.Detail.applyUserDeName
                    Info.apply.apply_date = res.data.Detail.date_day
                    Info.apply.process = res.data.Detail.reviewAudit
                    Info.apply.process.editPermission = res.data.Detail.editPermission

                    if (Number(res.data.Detail.get_fun) === 1) {
                        Info.TableInfo.forEach((item, index) => {
                            if (item.filedLabel === '联系方式') {
                                item.unShow = false
                                item.value = res.data.Detail.contact
                            }
                        })
                    }


                    Info.TableInfo.forEach((item, index) => {
                        if (item.filedLabel === '项目名称') {
                            item.value = res.data.Detail.projectName
                        } else if (item.filedLabel === '项目编号') {
                            item.value = res.data.Detail.pro_number
                        } else if (item.filedLabel === '项目经理') {
                            item.value = res.data.Detail.pro_manager
                        } else if (item.filedLabel === '开票金额(元)') {
                            item.value = res.data.Detail.invo_money
                        } else if (item.filedLabel === '开票类型') {
                            item.value = []
                            item.value = [...item.value, res.data.Detail.invo_type]
                        } else if (item.filedLabel === '取票方式') {
                            item.value = []
                            item.value = [...item.value, Number(res.data.Detail.get_fun)]
                        } else if (item.filedLabel === '附件') {
                            item.value = res.data.Detail.enclosure
                        }
                    })

                    this.setState(preState => ({
                        Info,
                        FormKey: Math.random(),
                    })
                    )
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    handleAudit = (type, value) => {
        let { Info } = this.state
        let userInfo = storage.getItem('userInfo')
        let data = {
            tableName: '',
            table_id: '',
            reviewResult: type,
            opinion: value,
            review_id: '',
        }

        Info.apply.process.reviewAudit.forEach((item, index) => {
            if (item.user_id === userInfo.userId) {
                data.tableName = item.table_name
                data.table_id = item.table_id
                data.review_id = item.id
            }
        })
        reviewApplyResult(data).then(
            (res) => {
                if (!res.code) {
                    Toast.show({
                        icon: 'success',
                        content: '审核成功',
                    })
                    setTimeout(() => {
                        window.history.back(-1)
                    })
                } else {
                    if (res.code === 1010) {
                        let select = {
                            audit_id: res.audit_id,
                            tableName: res.tableName,
                            table_id: res.table_id,
                            data: res.data,
                        }

                        Info.select = select

                        this.setState({
                            Info
                        })
                    } else {
                        Toast.show({
                            icon: 'fail',
                            content: res.data[0] || '审核失败',
                        })
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    render() {
        let { FormKey, Info } = this.state
        return (
            <div>
                <NavBar menuName='业主开票申请' />

                <AddConfig key={FormKey} Info={Info} auditEvent={this.handleAudit} />
            </div>
        )
    }
}

export default withRouter(NewItem)
